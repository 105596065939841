import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  NonInputableField,
  SpacedDivider,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import { removeFromArray } from "@bjelos-farm/lib-common-tools";
import {
  StockActionCreateInput,
  useCreateStockActionMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { Delete, PlusOne } from "@material-ui/icons";

export const Create: React.FC = () => {
  const { stocks } = useData();
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateStockActionMutation({
    update: (_, { data }) => {
      setRedirect(data?.createStockAction.id);
    },
  });
  const formik = useFormik<StockActionCreateInput>({
    initialValues: {
      name: "",
      presetValues: { set: [] },
      subActions: { create: [] },
    },
    onSubmit: (data) =>
      createMutation({
        variables: { data },
      }),
  });

  if (getRedirect) return <Redirect to={`/stock-actions/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          subheader="Ajouter un type d'action"
          title="Action sur stock"
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Effet de l'action
          </Typography>
          <Grid container spacing={3}>
            {formik.values.subActions?.create?.map((subAction, index) => (
              <React.Fragment key={index}>
                <Grid item md={6} xs={12}>
                  <SingleSelect
                    disableClearable={true}
                    label="Sur stock"
                    options={stocks
                      .filter((stock) => !stock.disabled)
                      .map((stock) => ({
                        label: stock.name,
                        value: stock.id,
                      }))}
                    currentValue={subAction.stock.connect?.id}
                    setNewValue={(value) =>
                      formik.setValues((oldValues) => {
                        if (
                          (value || value === 0) &&
                          oldValues.subActions?.create &&
                          oldValues.subActions?.create[index]?.stock?.connect
                            ?.id
                        ) {
                          oldValues.subActions.create[index].stock.connect = {
                            id: value,
                          };
                          return oldValues;
                        }
                        return oldValues;
                      })
                    }
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <NonInputableField
                    type="number"
                    name={`subActions.create[${index}].delta`}
                    onChange={formik.handleChange}
                    value={subAction.delta}
                    label="Delta"
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  <IconButton
                    onClick={() =>
                      formik.setValues((values) => {
                        if (values.subActions?.create) {
                          values.subActions.create = removeFromArray(
                            values.subActions.create,
                            index,
                          );
                        }
                        return values;
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item md={6} xs={12}>
              <IconButton
                onClick={() =>
                  formik.setValues((values) => {
                    if (values.subActions?.create) {
                      values.subActions.create.push({
                        delta: 0,
                        stock: { connect: { id: stocks[0].id } },
                      });
                    }
                    return values;
                  })
                }
              >
                <PlusOne />
              </IconButton>
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Valeurs rapides
          </Typography>
          <Grid container spacing={3}>
            {formik.values.presetValues?.set.map((presetValue, index) => (
              <React.Fragment key={index}>
                <Grid item md={5} xs={12}>
                  <NonInputableField
                    type="number"
                    name={`presetValues.set[${index}]`}
                    onChange={formik.handleChange}
                    value={presetValue}
                    label="Valeur rapide"
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  <IconButton
                    onClick={() =>
                      formik.setValues((values) => {
                        if (values.presetValues?.set) {
                          values.presetValues.set = removeFromArray(
                            values.presetValues.set,
                            index,
                          );
                        }
                        return values;
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item md={6} xs={12}>
              <IconButton
                onClick={() =>
                  formik.setValues((values) => {
                    values.presetValues?.set.push(0);
                    return values;
                  })
                }
              >
                <PlusOne />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer l'action
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
