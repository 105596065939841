import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
  PlusOne,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import {
  StockActionUpdateInput,
  useUpdateStockActionMutation,
  useStockActionQuery,
} from "../../Generated/graphql";
import { removeFromArray } from "@bjelos-farm/lib-common-tools";
import { useData } from "../../Provider/Data";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { stocks } = useData();
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const { data, loading } = useStockActionQuery({
    variables: { where: { id: numId } },
  });

  const [updateMutation] = useUpdateStockActionMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<StockActionUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.stockAction;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      name: { set: pageData.name },
      hidden: { set: pageData.hidden },
      presetValues: { set: pageData.presetValues ?? [] },
      subActions: {
        create: pageData.subActions?.map((subAction) => ({
          delta: subAction.delta,
          stock: { connect: { id: subAction.stock.id } },
        })),
        delete:
          pageData.subActions?.map((subAction) => ({ id: subAction.id })) ?? [],
      },
    });
  };

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title={"Action sur stock"}
          action={
            <>
              <IconButton
                hidden={!getEditable}
                onClick={() =>
                  formik.setValues((oldValues) => {
                    oldValues.hidden = { set: !oldValues.hidden?.set };
                    return oldValues;
                  })
                }
              >
                {(getEditable ? formik.values.hidden?.set : pageData.hidden) ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="name.set"
                value={getEditable ? formik.values.name?.set : pageData.name}
                onChange={formik.handleChange}
                label="Nom"
              />
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Effet de l'action
          </Typography>
          <Grid container spacing={3}>
            {!getEditable &&
              pageData.subActions?.map((subAction, index) => (
                <React.Fragment key={index}>
                  <Grid item md={6} xs={12} key={index}>
                    <NonInputableField
                      label="Sur stock"
                      disabled={true}
                      value={
                        stocks.find((stock) => stock.id === subAction.stock.id)
                          ?.name
                      }
                    />
                  </Grid>
                  <Grid item md={5} xs={12} key={index}>
                    <NonInputableField
                      label="Delta"
                      disabled={true}
                      value={subAction.delta}
                    />
                  </Grid>
                  <Grid item md={1} xs={12} key={index}></Grid>
                </React.Fragment>
              ))}
            {getEditable &&
              formik.values.subActions?.create?.map((subAction, index) => (
                <React.Fragment key={index}>
                  <Grid item md={6} xs={12}>
                    <SingleSelect
                      disableClearable={true}
                      label="Sur stock"
                      options={stocks
                        .filter((stock) => !stock.disabled)
                        .map((stock) => ({
                          label: stock.name,
                          value: stock.id,
                        }))}
                      currentValue={subAction.stock.connect?.id}
                      setNewValue={(value) =>
                        formik.setValues((oldValues) => {
                          if (
                            (value || value === 0) &&
                            oldValues.subActions?.create &&
                            oldValues.subActions?.create[index].stock.connect
                              ?.id
                          ) {
                            oldValues.subActions.create[index].stock.connect = {
                              id: value,
                            };
                            return oldValues;
                          }
                          return oldValues;
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <NonInputableField
                      type="number"
                      name={`subActions.create[${index}].delta`}
                      onChange={formik.handleChange}
                      value={subAction.delta}
                      label="Delta"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <IconButton
                      onClick={() =>
                        formik.setValues((values) => {
                          if (values.subActions?.create) {
                            values.subActions.create = removeFromArray(
                              values.subActions.create,
                              index,
                            );
                          }
                          return values;
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            {getEditable && (
              <Grid item md={6} xs={12}>
                <IconButton
                  onClick={() =>
                    formik.setValues((values) => {
                      values.subActions?.create?.push({
                        stock: {
                          connect: { id: stocks[0].id },
                        },
                        delta: 1,
                      });
                      return values;
                    })
                  }
                >
                  <PlusOne />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Valeurs rapides
          </Typography>
          <Grid container spacing={3}>
            {getEditable &&
              formik.values.presetValues?.set.map((presetValue, index) => (
                <React.Fragment key={index}>
                  <Grid item md={5} xs={12}>
                    <NonInputableField
                      type="number"
                      name={`presetValues.set[${index}]`}
                      onChange={formik.handleChange}
                      value={presetValue}
                      label="numéro"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <IconButton
                      onClick={() =>
                        formik.setValues((values) => {
                          if (values.presetValues?.set) {
                            values.presetValues.set = removeFromArray(
                              values.presetValues.set,
                              index,
                            );
                          }
                          return values;
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            {!getEditable &&
              pageData.presetValues?.map((presetValue, index) => (
                <React.Fragment key={index}>
                  <Grid item md={5} xs={12}>
                    <NonInputableField
                      disabled={true}
                      value={presetValue}
                      label="numéro"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}></Grid>
                </React.Fragment>
              ))}
            {getEditable && (
              <Grid item md={6} xs={12}>
                <IconButton
                  onClick={() =>
                    formik.setValues((values) => {
                      values.presetValues?.set.push(0);
                      return values;
                    })
                  }
                >
                  <PlusOne />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
    </Card>
  );
};
