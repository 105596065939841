import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type BatchPayload = {
  __typename?: "BatchPayload";
  count: Scalars["Int"];
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type Call = {
  __typename?: "Call";
  callSchedule: CallSchedule;
  callScheduleId: Scalars["Int"];
  comment: Scalars["String"];
  concluent?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  finished?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type CallCreateManyWithoutCallScheduleInput = {
  connect?: Maybe<Array<CallWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallCreateOrConnectWithoutcallScheduleInput>>;
  create?: Maybe<Array<CallCreateWithoutCallScheduleInput>>;
};

export type CallCreateManyWithoutOrderInput = {
  connect?: Maybe<Array<CallWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallCreateOrConnectWithoutorderInput>>;
  create?: Maybe<Array<CallCreateWithoutOrderInput>>;
};

export type CallCreateOrConnectWithoutcallScheduleInput = {
  create: CallCreateWithoutCallScheduleInput;
  where: CallWhereUniqueInput;
};

export type CallCreateOrConnectWithoutorderInput = {
  create: CallCreateWithoutOrderInput;
  where: CallWhereUniqueInput;
};

export type CallCreateWithoutCallScheduleInput = {
  comment?: Maybe<Scalars["String"]>;
  concluent?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  finished?: Maybe<Scalars["DateTime"]>;
  order?: Maybe<OrderCreateOneWithoutCallsInput>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CallCreateWithoutOrderInput = {
  callSchedule: CallScheduleCreateOneWithoutCallsInput;
  comment?: Maybe<Scalars["String"]>;
  concluent?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  finished?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CallListRelationFilter = {
  every?: Maybe<CallWhereInput>;
  none?: Maybe<CallWhereInput>;
  some?: Maybe<CallWhereInput>;
};

export type CallOrderByInput = {
  callScheduleId?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  concluent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  finished?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  orderId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CallScalarFieldEnum {
  CallScheduleId = "callScheduleId",
  Comment = "comment",
  Concluent = "concluent",
  CreatedAt = "createdAt",
  Finished = "finished",
  Id = "id",
  OrderId = "orderId",
  UpdatedAt = "updatedAt"
}

export type CallScalarWhereInput = {
  AND?: Maybe<Array<CallScalarWhereInput>>;
  callScheduleId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  concluent?: Maybe<BoolNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  finished?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CallScalarWhereInput>>;
  OR?: Maybe<Array<CallScalarWhereInput>>;
  orderId?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallSchedule = {
  __typename?: "CallSchedule";
  calls?: Maybe<Array<Call>>;
  comment: Scalars["String"];
  day: Scalars["Int"];
  deliveryDayAddition: Scalars["Int"];
  deliveryTime: Scalars["Int"];
  id: Scalars["Int"];
  location: Location;
  locationId: Scalars["Int"];
  phone?: Maybe<Scalars["String"]>;
};

export type CallScheduleCallsArgs = {
  cursor?: Maybe<CallWhereUniqueInput>;
  distinct?: Maybe<Array<CallScalarFieldEnum>>;
  orderBy?: Maybe<Array<CallOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CallWhereInput>;
};

export type CallScheduleCreateInput = {
  calls?: Maybe<CallCreateManyWithoutCallScheduleInput>;
  comment?: Maybe<Scalars["String"]>;
  day?: Maybe<Scalars["Int"]>;
  deliveryDayAddition?: Maybe<Scalars["Int"]>;
  deliveryTime: Scalars["Int"];
  location: LocationCreateOneWithoutCallSchedulesInput;
  phone?: Maybe<Scalars["String"]>;
};

export type CallScheduleCreateManyWithoutLocationInput = {
  connect?: Maybe<Array<CallScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallScheduleCreateOrConnectWithoutlocationInput>>;
  create?: Maybe<Array<CallScheduleCreateWithoutLocationInput>>;
};

export type CallScheduleCreateOneWithoutCallsInput = {
  connect?: Maybe<CallScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<CallScheduleCreateOrConnectWithoutcallsInput>;
  create?: Maybe<CallScheduleCreateWithoutCallsInput>;
};

export type CallScheduleCreateOrConnectWithoutcallsInput = {
  create: CallScheduleCreateWithoutCallsInput;
  where: CallScheduleWhereUniqueInput;
};

export type CallScheduleCreateOrConnectWithoutlocationInput = {
  create: CallScheduleCreateWithoutLocationInput;
  where: CallScheduleWhereUniqueInput;
};

export type CallScheduleCreateWithoutCallsInput = {
  comment?: Maybe<Scalars["String"]>;
  day?: Maybe<Scalars["Int"]>;
  deliveryDayAddition?: Maybe<Scalars["Int"]>;
  deliveryTime: Scalars["Int"];
  location: LocationCreateOneWithoutCallSchedulesInput;
  phone?: Maybe<Scalars["String"]>;
};

export type CallScheduleCreateWithoutLocationInput = {
  calls?: Maybe<CallCreateManyWithoutCallScheduleInput>;
  comment?: Maybe<Scalars["String"]>;
  day?: Maybe<Scalars["Int"]>;
  deliveryDayAddition?: Maybe<Scalars["Int"]>;
  deliveryTime: Scalars["Int"];
  phone?: Maybe<Scalars["String"]>;
};

export type CallScheduleListRelationFilter = {
  every?: Maybe<CallScheduleWhereInput>;
  none?: Maybe<CallScheduleWhereInput>;
  some?: Maybe<CallScheduleWhereInput>;
};

export type CallScheduleOrderByInput = {
  comment?: Maybe<SortOrder>;
  day?: Maybe<SortOrder>;
  deliveryDayAddition?: Maybe<SortOrder>;
  deliveryTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
};

export type CallScheduleRelationFilter = {
  is?: Maybe<CallScheduleWhereInput>;
  isNot?: Maybe<CallScheduleWhereInput>;
};

export enum CallScheduleScalarFieldEnum {
  Comment = "comment",
  Day = "day",
  DeliveryDayAddition = "deliveryDayAddition",
  DeliveryTime = "deliveryTime",
  Id = "id",
  LocationId = "locationId",
  Phone = "phone"
}

export type CallScheduleScalarWhereInput = {
  AND?: Maybe<Array<CallScheduleScalarWhereInput>>;
  comment?: Maybe<StringFilter>;
  day?: Maybe<IntFilter>;
  deliveryDayAddition?: Maybe<IntFilter>;
  deliveryTime?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  locationId?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CallScheduleScalarWhereInput>>;
  OR?: Maybe<Array<CallScheduleScalarWhereInput>>;
  phone?: Maybe<StringNullableFilter>;
};

export type CallScheduleUpdateInput = {
  calls?: Maybe<CallUpdateManyWithoutCallScheduleInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  day?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryDayAddition?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTime?: Maybe<IntFieldUpdateOperationsInput>;
  location?: Maybe<LocationUpdateOneRequiredWithoutCallSchedulesInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CallScheduleUpdateManyMutationInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  day?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryDayAddition?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTime?: Maybe<IntFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CallScheduleUpdateManyWithoutLocationInput = {
  connect?: Maybe<Array<CallScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallScheduleCreateOrConnectWithoutlocationInput>>;
  create?: Maybe<Array<CallScheduleCreateWithoutLocationInput>>;
  delete?: Maybe<Array<CallScheduleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallScheduleScalarWhereInput>>;
  disconnect?: Maybe<Array<CallScheduleWhereUniqueInput>>;
  set?: Maybe<Array<CallScheduleWhereUniqueInput>>;
  update?: Maybe<Array<CallScheduleUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<CallScheduleUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: Maybe<Array<CallScheduleUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type CallScheduleUpdateManyWithWhereWithoutLocationInput = {
  data: CallScheduleUpdateManyMutationInput;
  where: CallScheduleScalarWhereInput;
};

export type CallScheduleUpdateOneRequiredWithoutCallsInput = {
  connect?: Maybe<CallScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<CallScheduleCreateOrConnectWithoutcallsInput>;
  create?: Maybe<CallScheduleCreateWithoutCallsInput>;
  update?: Maybe<CallScheduleUpdateWithoutCallsInput>;
  upsert?: Maybe<CallScheduleUpsertWithoutCallsInput>;
};

export type CallScheduleUpdateWithoutCallsInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  day?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryDayAddition?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTime?: Maybe<IntFieldUpdateOperationsInput>;
  location?: Maybe<LocationUpdateOneRequiredWithoutCallSchedulesInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CallScheduleUpdateWithoutLocationInput = {
  calls?: Maybe<CallUpdateManyWithoutCallScheduleInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  day?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryDayAddition?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTime?: Maybe<IntFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type CallScheduleUpdateWithWhereUniqueWithoutLocationInput = {
  data: CallScheduleUpdateWithoutLocationInput;
  where: CallScheduleWhereUniqueInput;
};

export type CallScheduleUpsertWithoutCallsInput = {
  create: CallScheduleCreateWithoutCallsInput;
  update: CallScheduleUpdateWithoutCallsInput;
};

export type CallScheduleUpsertWithWhereUniqueWithoutLocationInput = {
  create: CallScheduleCreateWithoutLocationInput;
  update: CallScheduleUpdateWithoutLocationInput;
  where: CallScheduleWhereUniqueInput;
};

export type CallScheduleWhereInput = {
  AND?: Maybe<Array<CallScheduleWhereInput>>;
  calls?: Maybe<CallListRelationFilter>;
  comment?: Maybe<StringFilter>;
  day?: Maybe<IntFilter>;
  deliveryDayAddition?: Maybe<IntFilter>;
  deliveryTime?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<LocationRelationFilter>;
  locationId?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CallScheduleWhereInput>>;
  OR?: Maybe<Array<CallScheduleWhereInput>>;
  phone?: Maybe<StringNullableFilter>;
};

export type CallScheduleWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type CallUpdateManyMutationInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  concluent?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  finished?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallUpdateManyWithoutCallScheduleInput = {
  connect?: Maybe<Array<CallWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallCreateOrConnectWithoutcallScheduleInput>>;
  create?: Maybe<Array<CallCreateWithoutCallScheduleInput>>;
  delete?: Maybe<Array<CallWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallScalarWhereInput>>;
  disconnect?: Maybe<Array<CallWhereUniqueInput>>;
  set?: Maybe<Array<CallWhereUniqueInput>>;
  update?: Maybe<Array<CallUpdateWithWhereUniqueWithoutCallScheduleInput>>;
  updateMany?: Maybe<Array<CallUpdateManyWithWhereWithoutCallScheduleInput>>;
  upsert?: Maybe<Array<CallUpsertWithWhereUniqueWithoutCallScheduleInput>>;
};

export type CallUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<CallWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallCreateOrConnectWithoutorderInput>>;
  create?: Maybe<Array<CallCreateWithoutOrderInput>>;
  delete?: Maybe<Array<CallWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallScalarWhereInput>>;
  disconnect?: Maybe<Array<CallWhereUniqueInput>>;
  set?: Maybe<Array<CallWhereUniqueInput>>;
  update?: Maybe<Array<CallUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<CallUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<CallUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type CallUpdateManyWithWhereWithoutCallScheduleInput = {
  data: CallUpdateManyMutationInput;
  where: CallScalarWhereInput;
};

export type CallUpdateManyWithWhereWithoutOrderInput = {
  data: CallUpdateManyMutationInput;
  where: CallScalarWhereInput;
};

export type CallUpdateWithoutCallScheduleInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  concluent?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  finished?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutCallsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallUpdateWithoutOrderInput = {
  callSchedule?: Maybe<CallScheduleUpdateOneRequiredWithoutCallsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  concluent?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  finished?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallUpdateWithWhereUniqueWithoutCallScheduleInput = {
  data: CallUpdateWithoutCallScheduleInput;
  where: CallWhereUniqueInput;
};

export type CallUpdateWithWhereUniqueWithoutOrderInput = {
  data: CallUpdateWithoutOrderInput;
  where: CallWhereUniqueInput;
};

export type CallUpsertWithWhereUniqueWithoutCallScheduleInput = {
  create: CallCreateWithoutCallScheduleInput;
  update: CallUpdateWithoutCallScheduleInput;
  where: CallWhereUniqueInput;
};

export type CallUpsertWithWhereUniqueWithoutOrderInput = {
  create: CallCreateWithoutOrderInput;
  update: CallUpdateWithoutOrderInput;
  where: CallWhereUniqueInput;
};

export type CallWhereInput = {
  AND?: Maybe<Array<CallWhereInput>>;
  callSchedule?: Maybe<CallScheduleRelationFilter>;
  callScheduleId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  concluent?: Maybe<BoolNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  finished?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CallWhereInput>>;
  OR?: Maybe<Array<CallWhereInput>>;
  order?: Maybe<OrderRelationFilter>;
  orderId?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type Cart = {
  __typename?: "Cart";
  cartItems?: Maybe<Array<CartItem>>;
  comment: Scalars["String"];
  id: Scalars["Int"];
  locations?: Maybe<Array<Location>>;
  orders?: Maybe<Array<Order>>;
};

export type CartCartItemsArgs = {
  cursor?: Maybe<CartItemWhereUniqueInput>;
  distinct?: Maybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: Maybe<Array<CartItemOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CartItemWhereInput>;
};

export type CartLocationsArgs = {
  cursor?: Maybe<LocationWhereUniqueInput>;
  distinct?: Maybe<Array<LocationScalarFieldEnum>>;
  orderBy?: Maybe<Array<LocationOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<LocationWhereInput>;
};

export type CartOrdersArgs = {
  cursor?: Maybe<OrderWhereUniqueInput>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
  orderBy?: Maybe<Array<OrderOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<OrderWhereInput>;
};

export type CartCreateOneWithoutCartItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutcartItemsInput>;
  create?: Maybe<CartCreateWithoutCartItemsInput>;
};

export type CartCreateOneWithoutLocationsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutlocationsInput>;
  create?: Maybe<CartCreateWithoutLocationsInput>;
};

export type CartCreateOneWithoutOrdersInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutordersInput>;
  create?: Maybe<CartCreateWithoutOrdersInput>;
};

export type CartCreateOrConnectWithoutcartItemsInput = {
  create: CartCreateWithoutCartItemsInput;
  where: CartWhereUniqueInput;
};

export type CartCreateOrConnectWithoutlocationsInput = {
  create: CartCreateWithoutLocationsInput;
  where: CartWhereUniqueInput;
};

export type CartCreateOrConnectWithoutordersInput = {
  create: CartCreateWithoutOrdersInput;
  where: CartWhereUniqueInput;
};

export type CartCreateWithoutCartItemsInput = {
  comment?: Maybe<Scalars["String"]>;
  locations?: Maybe<LocationCreateManyWithoutFullStockInput>;
  orders?: Maybe<OrderCreateManyWithoutCartInput>;
};

export type CartCreateWithoutLocationsInput = {
  cartItems?: Maybe<CartItemCreateManyWithoutCartInput>;
  comment?: Maybe<Scalars["String"]>;
  orders?: Maybe<OrderCreateManyWithoutCartInput>;
};

export type CartCreateWithoutOrdersInput = {
  cartItems?: Maybe<CartItemCreateManyWithoutCartInput>;
  comment?: Maybe<Scalars["String"]>;
  locations?: Maybe<LocationCreateManyWithoutFullStockInput>;
};

export type CartItem = {
  __typename?: "CartItem";
  bulk: Scalars["Boolean"];
  cart: Cart;
  cartId: Scalars["Int"];
  comment: Scalars["String"];
  id: Scalars["Int"];
  productPrice: ProductPrice;
  productPriceId: Scalars["Int"];
  qty: Scalars["Int"];
};

export type CartItemCreateManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutcartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
};

export type CartItemCreateManyWithoutProductPriceInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutproductPriceInput>>;
  create?: Maybe<Array<CartItemCreateWithoutProductPriceInput>>;
};

export type CartItemCreateOrConnectWithoutcartInput = {
  create: CartItemCreateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutproductPriceInput = {
  create: CartItemCreateWithoutProductPriceInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateWithoutCartInput = {
  bulk: Scalars["Boolean"];
  comment?: Maybe<Scalars["String"]>;
  productPrice: ProductPriceCreateOneWithoutCartItemsInput;
  qty: Scalars["Int"];
};

export type CartItemCreateWithoutProductPriceInput = {
  bulk: Scalars["Boolean"];
  cart: CartCreateOneWithoutCartItemsInput;
  comment?: Maybe<Scalars["String"]>;
  qty: Scalars["Int"];
};

export type CartItemListRelationFilter = {
  every?: Maybe<CartItemWhereInput>;
  none?: Maybe<CartItemWhereInput>;
  some?: Maybe<CartItemWhereInput>;
};

export type CartItemOrderByInput = {
  bulk?: Maybe<SortOrder>;
  cartId?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  productPriceId?: Maybe<SortOrder>;
  qty?: Maybe<SortOrder>;
};

export enum CartItemScalarFieldEnum {
  Bulk = "bulk",
  CartId = "cartId",
  Comment = "comment",
  Id = "id",
  ProductPriceId = "productPriceId",
  Qty = "qty"
}

export type CartItemScalarWhereInput = {
  AND?: Maybe<Array<CartItemScalarWhereInput>>;
  bulk?: Maybe<BoolFilter>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CartItemScalarWhereInput>>;
  OR?: Maybe<Array<CartItemScalarWhereInput>>;
  productPriceId?: Maybe<IntFilter>;
  qty?: Maybe<IntFilter>;
};

export type CartItemUpdateManyMutationInput = {
  bulk?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartItemUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutcartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutCartInput>>;
};

export type CartItemUpdateManyWithoutProductPriceInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutproductPriceInput>>;
  create?: Maybe<Array<CartItemCreateWithoutProductPriceInput>>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutProductPriceInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutProductPriceInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutProductPriceInput>>;
};

export type CartItemUpdateManyWithWhereWithoutCartInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithWhereWithoutProductPriceInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateWithoutCartInput = {
  bulk?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  productPrice?: Maybe<ProductPriceUpdateOneRequiredWithoutCartItemsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutProductPriceInput = {
  bulk?: Maybe<BoolFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutCartItemsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithWhereUniqueWithoutCartInput = {
  data: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithWhereUniqueWithoutProductPriceInput = {
  data: CartItemUpdateWithoutProductPriceInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithWhereUniqueWithoutCartInput = {
  create: CartItemCreateWithoutCartInput;
  update: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithWhereUniqueWithoutProductPriceInput = {
  create: CartItemCreateWithoutProductPriceInput;
  update: CartItemUpdateWithoutProductPriceInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemWhereInput = {
  AND?: Maybe<Array<CartItemWhereInput>>;
  bulk?: Maybe<BoolFilter>;
  cart?: Maybe<CartRelationFilter>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<CartItemWhereInput>>;
  OR?: Maybe<Array<CartItemWhereInput>>;
  productPrice?: Maybe<ProductPriceRelationFilter>;
  productPriceId?: Maybe<IntFilter>;
  qty?: Maybe<IntFilter>;
};

export type CartItemWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type CartRelationFilter = {
  is?: Maybe<CartWhereInput>;
  isNot?: Maybe<CartWhereInput>;
};

export type CartUpdateOneRequiredWithoutCartItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutcartItemsInput>;
  create?: Maybe<CartCreateWithoutCartItemsInput>;
  update?: Maybe<CartUpdateWithoutCartItemsInput>;
  upsert?: Maybe<CartUpsertWithoutCartItemsInput>;
};

export type CartUpdateOneRequiredWithoutLocationsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutlocationsInput>;
  create?: Maybe<CartCreateWithoutLocationsInput>;
  update?: Maybe<CartUpdateWithoutLocationsInput>;
  upsert?: Maybe<CartUpsertWithoutLocationsInput>;
};

export type CartUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutordersInput>;
  create?: Maybe<CartCreateWithoutOrdersInput>;
  update?: Maybe<CartUpdateWithoutOrdersInput>;
  upsert?: Maybe<CartUpsertWithoutOrdersInput>;
};

export type CartUpdateWithoutCartItemsInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  locations?: Maybe<LocationUpdateManyWithoutFullStockInput>;
  orders?: Maybe<OrderUpdateManyWithoutCartInput>;
};

export type CartUpdateWithoutLocationsInput = {
  cartItems?: Maybe<CartItemUpdateManyWithoutCartInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCartInput>;
};

export type CartUpdateWithoutOrdersInput = {
  cartItems?: Maybe<CartItemUpdateManyWithoutCartInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  locations?: Maybe<LocationUpdateManyWithoutFullStockInput>;
};

export type CartUpsertWithoutCartItemsInput = {
  create: CartCreateWithoutCartItemsInput;
  update: CartUpdateWithoutCartItemsInput;
};

export type CartUpsertWithoutLocationsInput = {
  create: CartCreateWithoutLocationsInput;
  update: CartUpdateWithoutLocationsInput;
};

export type CartUpsertWithoutOrdersInput = {
  create: CartCreateWithoutOrdersInput;
  update: CartUpdateWithoutOrdersInput;
};

export type CartWhereInput = {
  AND?: Maybe<Array<CartWhereInput>>;
  cartItems?: Maybe<CartItemListRelationFilter>;
  comment?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  locations?: Maybe<LocationListRelationFilter>;
  NOT?: Maybe<Array<CartWhereInput>>;
  OR?: Maybe<Array<CartWhereInput>>;
  orders?: Maybe<OrderListRelationFilter>;
};

export type CartWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export enum ChangeType {
  Change = "CHANGE",
  Set = "SET"
}

export type Customer = {
  __typename?: "Customer";
  firm?: Maybe<Firm>;
  firmId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  locations?: Maybe<Array<Location>>;
  name: Scalars["String"];
  user: User;
  userId: Scalars["Int"];
};

export type CustomerLocationsArgs = {
  cursor?: Maybe<LocationWhereUniqueInput>;
  distinct?: Maybe<Array<LocationScalarFieldEnum>>;
  orderBy?: Maybe<Array<LocationOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<LocationWhereInput>;
};

export type CustomerCreateManyWithoutFirmInput = {
  connect?: Maybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerCreateOrConnectWithoutfirmInput>>;
  create?: Maybe<Array<CustomerCreateWithoutFirmInput>>;
};

export type CustomerCreateManyWithoutLocationsInput = {
  connect?: Maybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerCreateOrConnectWithoutlocationsInput>>;
  create?: Maybe<Array<CustomerCreateWithoutLocationsInput>>;
};

export type CustomerCreateOneWithoutUserInput = {
  connect?: Maybe<CustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomerCreateOrConnectWithoutuserInput>;
  create?: Maybe<CustomerCreateWithoutUserInput>;
};

export type CustomerCreateOrConnectWithoutfirmInput = {
  create: CustomerCreateWithoutFirmInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutlocationsInput = {
  create: CustomerCreateWithoutLocationsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutuserInput = {
  create: CustomerCreateWithoutUserInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateWithoutFirmInput = {
  locations?: Maybe<LocationCreateManyWithoutCustomersInput>;
  name: Scalars["String"];
  user: UserCreateOneWithoutCustomerInput;
};

export type CustomerCreateWithoutLocationsInput = {
  firm?: Maybe<FirmCreateOneWithoutCustomerInput>;
  name: Scalars["String"];
  user: UserCreateOneWithoutCustomerInput;
};

export type CustomerCreateWithoutUserInput = {
  firm?: Maybe<FirmCreateOneWithoutCustomerInput>;
  locations?: Maybe<LocationCreateManyWithoutCustomersInput>;
  name: Scalars["String"];
};

export type CustomerListRelationFilter = {
  every?: Maybe<CustomerWhereInput>;
  none?: Maybe<CustomerWhereInput>;
  some?: Maybe<CustomerWhereInput>;
};

export type CustomerOrderByInput = {
  firmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CustomerRelationFilter = {
  is?: Maybe<CustomerWhereInput>;
  isNot?: Maybe<CustomerWhereInput>;
};

export enum CustomerScalarFieldEnum {
  FirmId = "firmId",
  Id = "id",
  Name = "name",
  UserId = "userId"
}

export type CustomerScalarWhereInput = {
  AND?: Maybe<Array<CustomerScalarWhereInput>>;
  firmId?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CustomerScalarWhereInput>>;
  OR?: Maybe<Array<CustomerScalarWhereInput>>;
  userId?: Maybe<IntFilter>;
};

export type CustomerUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CustomerUpdateManyWithoutFirmInput = {
  connect?: Maybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerCreateOrConnectWithoutfirmInput>>;
  create?: Maybe<Array<CustomerCreateWithoutFirmInput>>;
  delete?: Maybe<Array<CustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomerWhereUniqueInput>>;
  set?: Maybe<Array<CustomerWhereUniqueInput>>;
  update?: Maybe<Array<CustomerUpdateWithWhereUniqueWithoutFirmInput>>;
  updateMany?: Maybe<Array<CustomerUpdateManyWithWhereWithoutFirmInput>>;
  upsert?: Maybe<Array<CustomerUpsertWithWhereUniqueWithoutFirmInput>>;
};

export type CustomerUpdateManyWithoutLocationsInput = {
  connect?: Maybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerCreateOrConnectWithoutlocationsInput>>;
  create?: Maybe<Array<CustomerCreateWithoutLocationsInput>>;
  delete?: Maybe<Array<CustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomerWhereUniqueInput>>;
  set?: Maybe<Array<CustomerWhereUniqueInput>>;
  update?: Maybe<Array<CustomerUpdateWithWhereUniqueWithoutLocationsInput>>;
  updateMany?: Maybe<Array<CustomerUpdateManyWithWhereWithoutLocationsInput>>;
  upsert?: Maybe<Array<CustomerUpsertWithWhereUniqueWithoutLocationsInput>>;
};

export type CustomerUpdateManyWithWhereWithoutFirmInput = {
  data: CustomerUpdateManyMutationInput;
  where: CustomerScalarWhereInput;
};

export type CustomerUpdateManyWithWhereWithoutLocationsInput = {
  data: CustomerUpdateManyMutationInput;
  where: CustomerScalarWhereInput;
};

export type CustomerUpdateOneWithoutUserInput = {
  connect?: Maybe<CustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomerCreateOrConnectWithoutuserInput>;
  create?: Maybe<CustomerCreateWithoutUserInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<CustomerUpdateWithoutUserInput>;
  upsert?: Maybe<CustomerUpsertWithoutUserInput>;
};

export type CustomerUpdateWithoutFirmInput = {
  locations?: Maybe<LocationUpdateManyWithoutCustomersInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCustomerInput>;
};

export type CustomerUpdateWithoutLocationsInput = {
  firm?: Maybe<FirmUpdateOneWithoutCustomerInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCustomerInput>;
};

export type CustomerUpdateWithoutUserInput = {
  firm?: Maybe<FirmUpdateOneWithoutCustomerInput>;
  locations?: Maybe<LocationUpdateManyWithoutCustomersInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CustomerUpdateWithWhereUniqueWithoutFirmInput = {
  data: CustomerUpdateWithoutFirmInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpdateWithWhereUniqueWithoutLocationsInput = {
  data: CustomerUpdateWithoutLocationsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpsertWithoutUserInput = {
  create: CustomerCreateWithoutUserInput;
  update: CustomerUpdateWithoutUserInput;
};

export type CustomerUpsertWithWhereUniqueWithoutFirmInput = {
  create: CustomerCreateWithoutFirmInput;
  update: CustomerUpdateWithoutFirmInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpsertWithWhereUniqueWithoutLocationsInput = {
  create: CustomerCreateWithoutLocationsInput;
  update: CustomerUpdateWithoutLocationsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerWhereInput = {
  AND?: Maybe<Array<CustomerWhereInput>>;
  firm?: Maybe<FirmRelationFilter>;
  firmId?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  locations?: Maybe<LocationListRelationFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CustomerWhereInput>>;
  OR?: Maybe<Array<CustomerWhereInput>>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<IntFilter>;
};

export type CustomerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["DateTime"]>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type Employee = {
  __typename?: "Employee";
  id: Scalars["Int"];
  name: Scalars["String"];
  role: EmployeeRole;
  user: User;
  userId: Scalars["Int"];
};

export type EmployeeCreateOneWithoutUserInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutuserInput>;
  create?: Maybe<EmployeeCreateWithoutUserInput>;
};

export type EmployeeCreateOrConnectWithoutuserInput = {
  create: EmployeeCreateWithoutUserInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateWithoutUserInput = {
  name: Scalars["String"];
  role?: Maybe<EmployeeRole>;
};

export type EmployeeRelationFilter = {
  is?: Maybe<EmployeeWhereInput>;
  isNot?: Maybe<EmployeeWhereInput>;
};

export enum EmployeeRole {
  Admin = "ADMIN",
  Cheesemonger = "CHEESEMONGER"
}

export type EmployeeUpdateOneWithoutUserInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutuserInput>;
  create?: Maybe<EmployeeCreateWithoutUserInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<EmployeeUpdateWithoutUserInput>;
  upsert?: Maybe<EmployeeUpsertWithoutUserInput>;
};

export type EmployeeUpdateWithoutUserInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  role?: Maybe<EnumEmployeeRoleFieldUpdateOperationsInput>;
};

export type EmployeeUpsertWithoutUserInput = {
  create: EmployeeCreateWithoutUserInput;
  update: EmployeeUpdateWithoutUserInput;
};

export type EmployeeWhereInput = {
  AND?: Maybe<Array<EmployeeWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmployeeWhereInput>>;
  OR?: Maybe<Array<EmployeeWhereInput>>;
  role?: Maybe<EnumEmployeeRoleFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<IntFilter>;
};

export type EmployeeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type EnumChangeTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChangeType>;
};

export type EnumChangeTypeFilter = {
  equals?: Maybe<ChangeType>;
  in?: Maybe<Array<ChangeType>>;
  not?: Maybe<NestedEnumChangeTypeFilter>;
  notIn?: Maybe<Array<ChangeType>>;
};

export type EnumEmployeeRoleFieldUpdateOperationsInput = {
  set?: Maybe<EmployeeRole>;
};

export type EnumEmployeeRoleFilter = {
  equals?: Maybe<EmployeeRole>;
  in?: Maybe<Array<EmployeeRole>>;
  not?: Maybe<NestedEnumEmployeeRoleFilter>;
  notIn?: Maybe<Array<EmployeeRole>>;
};

export type EnumLocationTypeFieldUpdateOperationsInput = {
  set?: Maybe<LocationType>;
};

export type EnumLocationTypeFilter = {
  equals?: Maybe<LocationType>;
  in?: Maybe<Array<LocationType>>;
  not?: Maybe<NestedEnumLocationTypeFilter>;
  notIn?: Maybe<Array<LocationType>>;
};

export type EnumNotificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<NotificationStatus>;
};

export type EnumNotificationStatusFilter = {
  equals?: Maybe<NotificationStatus>;
  in?: Maybe<Array<NotificationStatus>>;
  not?: Maybe<NestedEnumNotificationStatusFilter>;
  notIn?: Maybe<Array<NotificationStatus>>;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<NotificationType>;
};

export type EnumNotificationTypeFilter = {
  equals?: Maybe<NotificationType>;
  in?: Maybe<Array<NotificationType>>;
  not?: Maybe<NestedEnumNotificationTypeFilter>;
  notIn?: Maybe<Array<NotificationType>>;
};

export type EnumOrderMethodFieldUpdateOperationsInput = {
  set?: Maybe<OrderMethod>;
};

export type EnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type EnumPaymentTypeFieldUpdateOperationsInput = {
  set?: Maybe<PaymentType>;
};

export type EnumPaymentTypeFilter = {
  equals?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  not?: Maybe<NestedEnumPaymentTypeFilter>;
  notIn?: Maybe<Array<PaymentType>>;
};

export type EnumStickerColorFieldUpdateOperationsInput = {
  set?: Maybe<StickerColor>;
};

export type EnumStickerColorFilter = {
  equals?: Maybe<StickerColor>;
  in?: Maybe<Array<StickerColor>>;
  not?: Maybe<NestedEnumStickerColorFilter>;
  notIn?: Maybe<Array<StickerColor>>;
};

export type Firm = {
  __typename?: "Firm";
  billingAddress: Scalars["String"];
  customer?: Maybe<Array<Customer>>;
  disabled: Scalars["Boolean"];
  id: Scalars["Int"];
  locations?: Maybe<Array<Location>>;
  name: Scalars["String"];
};

export type FirmCustomerArgs = {
  cursor?: Maybe<CustomerWhereUniqueInput>;
  distinct?: Maybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: Maybe<Array<CustomerOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CustomerWhereInput>;
};

export type FirmLocationsArgs = {
  cursor?: Maybe<LocationWhereUniqueInput>;
  distinct?: Maybe<Array<LocationScalarFieldEnum>>;
  orderBy?: Maybe<Array<LocationOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<LocationWhereInput>;
};

export type FirmCreateInput = {
  billingAddress: Scalars["String"];
  customer?: Maybe<CustomerCreateManyWithoutFirmInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  locations?: Maybe<LocationCreateManyWithoutFirmInput>;
  name: Scalars["String"];
};

export type FirmCreateOneWithoutCustomerInput = {
  connect?: Maybe<FirmWhereUniqueInput>;
  connectOrCreate?: Maybe<FirmCreateOrConnectWithoutcustomerInput>;
  create?: Maybe<FirmCreateWithoutCustomerInput>;
};

export type FirmCreateOneWithoutLocationsInput = {
  connect?: Maybe<FirmWhereUniqueInput>;
  connectOrCreate?: Maybe<FirmCreateOrConnectWithoutlocationsInput>;
  create?: Maybe<FirmCreateWithoutLocationsInput>;
};

export type FirmCreateOrConnectWithoutcustomerInput = {
  create: FirmCreateWithoutCustomerInput;
  where: FirmWhereUniqueInput;
};

export type FirmCreateOrConnectWithoutlocationsInput = {
  create: FirmCreateWithoutLocationsInput;
  where: FirmWhereUniqueInput;
};

export type FirmCreateWithoutCustomerInput = {
  billingAddress: Scalars["String"];
  disabled?: Maybe<Scalars["Boolean"]>;
  locations?: Maybe<LocationCreateManyWithoutFirmInput>;
  name: Scalars["String"];
};

export type FirmCreateWithoutLocationsInput = {
  billingAddress: Scalars["String"];
  customer?: Maybe<CustomerCreateManyWithoutFirmInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type FirmOrderByInput = {
  billingAddress?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type FirmRelationFilter = {
  is?: Maybe<FirmWhereInput>;
  isNot?: Maybe<FirmWhereInput>;
};

export enum FirmScalarFieldEnum {
  BillingAddress = "billingAddress",
  Disabled = "disabled",
  Id = "id",
  Name = "name"
}

export type FirmUpdateInput = {
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  customer?: Maybe<CustomerUpdateManyWithoutFirmInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locations?: Maybe<LocationUpdateManyWithoutFirmInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FirmUpdateOneRequiredWithoutLocationsInput = {
  connect?: Maybe<FirmWhereUniqueInput>;
  connectOrCreate?: Maybe<FirmCreateOrConnectWithoutlocationsInput>;
  create?: Maybe<FirmCreateWithoutLocationsInput>;
  update?: Maybe<FirmUpdateWithoutLocationsInput>;
  upsert?: Maybe<FirmUpsertWithoutLocationsInput>;
};

export type FirmUpdateOneWithoutCustomerInput = {
  connect?: Maybe<FirmWhereUniqueInput>;
  connectOrCreate?: Maybe<FirmCreateOrConnectWithoutcustomerInput>;
  create?: Maybe<FirmCreateWithoutCustomerInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<FirmUpdateWithoutCustomerInput>;
  upsert?: Maybe<FirmUpsertWithoutCustomerInput>;
};

export type FirmUpdateWithoutCustomerInput = {
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locations?: Maybe<LocationUpdateManyWithoutFirmInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FirmUpdateWithoutLocationsInput = {
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  customer?: Maybe<CustomerUpdateManyWithoutFirmInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FirmUpsertWithoutCustomerInput = {
  create: FirmCreateWithoutCustomerInput;
  update: FirmUpdateWithoutCustomerInput;
};

export type FirmUpsertWithoutLocationsInput = {
  create: FirmCreateWithoutLocationsInput;
  update: FirmUpdateWithoutLocationsInput;
};

export type FirmWhereInput = {
  AND?: Maybe<Array<FirmWhereInput>>;
  billingAddress?: Maybe<StringFilter>;
  customer?: Maybe<CustomerListRelationFilter>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  locations?: Maybe<LocationListRelationFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<FirmWhereInput>>;
  OR?: Maybe<Array<FirmWhereInput>>;
};

export type FirmWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars["Float"]>;
  divide?: Maybe<Scalars["Float"]>;
  increment?: Maybe<Scalars["Float"]>;
  multiply?: Maybe<Scalars["Float"]>;
  set?: Maybe<Scalars["Float"]>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars["Int"]>;
  divide?: Maybe<Scalars["Int"]>;
  increment?: Maybe<Scalars["Int"]>;
  multiply?: Maybe<Scalars["Int"]>;
  set?: Maybe<Scalars["Int"]>;
};

export type IntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars["Int"]>>;
};

export type Location = {
  __typename?: "Location";
  address: Scalars["String"];
  billingAddress: Scalars["String"];
  callSchedules?: Maybe<Array<CallSchedule>>;
  cartId: Scalars["Int"];
  comment: Scalars["String"];
  customers?: Maybe<Array<Customer>>;
  disabled: Scalars["Boolean"];
  firm: Firm;
  firmId: Scalars["Int"];
  fullStock: Cart;
  id: Scalars["Int"];
  locationType: LocationType;
  monthly: Scalars["Boolean"];
  name: Scalars["String"];
  orderDates?: Maybe<Array<Scalars["Int"]>>;
  orderMethod: OrderMethod;
  orders?: Maybe<Array<Order>>;
  paymentType: PaymentType;
  phones?: Maybe<Array<Scalars["String"]>>;
  printBarcode: Scalars["Boolean"];
  printDate: Scalars["Boolean"];
  send: Scalars["Boolean"];
  stickerColor: StickerColor;
};

export type LocationCallSchedulesArgs = {
  cursor?: Maybe<CallScheduleWhereUniqueInput>;
  distinct?: Maybe<Array<CallScheduleScalarFieldEnum>>;
  orderBy?: Maybe<Array<CallScheduleOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CallScheduleWhereInput>;
};

export type LocationCustomersArgs = {
  cursor?: Maybe<CustomerWhereUniqueInput>;
  distinct?: Maybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: Maybe<Array<CustomerOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CustomerWhereInput>;
};

export type LocationOrdersArgs = {
  cursor?: Maybe<OrderWhereUniqueInput>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
  orderBy?: Maybe<Array<OrderOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<OrderWhereInput>;
};

export type LocationCreateInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  callSchedules?: Maybe<CallScheduleCreateManyWithoutLocationInput>;
  comment?: Maybe<Scalars["String"]>;
  customers?: Maybe<CustomerCreateManyWithoutLocationsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  firm: FirmCreateOneWithoutLocationsInput;
  fullStock: CartCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  orders?: Maybe<OrderCreateManyWithoutLocationInput>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationCreateManyWithoutCustomersInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutcustomersInput>>;
  create?: Maybe<Array<LocationCreateWithoutCustomersInput>>;
};

export type LocationCreateManyWithoutFirmInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutfirmInput>>;
  create?: Maybe<Array<LocationCreateWithoutFirmInput>>;
};

export type LocationCreateManyWithoutFullStockInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutfullStockInput>>;
  create?: Maybe<Array<LocationCreateWithoutFullStockInput>>;
};

export type LocationCreateOneWithoutCallSchedulesInput = {
  connect?: Maybe<LocationWhereUniqueInput>;
  connectOrCreate?: Maybe<LocationCreateOrConnectWithoutcallSchedulesInput>;
  create?: Maybe<LocationCreateWithoutCallSchedulesInput>;
};

export type LocationCreateOneWithoutOrdersInput = {
  connect?: Maybe<LocationWhereUniqueInput>;
  connectOrCreate?: Maybe<LocationCreateOrConnectWithoutordersInput>;
  create?: Maybe<LocationCreateWithoutOrdersInput>;
};

export type LocationCreateOrConnectWithoutcallSchedulesInput = {
  create: LocationCreateWithoutCallSchedulesInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutcustomersInput = {
  create: LocationCreateWithoutCustomersInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutfirmInput = {
  create: LocationCreateWithoutFirmInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutfullStockInput = {
  create: LocationCreateWithoutFullStockInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutordersInput = {
  create: LocationCreateWithoutOrdersInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateorderDatesInput = {
  set: Array<Scalars["Int"]>;
};

export type LocationCreatephonesInput = {
  set: Array<Scalars["String"]>;
};

export type LocationCreateWithoutCallSchedulesInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  customers?: Maybe<CustomerCreateManyWithoutLocationsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  firm: FirmCreateOneWithoutLocationsInput;
  fullStock: CartCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  orders?: Maybe<OrderCreateManyWithoutLocationInput>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationCreateWithoutCustomersInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  callSchedules?: Maybe<CallScheduleCreateManyWithoutLocationInput>;
  comment?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  firm: FirmCreateOneWithoutLocationsInput;
  fullStock: CartCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  orders?: Maybe<OrderCreateManyWithoutLocationInput>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationCreateWithoutFirmInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  callSchedules?: Maybe<CallScheduleCreateManyWithoutLocationInput>;
  comment?: Maybe<Scalars["String"]>;
  customers?: Maybe<CustomerCreateManyWithoutLocationsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  fullStock: CartCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  orders?: Maybe<OrderCreateManyWithoutLocationInput>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationCreateWithoutFullStockInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  callSchedules?: Maybe<CallScheduleCreateManyWithoutLocationInput>;
  comment?: Maybe<Scalars["String"]>;
  customers?: Maybe<CustomerCreateManyWithoutLocationsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  firm: FirmCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  orders?: Maybe<OrderCreateManyWithoutLocationInput>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationCreateWithoutOrdersInput = {
  address: Scalars["String"];
  billingAddress?: Maybe<Scalars["String"]>;
  callSchedules?: Maybe<CallScheduleCreateManyWithoutLocationInput>;
  comment?: Maybe<Scalars["String"]>;
  customers?: Maybe<CustomerCreateManyWithoutLocationsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  firm: FirmCreateOneWithoutLocationsInput;
  fullStock: CartCreateOneWithoutLocationsInput;
  locationType: LocationType;
  monthly?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderDates?: Maybe<LocationCreateorderDatesInput>;
  orderMethod?: Maybe<OrderMethod>;
  paymentType?: Maybe<PaymentType>;
  phones?: Maybe<LocationCreatephonesInput>;
  printBarcode?: Maybe<Scalars["Boolean"]>;
  printDate?: Maybe<Scalars["Boolean"]>;
  send?: Maybe<Scalars["Boolean"]>;
  stickerColor?: Maybe<StickerColor>;
};

export type LocationListRelationFilter = {
  every?: Maybe<LocationWhereInput>;
  none?: Maybe<LocationWhereInput>;
  some?: Maybe<LocationWhereInput>;
};

export type LocationOrderByInput = {
  address?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  cartId?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  firmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationType?: Maybe<SortOrder>;
  monthly?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderDates?: Maybe<SortOrder>;
  orderMethod?: Maybe<SortOrder>;
  paymentType?: Maybe<SortOrder>;
  phones?: Maybe<SortOrder>;
  printBarcode?: Maybe<SortOrder>;
  printDate?: Maybe<SortOrder>;
  send?: Maybe<SortOrder>;
  stickerColor?: Maybe<SortOrder>;
};

export type LocationRelationFilter = {
  is?: Maybe<LocationWhereInput>;
  isNot?: Maybe<LocationWhereInput>;
};

export enum LocationScalarFieldEnum {
  Address = "address",
  BillingAddress = "billingAddress",
  CartId = "cartId",
  Comment = "comment",
  Disabled = "disabled",
  FirmId = "firmId",
  Id = "id",
  LocationType = "locationType",
  Monthly = "monthly",
  Name = "name",
  OrderDates = "orderDates",
  OrderMethod = "orderMethod",
  PaymentType = "paymentType",
  Phones = "phones",
  PrintBarcode = "printBarcode",
  PrintDate = "printDate",
  Send = "send",
  StickerColor = "stickerColor"
}

export type LocationScalarWhereInput = {
  address?: Maybe<StringFilter>;
  AND?: Maybe<Array<LocationScalarWhereInput>>;
  billingAddress?: Maybe<StringFilter>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  disabled?: Maybe<BoolFilter>;
  firmId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  locationType?: Maybe<EnumLocationTypeFilter>;
  monthly?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LocationScalarWhereInput>>;
  OR?: Maybe<Array<LocationScalarWhereInput>>;
  orderDates?: Maybe<IntNullableListFilter>;
  orderMethod?: Maybe<EnumOrderMethodFilter>;
  paymentType?: Maybe<EnumPaymentTypeFilter>;
  phones?: Maybe<StringNullableListFilter>;
  printBarcode?: Maybe<BoolFilter>;
  printDate?: Maybe<BoolFilter>;
  send?: Maybe<BoolFilter>;
  stickerColor?: Maybe<EnumStickerColorFilter>;
};

export enum LocationType {
  Enduser = "ENDUSER",
  Restaurateur = "RESTAURATEUR",
  Retailer = "RETAILER"
}

export type LocationUpdateInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  callSchedules?: Maybe<CallScheduleUpdateManyWithoutLocationInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  customers?: Maybe<CustomerUpdateManyWithoutLocationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  firm?: Maybe<FirmUpdateOneRequiredWithoutLocationsInput>;
  fullStock?: Maybe<CartUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutLocationInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateManyMutationInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithoutCustomersInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutcustomersInput>>;
  create?: Maybe<Array<LocationCreateWithoutCustomersInput>>;
  delete?: Maybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LocationScalarWhereInput>>;
  disconnect?: Maybe<Array<LocationWhereUniqueInput>>;
  set?: Maybe<Array<LocationWhereUniqueInput>>;
  update?: Maybe<Array<LocationUpdateWithWhereUniqueWithoutCustomersInput>>;
  updateMany?: Maybe<Array<LocationUpdateManyWithWhereWithoutCustomersInput>>;
  upsert?: Maybe<Array<LocationUpsertWithWhereUniqueWithoutCustomersInput>>;
};

export type LocationUpdateManyWithoutFirmInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutfirmInput>>;
  create?: Maybe<Array<LocationCreateWithoutFirmInput>>;
  delete?: Maybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LocationScalarWhereInput>>;
  disconnect?: Maybe<Array<LocationWhereUniqueInput>>;
  set?: Maybe<Array<LocationWhereUniqueInput>>;
  update?: Maybe<Array<LocationUpdateWithWhereUniqueWithoutFirmInput>>;
  updateMany?: Maybe<Array<LocationUpdateManyWithWhereWithoutFirmInput>>;
  upsert?: Maybe<Array<LocationUpsertWithWhereUniqueWithoutFirmInput>>;
};

export type LocationUpdateManyWithoutFullStockInput = {
  connect?: Maybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LocationCreateOrConnectWithoutfullStockInput>>;
  create?: Maybe<Array<LocationCreateWithoutFullStockInput>>;
  delete?: Maybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LocationScalarWhereInput>>;
  disconnect?: Maybe<Array<LocationWhereUniqueInput>>;
  set?: Maybe<Array<LocationWhereUniqueInput>>;
  update?: Maybe<Array<LocationUpdateWithWhereUniqueWithoutFullStockInput>>;
  updateMany?: Maybe<Array<LocationUpdateManyWithWhereWithoutFullStockInput>>;
  upsert?: Maybe<Array<LocationUpsertWithWhereUniqueWithoutFullStockInput>>;
};

export type LocationUpdateManyWithWhereWithoutCustomersInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithWhereWithoutFirmInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithWhereWithoutFullStockInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateOneRequiredWithoutCallSchedulesInput = {
  connect?: Maybe<LocationWhereUniqueInput>;
  connectOrCreate?: Maybe<LocationCreateOrConnectWithoutcallSchedulesInput>;
  create?: Maybe<LocationCreateWithoutCallSchedulesInput>;
  update?: Maybe<LocationUpdateWithoutCallSchedulesInput>;
  upsert?: Maybe<LocationUpsertWithoutCallSchedulesInput>;
};

export type LocationUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<LocationWhereUniqueInput>;
  connectOrCreate?: Maybe<LocationCreateOrConnectWithoutordersInput>;
  create?: Maybe<LocationCreateWithoutOrdersInput>;
  update?: Maybe<LocationUpdateWithoutOrdersInput>;
  upsert?: Maybe<LocationUpsertWithoutOrdersInput>;
};

export type LocationUpdateorderDatesInput = {
  set: Array<Scalars["Int"]>;
};

export type LocationUpdatephonesInput = {
  set: Array<Scalars["String"]>;
};

export type LocationUpdateWithoutCallSchedulesInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  customers?: Maybe<CustomerUpdateManyWithoutLocationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  firm?: Maybe<FirmUpdateOneRequiredWithoutLocationsInput>;
  fullStock?: Maybe<CartUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutLocationInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutCustomersInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  callSchedules?: Maybe<CallScheduleUpdateManyWithoutLocationInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  firm?: Maybe<FirmUpdateOneRequiredWithoutLocationsInput>;
  fullStock?: Maybe<CartUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutLocationInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutFirmInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  callSchedules?: Maybe<CallScheduleUpdateManyWithoutLocationInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  customers?: Maybe<CustomerUpdateManyWithoutLocationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fullStock?: Maybe<CartUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutLocationInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutFullStockInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  callSchedules?: Maybe<CallScheduleUpdateManyWithoutLocationInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  customers?: Maybe<CustomerUpdateManyWithoutLocationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  firm?: Maybe<FirmUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutLocationInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateWithoutOrdersInput = {
  address?: Maybe<StringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<StringFieldUpdateOperationsInput>;
  callSchedules?: Maybe<CallScheduleUpdateManyWithoutLocationInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  customers?: Maybe<CustomerUpdateManyWithoutLocationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  firm?: Maybe<FirmUpdateOneRequiredWithoutLocationsInput>;
  fullStock?: Maybe<CartUpdateOneRequiredWithoutLocationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  monthly?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderDates?: Maybe<LocationUpdateorderDatesInput>;
  orderMethod?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  paymentType?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  phones?: Maybe<LocationUpdatephonesInput>;
  printBarcode?: Maybe<BoolFieldUpdateOperationsInput>;
  printDate?: Maybe<BoolFieldUpdateOperationsInput>;
  send?: Maybe<BoolFieldUpdateOperationsInput>;
  stickerColor?: Maybe<EnumStickerColorFieldUpdateOperationsInput>;
};

export type LocationUpdateWithWhereUniqueWithoutCustomersInput = {
  data: LocationUpdateWithoutCustomersInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithWhereUniqueWithoutFirmInput = {
  data: LocationUpdateWithoutFirmInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithWhereUniqueWithoutFullStockInput = {
  data: LocationUpdateWithoutFullStockInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithoutCallSchedulesInput = {
  create: LocationCreateWithoutCallSchedulesInput;
  update: LocationUpdateWithoutCallSchedulesInput;
};

export type LocationUpsertWithoutOrdersInput = {
  create: LocationCreateWithoutOrdersInput;
  update: LocationUpdateWithoutOrdersInput;
};

export type LocationUpsertWithWhereUniqueWithoutCustomersInput = {
  create: LocationCreateWithoutCustomersInput;
  update: LocationUpdateWithoutCustomersInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithWhereUniqueWithoutFirmInput = {
  create: LocationCreateWithoutFirmInput;
  update: LocationUpdateWithoutFirmInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithWhereUniqueWithoutFullStockInput = {
  create: LocationCreateWithoutFullStockInput;
  update: LocationUpdateWithoutFullStockInput;
  where: LocationWhereUniqueInput;
};

export type LocationWhereInput = {
  address?: Maybe<StringFilter>;
  AND?: Maybe<Array<LocationWhereInput>>;
  billingAddress?: Maybe<StringFilter>;
  callSchedules?: Maybe<CallScheduleListRelationFilter>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  customers?: Maybe<CustomerListRelationFilter>;
  disabled?: Maybe<BoolFilter>;
  firm?: Maybe<FirmRelationFilter>;
  firmId?: Maybe<IntFilter>;
  fullStock?: Maybe<CartRelationFilter>;
  id?: Maybe<IntFilter>;
  locationType?: Maybe<EnumLocationTypeFilter>;
  monthly?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LocationWhereInput>>;
  OR?: Maybe<Array<LocationWhereInput>>;
  orderDates?: Maybe<IntNullableListFilter>;
  orderMethod?: Maybe<EnumOrderMethodFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  paymentType?: Maybe<EnumPaymentTypeFilter>;
  phones?: Maybe<StringNullableListFilter>;
  printBarcode?: Maybe<BoolFilter>;
  printDate?: Maybe<BoolFilter>;
  send?: Maybe<BoolFilter>;
  stickerColor?: Maybe<EnumStickerColorFilter>;
};

export type LocationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  applyStockAction: Scalars["Boolean"];
  createCallSchedule: CallSchedule;
  createFirm: Firm;
  createLocation: Location;
  createOrder: Order;
  createProduct: Product;
  createProductCategory: ProductCategory;
  createStock: Stock;
  createStockAction: StockAction;
  login: Scalars["String"];
  setOrderDelivered?: Maybe<Order>;
  updateCallSchedule?: Maybe<CallSchedule>;
  updateFirm?: Maybe<Firm>;
  updateLocation?: Maybe<Location>;
  updateOrder?: Maybe<Order>;
  updateOrders?: Maybe<BatchPayload>;
  updateProduct?: Maybe<Product>;
  updateProductCategory?: Maybe<ProductCategory>;
  updateStock?: Maybe<Stock>;
  updateStockAction?: Maybe<StockAction>;
};

export type MutationApplyStockActionArgs = {
  data: StockActionApply;
};

export type MutationCreateCallScheduleArgs = {
  data: CallScheduleCreateInput;
};

export type MutationCreateFirmArgs = {
  data: FirmCreateInput;
};

export type MutationCreateLocationArgs = {
  data: LocationCreateInput;
};

export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};

export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};

export type MutationCreateProductCategoryArgs = {
  data: ProductCategoryCreateInput;
};

export type MutationCreateStockArgs = {
  data: StockCreateInput;
};

export type MutationCreateStockActionArgs = {
  data: StockActionCreateInput;
};

export type MutationLoginArgs = {
  input: UserAuth;
};

export type MutationSetOrderDeliveredArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpdateCallScheduleArgs = {
  data: CallScheduleUpdateInput;
  where: CallScheduleWhereUniqueInput;
};

export type MutationUpdateFirmArgs = {
  data: FirmUpdateInput;
  where: FirmWhereUniqueInput;
};

export type MutationUpdateLocationArgs = {
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};

export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpdateOrdersArgs = {
  data: OrderUpdateManyMutationInput;
  where?: Maybe<OrderWhereInput>;
};

export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpdateProductCategoryArgs = {
  data: ProductCategoryUpdateInput;
  where: ProductCategoryWhereUniqueInput;
};

export type MutationUpdateStockArgs = {
  data: StockUpdateInput;
  where: StockWhereUniqueInput;
};

export type MutationUpdateStockActionArgs = {
  data: StockActionUpdateInput;
  where: StockActionWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
};

export type NestedEnumChangeTypeFilter = {
  equals?: Maybe<ChangeType>;
  in?: Maybe<Array<ChangeType>>;
  not?: Maybe<NestedEnumChangeTypeFilter>;
  notIn?: Maybe<Array<ChangeType>>;
};

export type NestedEnumEmployeeRoleFilter = {
  equals?: Maybe<EmployeeRole>;
  in?: Maybe<Array<EmployeeRole>>;
  not?: Maybe<NestedEnumEmployeeRoleFilter>;
  notIn?: Maybe<Array<EmployeeRole>>;
};

export type NestedEnumLocationTypeFilter = {
  equals?: Maybe<LocationType>;
  in?: Maybe<Array<LocationType>>;
  not?: Maybe<NestedEnumLocationTypeFilter>;
  notIn?: Maybe<Array<LocationType>>;
};

export type NestedEnumNotificationStatusFilter = {
  equals?: Maybe<NotificationStatus>;
  in?: Maybe<Array<NotificationStatus>>;
  not?: Maybe<NestedEnumNotificationStatusFilter>;
  notIn?: Maybe<Array<NotificationStatus>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: Maybe<NotificationType>;
  in?: Maybe<Array<NotificationType>>;
  not?: Maybe<NestedEnumNotificationTypeFilter>;
  notIn?: Maybe<Array<NotificationType>>;
};

export type NestedEnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentTypeFilter = {
  equals?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  not?: Maybe<NestedEnumPaymentTypeFilter>;
  notIn?: Maybe<Array<PaymentType>>;
};

export type NestedEnumStickerColorFilter = {
  equals?: Maybe<StickerColor>;
  in?: Maybe<Array<StickerColor>>;
  not?: Maybe<NestedEnumStickerColorFilter>;
  notIn?: Maybe<Array<StickerColor>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type Notification = {
  __typename?: "Notification";
  content: Scalars["String"];
  id: Scalars["Int"];
  link?: Maybe<Scalars["String"]>;
  status: NotificationStatus;
  title: Scalars["String"];
  type: NotificationType;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["Int"]>;
};

export type NotificationCreateManyWithoutUserInput = {
  connect?: Maybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationCreateOrConnectWithoutuserInput>>;
  create?: Maybe<Array<NotificationCreateWithoutUserInput>>;
};

export type NotificationCreateOrConnectWithoutuserInput = {
  create: NotificationCreateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutUserInput = {
  content: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  status?: Maybe<NotificationStatus>;
  title: Scalars["String"];
  type?: Maybe<NotificationType>;
};

export type NotificationListRelationFilter = {
  every?: Maybe<NotificationWhereInput>;
  none?: Maybe<NotificationWhereInput>;
  some?: Maybe<NotificationWhereInput>;
};

export type NotificationOrderByInput = {
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  link?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  Content = "content",
  Id = "id",
  Link = "link",
  Status = "status",
  Title = "title",
  Type = "type",
  UserId = "userId"
}

export type NotificationScalarWhereInput = {
  AND?: Maybe<Array<NotificationScalarWhereInput>>;
  content?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  link?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<NotificationScalarWhereInput>>;
  OR?: Maybe<Array<NotificationScalarWhereInput>>;
  status?: Maybe<EnumNotificationStatusFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumNotificationTypeFilter>;
  userId?: Maybe<IntNullableFilter>;
};

export enum NotificationStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Read = "READ"
}

export enum NotificationType {
  Fallbacked = "FALLBACKED",
  None = "NONE"
}

export type NotificationUpdateManyMutationInput = {
  content?: Maybe<StringFieldUpdateOperationsInput>;
  link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumNotificationTypeFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationCreateOrConnectWithoutuserInput>>;
  create?: Maybe<Array<NotificationCreateWithoutUserInput>>;
  delete?: Maybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<NotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<NotificationWhereUniqueInput>>;
  set?: Maybe<Array<NotificationWhereUniqueInput>>;
  update?: Maybe<Array<NotificationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<NotificationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<NotificationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NotificationUpdateManyWithWhereWithoutUserInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateWithoutUserInput = {
  content?: Maybe<StringFieldUpdateOperationsInput>;
  link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumNotificationStatusFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumNotificationTypeFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithWhereUniqueWithoutUserInput = {
  data: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  update: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: Maybe<Array<NotificationWhereInput>>;
  content?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  link?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  status?: Maybe<EnumNotificationStatusFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumNotificationTypeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<IntNullableFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Boolean"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["DateTime"]>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars["Int"]>;
  divide?: Maybe<Scalars["Int"]>;
  increment?: Maybe<Scalars["Int"]>;
  multiply?: Maybe<Scalars["Int"]>;
  set?: Maybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export type Order = {
  __typename?: "Order";
  calls?: Maybe<Array<Call>>;
  cart: Cart;
  cartId: Scalars["Int"];
  comment: Scalars["String"];
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  externalRef?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  location: Location;
  locationId: Scalars["Int"];
  paidAt?: Maybe<Scalars["DateTime"]>;
  status: OrderStatus;
  when: Scalars["DateTime"];
};

export type OrderCallsArgs = {
  cursor?: Maybe<CallWhereUniqueInput>;
  distinct?: Maybe<Array<CallScalarFieldEnum>>;
  orderBy?: Maybe<Array<CallOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CallWhereInput>;
};

export type OrderCreateInput = {
  calls?: Maybe<CallCreateManyWithoutOrderInput>;
  cart: CartCreateOneWithoutOrdersInput;
  comment?: Maybe<Scalars["String"]>;
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  externalRef?: Maybe<Scalars["String"]>;
  location: LocationCreateOneWithoutOrdersInput;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<OrderStatus>;
  when: Scalars["DateTime"];
};

export type OrderCreateManyWithoutCartInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutcartInput>>;
  create?: Maybe<Array<OrderCreateWithoutCartInput>>;
};

export type OrderCreateManyWithoutLocationInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutlocationInput>>;
  create?: Maybe<Array<OrderCreateWithoutLocationInput>>;
};

export type OrderCreateOneWithoutCallsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutcallsInput>;
  create?: Maybe<OrderCreateWithoutCallsInput>;
};

export type OrderCreateOrConnectWithoutcallsInput = {
  create: OrderCreateWithoutCallsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutcartInput = {
  create: OrderCreateWithoutCartInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutlocationInput = {
  create: OrderCreateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCallsInput = {
  cart: CartCreateOneWithoutOrdersInput;
  comment?: Maybe<Scalars["String"]>;
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  externalRef?: Maybe<Scalars["String"]>;
  location: LocationCreateOneWithoutOrdersInput;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<OrderStatus>;
  when: Scalars["DateTime"];
};

export type OrderCreateWithoutCartInput = {
  calls?: Maybe<CallCreateManyWithoutOrderInput>;
  comment?: Maybe<Scalars["String"]>;
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  externalRef?: Maybe<Scalars["String"]>;
  location: LocationCreateOneWithoutOrdersInput;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<OrderStatus>;
  when: Scalars["DateTime"];
};

export type OrderCreateWithoutLocationInput = {
  calls?: Maybe<CallCreateManyWithoutOrderInput>;
  cart: CartCreateOneWithoutOrdersInput;
  comment?: Maybe<Scalars["String"]>;
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  externalRef?: Maybe<Scalars["String"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<OrderStatus>;
  when: Scalars["DateTime"];
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
};

export enum OrderMethod {
  FromBasis = "FROM_BASIS",
  Normal = "NORMAL",
  Permanent = "PERMANENT"
}

export type OrderOrderByInput = {
  cartId?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  deliveredAt?: Maybe<SortOrder>;
  externalRef?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  when?: Maybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: Maybe<OrderWhereInput>;
  isNot?: Maybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  CartId = "cartId",
  Comment = "comment",
  DeliveredAt = "deliveredAt",
  ExternalRef = "externalRef",
  Id = "id",
  LocationId = "locationId",
  PaidAt = "paidAt",
  Status = "status",
  When = "when"
}

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<OrderScalarWhereInput>>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  externalRef?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  locationId?: Maybe<IntFilter>;
  NOT?: Maybe<Array<OrderScalarWhereInput>>;
  OR?: Maybe<Array<OrderScalarWhereInput>>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  when?: Maybe<DateTimeFilter>;
};

export enum OrderStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Invoiced = "INVOICED",
  Paid = "PAID"
}

export type OrderUpdateInput = {
  calls?: Maybe<CallUpdateManyWithoutOrderInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutOrdersInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalRef?: Maybe<NullableStringFieldUpdateOperationsInput>;
  location?: Maybe<LocationUpdateOneRequiredWithoutOrdersInput>;
  paidAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  when?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyMutationInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalRef?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  when?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutcartInput>>;
  create?: Maybe<Array<OrderCreateWithoutCartInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutCartInput>>;
};

export type OrderUpdateManyWithoutLocationInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutlocationInput>>;
  create?: Maybe<Array<OrderCreateWithoutLocationInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type OrderUpdateManyWithWhereWithoutCartInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutLocationInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateOneWithoutCallsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutcallsInput>;
  create?: Maybe<OrderCreateWithoutCallsInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<OrderUpdateWithoutCallsInput>;
  upsert?: Maybe<OrderUpsertWithoutCallsInput>;
};

export type OrderUpdateWithoutCallsInput = {
  cart?: Maybe<CartUpdateOneRequiredWithoutOrdersInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalRef?: Maybe<NullableStringFieldUpdateOperationsInput>;
  location?: Maybe<LocationUpdateOneRequiredWithoutOrdersInput>;
  paidAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  when?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutCartInput = {
  calls?: Maybe<CallUpdateManyWithoutOrderInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalRef?: Maybe<NullableStringFieldUpdateOperationsInput>;
  location?: Maybe<LocationUpdateOneRequiredWithoutOrdersInput>;
  paidAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  when?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutLocationInput = {
  calls?: Maybe<CallUpdateManyWithoutOrderInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutOrdersInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalRef?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  when?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCartInput = {
  data: OrderUpdateWithoutCartInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutLocationInput = {
  data: OrderUpdateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutCallsInput = {
  create: OrderCreateWithoutCallsInput;
  update: OrderUpdateWithoutCallsInput;
};

export type OrderUpsertWithWhereUniqueWithoutCartInput = {
  create: OrderCreateWithoutCartInput;
  update: OrderUpdateWithoutCartInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutLocationInput = {
  create: OrderCreateWithoutLocationInput;
  update: OrderUpdateWithoutLocationInput;
  where: OrderWhereUniqueInput;
};

export type OrderWhereInput = {
  AND?: Maybe<Array<OrderWhereInput>>;
  calls?: Maybe<CallListRelationFilter>;
  cart?: Maybe<CartRelationFilter>;
  cartId?: Maybe<IntFilter>;
  comment?: Maybe<StringFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  externalRef?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<LocationRelationFilter>;
  locationId?: Maybe<IntFilter>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  when?: Maybe<DateTimeFilter>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export enum PaymentType {
  Bank = "BANK",
  Cash = "CASH"
}

export type Product = {
  __typename?: "Product";
  category?: Maybe<ProductCategory>;
  categoryId?: Maybe<Scalars["Int"]>;
  disabled: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
  productPrices?: Maybe<Array<ProductPrice>>;
  stockAction: StockAction;
  stockActionId: Scalars["Int"];
  subname?: Maybe<Scalars["String"]>;
};

export type ProductProductPricesArgs = {
  cursor?: Maybe<ProductPriceWhereUniqueInput>;
  distinct?: Maybe<Array<ProductPriceScalarFieldEnum>>;
  orderBy?: Maybe<Array<ProductPriceOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<ProductPriceWhereInput>;
};

export type ProductCategory = {
  __typename?: "ProductCategory";
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
  products?: Maybe<Array<Product>>;
};

export type ProductCategoryProductsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<ProductWhereInput>;
};

export type ProductCategoryCreateInput = {
  name: Scalars["String"];
  order: Scalars["Int"];
  products?: Maybe<ProductCreateManyWithoutCategoryInput>;
};

export type ProductCategoryCreateOneWithoutProductsInput = {
  connect?: Maybe<ProductCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCategoryCreateOrConnectWithoutproductsInput>;
  create?: Maybe<ProductCategoryCreateWithoutProductsInput>;
};

export type ProductCategoryCreateOrConnectWithoutproductsInput = {
  create: ProductCategoryCreateWithoutProductsInput;
  where: ProductCategoryWhereUniqueInput;
};

export type ProductCategoryCreateWithoutProductsInput = {
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type ProductCategoryOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
};

export type ProductCategoryRelationFilter = {
  is?: Maybe<ProductCategoryWhereInput>;
  isNot?: Maybe<ProductCategoryWhereInput>;
};

export enum ProductCategoryScalarFieldEnum {
  Id = "id",
  Name = "name",
  Order = "order"
}

export type ProductCategoryUpdateInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryInput>;
};

export type ProductCategoryUpdateOneWithoutProductsInput = {
  connect?: Maybe<ProductCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCategoryCreateOrConnectWithoutproductsInput>;
  create?: Maybe<ProductCategoryCreateWithoutProductsInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<ProductCategoryUpdateWithoutProductsInput>;
  upsert?: Maybe<ProductCategoryUpsertWithoutProductsInput>;
};

export type ProductCategoryUpdateWithoutProductsInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProductCategoryUpsertWithoutProductsInput = {
  create: ProductCategoryCreateWithoutProductsInput;
  update: ProductCategoryUpdateWithoutProductsInput;
};

export type ProductCategoryWhereInput = {
  AND?: Maybe<Array<ProductCategoryWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ProductCategoryWhereInput>>;
  OR?: Maybe<Array<ProductCategoryWhereInput>>;
  order?: Maybe<IntFilter>;
  products?: Maybe<ProductListRelationFilter>;
};

export type ProductCategoryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type ProductCreateInput = {
  category?: Maybe<ProductCategoryCreateOneWithoutProductsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  productPrices?: Maybe<ProductPriceCreateManyWithoutProductInput>;
  stockAction: StockActionCreateOneWithoutProductsInput;
  subname?: Maybe<Scalars["String"]>;
};

export type ProductCreateManyWithoutCategoryInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutcategoryInput>>;
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
};

export type ProductCreateManyWithoutStockActionInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<ProductCreateWithoutStockActionInput>>;
};

export type ProductCreateOneWithoutProductPricesInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutproductPricesInput>;
  create?: Maybe<ProductCreateWithoutProductPricesInput>;
};

export type ProductCreateOrConnectWithoutcategoryInput = {
  create: ProductCreateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutproductPricesInput = {
  create: ProductCreateWithoutProductPricesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutstockActionInput = {
  create: ProductCreateWithoutStockActionInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutCategoryInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  productPrices?: Maybe<ProductPriceCreateManyWithoutProductInput>;
  stockAction: StockActionCreateOneWithoutProductsInput;
  subname?: Maybe<Scalars["String"]>;
};

export type ProductCreateWithoutProductPricesInput = {
  category?: Maybe<ProductCategoryCreateOneWithoutProductsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  stockAction: StockActionCreateOneWithoutProductsInput;
  subname?: Maybe<Scalars["String"]>;
};

export type ProductCreateWithoutStockActionInput = {
  category?: Maybe<ProductCategoryCreateOneWithoutProductsInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  productPrices?: Maybe<ProductPriceCreateManyWithoutProductInput>;
  subname?: Maybe<Scalars["String"]>;
};

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
};

export type ProductOrderByInput = {
  categoryId?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  stockActionId?: Maybe<SortOrder>;
  subname?: Maybe<SortOrder>;
};

export type ProductPrice = {
  __typename?: "ProductPrice";
  cartItems?: Maybe<Array<CartItem>>;
  disabled: Scalars["Boolean"];
  id: Scalars["Int"];
  locationType: LocationType;
  price: Scalars["Float"];
  product: Product;
  productId: Scalars["Int"];
};

export type ProductPriceCartItemsArgs = {
  cursor?: Maybe<CartItemWhereUniqueInput>;
  distinct?: Maybe<Array<CartItemScalarFieldEnum>>;
  orderBy?: Maybe<Array<CartItemOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CartItemWhereInput>;
};

export type ProductPriceCreateManyWithoutProductInput = {
  connect?: Maybe<Array<ProductPriceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductPriceCreateOrConnectWithoutproductInput>>;
  create?: Maybe<Array<ProductPriceCreateWithoutProductInput>>;
};

export type ProductPriceCreateOneWithoutCartItemsInput = {
  connect?: Maybe<ProductPriceWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductPriceCreateOrConnectWithoutcartItemsInput>;
  create?: Maybe<ProductPriceCreateWithoutCartItemsInput>;
};

export type ProductPriceCreateOrConnectWithoutcartItemsInput = {
  create: ProductPriceCreateWithoutCartItemsInput;
  where: ProductPriceWhereUniqueInput;
};

export type ProductPriceCreateOrConnectWithoutproductInput = {
  create: ProductPriceCreateWithoutProductInput;
  where: ProductPriceWhereUniqueInput;
};

export type ProductPriceCreateWithoutCartItemsInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  locationType: LocationType;
  price: Scalars["Float"];
  product: ProductCreateOneWithoutProductPricesInput;
};

export type ProductPriceCreateWithoutProductInput = {
  cartItems?: Maybe<CartItemCreateManyWithoutProductPriceInput>;
  disabled?: Maybe<Scalars["Boolean"]>;
  locationType: LocationType;
  price: Scalars["Float"];
};

export type ProductPriceListRelationFilter = {
  every?: Maybe<ProductPriceWhereInput>;
  none?: Maybe<ProductPriceWhereInput>;
  some?: Maybe<ProductPriceWhereInput>;
};

export type ProductPriceOrderByInput = {
  disabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationType?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type ProductPriceRelationFilter = {
  is?: Maybe<ProductPriceWhereInput>;
  isNot?: Maybe<ProductPriceWhereInput>;
};

export enum ProductPriceScalarFieldEnum {
  Disabled = "disabled",
  Id = "id",
  LocationType = "locationType",
  Price = "price",
  ProductId = "productId"
}

export type ProductPriceScalarWhereInput = {
  AND?: Maybe<Array<ProductPriceScalarWhereInput>>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  locationType?: Maybe<EnumLocationTypeFilter>;
  NOT?: Maybe<Array<ProductPriceScalarWhereInput>>;
  OR?: Maybe<Array<ProductPriceScalarWhereInput>>;
  price?: Maybe<FloatFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductPriceUpdateManyMutationInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type ProductPriceUpdateManyWithoutProductInput = {
  connect?: Maybe<Array<ProductPriceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductPriceCreateOrConnectWithoutproductInput>>;
  create?: Maybe<Array<ProductPriceCreateWithoutProductInput>>;
  delete?: Maybe<Array<ProductPriceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductPriceScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductPriceWhereUniqueInput>>;
  set?: Maybe<Array<ProductPriceWhereUniqueInput>>;
  update?: Maybe<Array<ProductPriceUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ProductPriceUpdateManyWithWhereWithoutProductInput>>;
  upsert?: Maybe<Array<ProductPriceUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductPriceUpdateManyWithWhereWithoutProductInput = {
  data: ProductPriceUpdateManyMutationInput;
  where: ProductPriceScalarWhereInput;
};

export type ProductPriceUpdateOneRequiredWithoutCartItemsInput = {
  connect?: Maybe<ProductPriceWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductPriceCreateOrConnectWithoutcartItemsInput>;
  create?: Maybe<ProductPriceCreateWithoutCartItemsInput>;
  update?: Maybe<ProductPriceUpdateWithoutCartItemsInput>;
  upsert?: Maybe<ProductPriceUpsertWithoutCartItemsInput>;
};

export type ProductPriceUpdateWithoutCartItemsInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneRequiredWithoutProductPricesInput>;
};

export type ProductPriceUpdateWithoutProductInput = {
  cartItems?: Maybe<CartItemUpdateManyWithoutProductPriceInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  locationType?: Maybe<EnumLocationTypeFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type ProductPriceUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductPriceUpdateWithoutProductInput;
  where: ProductPriceWhereUniqueInput;
};

export type ProductPriceUpsertWithoutCartItemsInput = {
  create: ProductPriceCreateWithoutCartItemsInput;
  update: ProductPriceUpdateWithoutCartItemsInput;
};

export type ProductPriceUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductPriceCreateWithoutProductInput;
  update: ProductPriceUpdateWithoutProductInput;
  where: ProductPriceWhereUniqueInput;
};

export type ProductPriceWhereInput = {
  AND?: Maybe<Array<ProductPriceWhereInput>>;
  cartItems?: Maybe<CartItemListRelationFilter>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  locationType?: Maybe<EnumLocationTypeFilter>;
  NOT?: Maybe<Array<ProductPriceWhereInput>>;
  OR?: Maybe<Array<ProductPriceWhereInput>>;
  price?: Maybe<FloatFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductPriceWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type ProductRelationFilter = {
  is?: Maybe<ProductWhereInput>;
  isNot?: Maybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  CategoryId = "categoryId",
  Disabled = "disabled",
  Id = "id",
  Name = "name",
  Order = "order",
  StockActionId = "stockActionId",
  Subname = "subname"
}

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  categoryId?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  order?: Maybe<IntFilter>;
  stockActionId?: Maybe<IntFilter>;
  subname?: Maybe<StringNullableFilter>;
};

export type ProductUpdateInput = {
  category?: Maybe<ProductCategoryUpdateOneWithoutProductsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  productPrices?: Maybe<ProductPriceUpdateManyWithoutProductInput>;
  stockAction?: Maybe<StockActionUpdateOneRequiredWithoutProductsInput>;
  subname?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  subname?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithoutCategoryInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutcategoryInput>>;
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type ProductUpdateManyWithoutStockActionInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<ProductCreateWithoutStockActionInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutStockActionInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutStockActionInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutStockActionInput>>;
};

export type ProductUpdateManyWithWhereWithoutCategoryInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutStockActionInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateOneRequiredWithoutProductPricesInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutproductPricesInput>;
  create?: Maybe<ProductCreateWithoutProductPricesInput>;
  update?: Maybe<ProductUpdateWithoutProductPricesInput>;
  upsert?: Maybe<ProductUpsertWithoutProductPricesInput>;
};

export type ProductUpdateWithoutCategoryInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  productPrices?: Maybe<ProductPriceUpdateManyWithoutProductInput>;
  stockAction?: Maybe<StockActionUpdateOneRequiredWithoutProductsInput>;
  subname?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductPricesInput = {
  category?: Maybe<ProductCategoryUpdateOneWithoutProductsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  stockAction?: Maybe<StockActionUpdateOneRequiredWithoutProductsInput>;
  subname?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutStockActionInput = {
  category?: Maybe<ProductCategoryUpdateOneWithoutProductsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  productPrices?: Maybe<ProductPriceUpdateManyWithoutProductInput>;
  subname?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutCategoryInput = {
  data: ProductUpdateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutStockActionInput = {
  data: ProductUpdateWithoutStockActionInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutProductPricesInput = {
  create: ProductCreateWithoutProductPricesInput;
  update: ProductUpdateWithoutProductPricesInput;
};

export type ProductUpsertWithWhereUniqueWithoutCategoryInput = {
  create: ProductCreateWithoutCategoryInput;
  update: ProductUpdateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutStockActionInput = {
  create: ProductCreateWithoutStockActionInput;
  update: ProductUpdateWithoutStockActionInput;
  where: ProductWhereUniqueInput;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  category?: Maybe<ProductCategoryRelationFilter>;
  categoryId?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  order?: Maybe<IntFilter>;
  productPrices?: Maybe<ProductPriceListRelationFilter>;
  stockAction?: Maybe<StockActionRelationFilter>;
  stockActionId?: Maybe<IntFilter>;
  subname?: Maybe<StringNullableFilter>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type Query = {
  __typename?: "Query";
  callSchedule?: Maybe<CallSchedule>;
  callSchedules: Array<CallSchedule>;
  firm?: Maybe<Firm>;
  firms: Array<Firm>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  me: User;
  order?: Maybe<Order>;
  orders: Array<Order>;
  ping: Scalars["String"];
  product?: Maybe<Product>;
  productCategories: Array<ProductCategory>;
  productCategory?: Maybe<ProductCategory>;
  products: Array<Product>;
  stock?: Maybe<Stock>;
  stockAction?: Maybe<StockAction>;
  stockActions: Array<StockAction>;
  stocks: Array<Stock>;
};

export type QueryCallScheduleArgs = {
  where: CallScheduleWhereUniqueInput;
};

export type QueryCallSchedulesArgs = {
  cursor?: Maybe<CallScheduleWhereUniqueInput>;
  distinct?: Maybe<Array<CallScheduleScalarFieldEnum>>;
  orderBy?: Maybe<Array<CallScheduleOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<CallScheduleWhereInput>;
};

export type QueryFirmArgs = {
  where: FirmWhereUniqueInput;
};

export type QueryFirmsArgs = {
  cursor?: Maybe<FirmWhereUniqueInput>;
  distinct?: Maybe<Array<FirmScalarFieldEnum>>;
  orderBy?: Maybe<Array<FirmOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<FirmWhereInput>;
};

export type QueryLocationArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryLocationsArgs = {
  cursor?: Maybe<LocationWhereUniqueInput>;
  distinct?: Maybe<Array<LocationScalarFieldEnum>>;
  orderBy?: Maybe<Array<LocationOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<LocationWhereInput>;
};

export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryOrdersArgs = {
  cursor?: Maybe<OrderWhereUniqueInput>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
  orderBy?: Maybe<Array<OrderOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<OrderWhereInput>;
};

export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};

export type QueryProductCategoriesArgs = {
  cursor?: Maybe<ProductCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<ProductCategoryScalarFieldEnum>>;
  orderBy?: Maybe<Array<ProductCategoryOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<ProductCategoryWhereInput>;
};

export type QueryProductCategoryArgs = {
  where: ProductCategoryWhereUniqueInput;
};

export type QueryProductsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<ProductWhereInput>;
};

export type QueryStockArgs = {
  where: StockWhereUniqueInput;
};

export type QueryStockActionArgs = {
  where: StockActionWhereUniqueInput;
};

export type QueryStockActionsArgs = {
  cursor?: Maybe<StockActionWhereUniqueInput>;
  distinct?: Maybe<Array<StockActionScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockActionOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockActionWhereInput>;
};

export type QueryStocksArgs = {
  cursor?: Maybe<StockWhereUniqueInput>;
  distinct?: Maybe<Array<StockScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockWhereInput>;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive"
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc"
}

export enum StickerColor {
  Green = "GREEN",
  None = "NONE",
  Red = "RED"
}

export type Stock = {
  __typename?: "Stock";
  disabled: Scalars["Boolean"];
  fallback?: Maybe<Stock>;
  id: Scalars["Int"];
  name: Scalars["String"];
  qty: Scalars["Int"];
  stockId?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Array<Stock>>;
  stockSubActions?: Maybe<Array<StockSubAction>>;
  stockSubChanges?: Maybe<Array<StockSubChange>>;
};

export type StockStocksArgs = {
  cursor?: Maybe<StockWhereUniqueInput>;
  distinct?: Maybe<Array<StockScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockWhereInput>;
};

export type StockStockSubActionsArgs = {
  cursor?: Maybe<StockSubActionWhereUniqueInput>;
  distinct?: Maybe<Array<StockSubActionScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockSubActionOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockSubActionWhereInput>;
};

export type StockStockSubChangesArgs = {
  cursor?: Maybe<StockSubChangeWhereUniqueInput>;
  distinct?: Maybe<Array<StockSubChangeScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockSubChangeOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockSubChangeWhereInput>;
};

export type StockAction = {
  __typename?: "StockAction";
  disabled: Scalars["Boolean"];
  hidden: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
  presetValues?: Maybe<Array<Scalars["Int"]>>;
  products?: Maybe<Array<Product>>;
  stockChanges?: Maybe<Array<StockChange>>;
  subActions?: Maybe<Array<StockSubAction>>;
};

export type StockActionProductsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<ProductWhereInput>;
};

export type StockActionStockChangesArgs = {
  cursor?: Maybe<StockChangeWhereUniqueInput>;
  distinct?: Maybe<Array<StockChangeScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockChangeOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockChangeWhereInput>;
};

export type StockActionSubActionsArgs = {
  cursor?: Maybe<StockSubActionWhereUniqueInput>;
  distinct?: Maybe<Array<StockSubActionScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockSubActionOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockSubActionWhereInput>;
};

export type StockActionApply = {
  id: Scalars["Float"];
  value: Scalars["Float"];
};

export type StockActionCreateInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  presetValues?: Maybe<StockActionCreatepresetValuesInput>;
  products?: Maybe<ProductCreateManyWithoutStockActionInput>;
  stockChanges?: Maybe<StockChangeCreateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionCreateManyWithoutStockActionInput>;
};

export type StockActionCreateOneWithoutProductsInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutproductsInput>;
  create?: Maybe<StockActionCreateWithoutProductsInput>;
};

export type StockActionCreateOneWithoutStockChangesInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutstockChangesInput>;
  create?: Maybe<StockActionCreateWithoutStockChangesInput>;
};

export type StockActionCreateOneWithoutSubActionsInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutsubActionsInput>;
  create?: Maybe<StockActionCreateWithoutSubActionsInput>;
};

export type StockActionCreateOrConnectWithoutproductsInput = {
  create: StockActionCreateWithoutProductsInput;
  where: StockActionWhereUniqueInput;
};

export type StockActionCreateOrConnectWithoutstockChangesInput = {
  create: StockActionCreateWithoutStockChangesInput;
  where: StockActionWhereUniqueInput;
};

export type StockActionCreateOrConnectWithoutsubActionsInput = {
  create: StockActionCreateWithoutSubActionsInput;
  where: StockActionWhereUniqueInput;
};

export type StockActionCreatepresetValuesInput = {
  set: Array<Scalars["Int"]>;
};

export type StockActionCreateWithoutProductsInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  presetValues?: Maybe<StockActionCreatepresetValuesInput>;
  stockChanges?: Maybe<StockChangeCreateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionCreateManyWithoutStockActionInput>;
};

export type StockActionCreateWithoutStockChangesInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  presetValues?: Maybe<StockActionCreatepresetValuesInput>;
  products?: Maybe<ProductCreateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionCreateManyWithoutStockActionInput>;
};

export type StockActionCreateWithoutSubActionsInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  presetValues?: Maybe<StockActionCreatepresetValuesInput>;
  products?: Maybe<ProductCreateManyWithoutStockActionInput>;
  stockChanges?: Maybe<StockChangeCreateManyWithoutStockActionInput>;
};

export type StockActionOrderByInput = {
  disabled?: Maybe<SortOrder>;
  hidden?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  presetValues?: Maybe<SortOrder>;
};

export type StockActionRelationFilter = {
  is?: Maybe<StockActionWhereInput>;
  isNot?: Maybe<StockActionWhereInput>;
};

export enum StockActionScalarFieldEnum {
  Disabled = "disabled",
  Hidden = "hidden",
  Id = "id",
  Name = "name",
  PresetValues = "presetValues"
}

export type StockActionUpdateInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  hidden?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  presetValues?: Maybe<StockActionUpdatepresetValuesInput>;
  products?: Maybe<ProductUpdateManyWithoutStockActionInput>;
  stockChanges?: Maybe<StockChangeUpdateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionUpdateManyWithoutStockActionInput>;
};

export type StockActionUpdateOneRequiredWithoutProductsInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutproductsInput>;
  create?: Maybe<StockActionCreateWithoutProductsInput>;
  update?: Maybe<StockActionUpdateWithoutProductsInput>;
  upsert?: Maybe<StockActionUpsertWithoutProductsInput>;
};

export type StockActionUpdateOneRequiredWithoutSubActionsInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutsubActionsInput>;
  create?: Maybe<StockActionCreateWithoutSubActionsInput>;
  update?: Maybe<StockActionUpdateWithoutSubActionsInput>;
  upsert?: Maybe<StockActionUpsertWithoutSubActionsInput>;
};

export type StockActionUpdateOneWithoutStockChangesInput = {
  connect?: Maybe<StockActionWhereUniqueInput>;
  connectOrCreate?: Maybe<StockActionCreateOrConnectWithoutstockChangesInput>;
  create?: Maybe<StockActionCreateWithoutStockChangesInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<StockActionUpdateWithoutStockChangesInput>;
  upsert?: Maybe<StockActionUpsertWithoutStockChangesInput>;
};

export type StockActionUpdatepresetValuesInput = {
  set: Array<Scalars["Int"]>;
};

export type StockActionUpdateWithoutProductsInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  hidden?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  presetValues?: Maybe<StockActionUpdatepresetValuesInput>;
  stockChanges?: Maybe<StockChangeUpdateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionUpdateManyWithoutStockActionInput>;
};

export type StockActionUpdateWithoutStockChangesInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  hidden?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  presetValues?: Maybe<StockActionUpdatepresetValuesInput>;
  products?: Maybe<ProductUpdateManyWithoutStockActionInput>;
  subActions?: Maybe<StockSubActionUpdateManyWithoutStockActionInput>;
};

export type StockActionUpdateWithoutSubActionsInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  hidden?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  presetValues?: Maybe<StockActionUpdatepresetValuesInput>;
  products?: Maybe<ProductUpdateManyWithoutStockActionInput>;
  stockChanges?: Maybe<StockChangeUpdateManyWithoutStockActionInput>;
};

export type StockActionUpsertWithoutProductsInput = {
  create: StockActionCreateWithoutProductsInput;
  update: StockActionUpdateWithoutProductsInput;
};

export type StockActionUpsertWithoutStockChangesInput = {
  create: StockActionCreateWithoutStockChangesInput;
  update: StockActionUpdateWithoutStockChangesInput;
};

export type StockActionUpsertWithoutSubActionsInput = {
  create: StockActionCreateWithoutSubActionsInput;
  update: StockActionUpdateWithoutSubActionsInput;
};

export type StockActionWhereInput = {
  AND?: Maybe<Array<StockActionWhereInput>>;
  disabled?: Maybe<BoolFilter>;
  hidden?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<StockActionWhereInput>>;
  OR?: Maybe<Array<StockActionWhereInput>>;
  presetValues?: Maybe<IntNullableListFilter>;
  products?: Maybe<ProductListRelationFilter>;
  stockChanges?: Maybe<StockChangeListRelationFilter>;
  subActions?: Maybe<StockSubActionListRelationFilter>;
};

export type StockActionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StockChange = {
  __typename?: "StockChange";
  by: User;
  comment: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  stockAction?: Maybe<StockAction>;
  stockActionId?: Maybe<Scalars["Int"]>;
  stockSubChanges?: Maybe<Array<StockSubChange>>;
  userId: Scalars["Int"];
  value?: Maybe<Scalars["Int"]>;
};

export type StockChangeStockSubChangesArgs = {
  cursor?: Maybe<StockSubChangeWhereUniqueInput>;
  distinct?: Maybe<Array<StockSubChangeScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockSubChangeOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockSubChangeWhereInput>;
};

export type StockChangeCreateManyWithoutByInput = {
  connect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockChangeCreateOrConnectWithoutbyInput>>;
  create?: Maybe<Array<StockChangeCreateWithoutByInput>>;
};

export type StockChangeCreateManyWithoutStockActionInput = {
  connect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockChangeCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<StockChangeCreateWithoutStockActionInput>>;
};

export type StockChangeCreateOneWithoutStockSubChangesInput = {
  connect?: Maybe<StockChangeWhereUniqueInput>;
  connectOrCreate?: Maybe<StockChangeCreateOrConnectWithoutstockSubChangesInput>;
  create?: Maybe<StockChangeCreateWithoutStockSubChangesInput>;
};

export type StockChangeCreateOrConnectWithoutbyInput = {
  create: StockChangeCreateWithoutByInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeCreateOrConnectWithoutstockActionInput = {
  create: StockChangeCreateWithoutStockActionInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeCreateOrConnectWithoutstockSubChangesInput = {
  create: StockChangeCreateWithoutStockSubChangesInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeCreateWithoutByInput = {
  comment?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  stockAction?: Maybe<StockActionCreateOneWithoutStockChangesInput>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockChangeInput>;
  value?: Maybe<Scalars["Int"]>;
};

export type StockChangeCreateWithoutStockActionInput = {
  by: UserCreateOneWithoutStockChangesInput;
  comment?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockChangeInput>;
  value?: Maybe<Scalars["Int"]>;
};

export type StockChangeCreateWithoutStockSubChangesInput = {
  by: UserCreateOneWithoutStockChangesInput;
  comment?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  stockAction?: Maybe<StockActionCreateOneWithoutStockChangesInput>;
  value?: Maybe<Scalars["Int"]>;
};

export type StockChangeListRelationFilter = {
  every?: Maybe<StockChangeWhereInput>;
  none?: Maybe<StockChangeWhereInput>;
  some?: Maybe<StockChangeWhereInput>;
};

export type StockChangeOrderByInput = {
  comment?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stockActionId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type StockChangeRelationFilter = {
  is?: Maybe<StockChangeWhereInput>;
  isNot?: Maybe<StockChangeWhereInput>;
};

export enum StockChangeScalarFieldEnum {
  Comment = "comment",
  CreatedAt = "createdAt",
  Id = "id",
  StockActionId = "stockActionId",
  UserId = "userId",
  Value = "value"
}

export type StockChangeScalarWhereInput = {
  AND?: Maybe<Array<StockChangeScalarWhereInput>>;
  comment?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockChangeScalarWhereInput>>;
  OR?: Maybe<Array<StockChangeScalarWhereInput>>;
  stockActionId?: Maybe<IntNullableFilter>;
  userId?: Maybe<IntFilter>;
  value?: Maybe<IntNullableFilter>;
};

export type StockChangeUpdateManyMutationInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type StockChangeUpdateManyWithoutByInput = {
  connect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockChangeCreateOrConnectWithoutbyInput>>;
  create?: Maybe<Array<StockChangeCreateWithoutByInput>>;
  delete?: Maybe<Array<StockChangeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockChangeScalarWhereInput>>;
  disconnect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  set?: Maybe<Array<StockChangeWhereUniqueInput>>;
  update?: Maybe<Array<StockChangeUpdateWithWhereUniqueWithoutByInput>>;
  updateMany?: Maybe<Array<StockChangeUpdateManyWithWhereWithoutByInput>>;
  upsert?: Maybe<Array<StockChangeUpsertWithWhereUniqueWithoutByInput>>;
};

export type StockChangeUpdateManyWithoutStockActionInput = {
  connect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockChangeCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<StockChangeCreateWithoutStockActionInput>>;
  delete?: Maybe<Array<StockChangeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockChangeScalarWhereInput>>;
  disconnect?: Maybe<Array<StockChangeWhereUniqueInput>>;
  set?: Maybe<Array<StockChangeWhereUniqueInput>>;
  update?: Maybe<Array<StockChangeUpdateWithWhereUniqueWithoutStockActionInput>>;
  updateMany?: Maybe<Array<StockChangeUpdateManyWithWhereWithoutStockActionInput>>;
  upsert?: Maybe<Array<StockChangeUpsertWithWhereUniqueWithoutStockActionInput>>;
};

export type StockChangeUpdateManyWithWhereWithoutByInput = {
  data: StockChangeUpdateManyMutationInput;
  where: StockChangeScalarWhereInput;
};

export type StockChangeUpdateManyWithWhereWithoutStockActionInput = {
  data: StockChangeUpdateManyMutationInput;
  where: StockChangeScalarWhereInput;
};

export type StockChangeUpdateOneRequiredWithoutStockSubChangesInput = {
  connect?: Maybe<StockChangeWhereUniqueInput>;
  connectOrCreate?: Maybe<StockChangeCreateOrConnectWithoutstockSubChangesInput>;
  create?: Maybe<StockChangeCreateWithoutStockSubChangesInput>;
  update?: Maybe<StockChangeUpdateWithoutStockSubChangesInput>;
  upsert?: Maybe<StockChangeUpsertWithoutStockSubChangesInput>;
};

export type StockChangeUpdateWithoutByInput = {
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stockAction?: Maybe<StockActionUpdateOneWithoutStockChangesInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockChangeInput>;
  value?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type StockChangeUpdateWithoutStockActionInput = {
  by?: Maybe<UserUpdateOneRequiredWithoutStockChangesInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockChangeInput>;
  value?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type StockChangeUpdateWithoutStockSubChangesInput = {
  by?: Maybe<UserUpdateOneRequiredWithoutStockChangesInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stockAction?: Maybe<StockActionUpdateOneWithoutStockChangesInput>;
  value?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type StockChangeUpdateWithWhereUniqueWithoutByInput = {
  data: StockChangeUpdateWithoutByInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeUpdateWithWhereUniqueWithoutStockActionInput = {
  data: StockChangeUpdateWithoutStockActionInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeUpsertWithoutStockSubChangesInput = {
  create: StockChangeCreateWithoutStockSubChangesInput;
  update: StockChangeUpdateWithoutStockSubChangesInput;
};

export type StockChangeUpsertWithWhereUniqueWithoutByInput = {
  create: StockChangeCreateWithoutByInput;
  update: StockChangeUpdateWithoutByInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeUpsertWithWhereUniqueWithoutStockActionInput = {
  create: StockChangeCreateWithoutStockActionInput;
  update: StockChangeUpdateWithoutStockActionInput;
  where: StockChangeWhereUniqueInput;
};

export type StockChangeWhereInput = {
  AND?: Maybe<Array<StockChangeWhereInput>>;
  by?: Maybe<UserRelationFilter>;
  comment?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockChangeWhereInput>>;
  OR?: Maybe<Array<StockChangeWhereInput>>;
  stockAction?: Maybe<StockActionRelationFilter>;
  stockActionId?: Maybe<IntNullableFilter>;
  stockSubChanges?: Maybe<StockSubChangeListRelationFilter>;
  userId?: Maybe<IntFilter>;
  value?: Maybe<IntNullableFilter>;
};

export type StockChangeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StockCreateInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  fallback?: Maybe<StockCreateOneWithoutStocksInput>;
  name: Scalars["String"];
  qty?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<StockCreateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionCreateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockInput>;
};

export type StockCreateManyWithoutFallbackInput = {
  connect?: Maybe<Array<StockWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockCreateOrConnectWithoutfallbackInput>>;
  create?: Maybe<Array<StockCreateWithoutFallbackInput>>;
};

export type StockCreateOneWithoutStocksInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstocksInput>;
  create?: Maybe<StockCreateWithoutStocksInput>;
};

export type StockCreateOneWithoutStockSubActionsInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstockSubActionsInput>;
  create?: Maybe<StockCreateWithoutStockSubActionsInput>;
};

export type StockCreateOneWithoutStockSubChangesInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstockSubChangesInput>;
  create?: Maybe<StockCreateWithoutStockSubChangesInput>;
};

export type StockCreateOrConnectWithoutfallbackInput = {
  create: StockCreateWithoutFallbackInput;
  where: StockWhereUniqueInput;
};

export type StockCreateOrConnectWithoutstocksInput = {
  create: StockCreateWithoutStocksInput;
  where: StockWhereUniqueInput;
};

export type StockCreateOrConnectWithoutstockSubActionsInput = {
  create: StockCreateWithoutStockSubActionsInput;
  where: StockWhereUniqueInput;
};

export type StockCreateOrConnectWithoutstockSubChangesInput = {
  create: StockCreateWithoutStockSubChangesInput;
  where: StockWhereUniqueInput;
};

export type StockCreateWithoutFallbackInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  qty?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<StockCreateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionCreateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockInput>;
};

export type StockCreateWithoutStocksInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  fallback?: Maybe<StockCreateOneWithoutStocksInput>;
  name: Scalars["String"];
  qty?: Maybe<Scalars["Int"]>;
  stockSubActions?: Maybe<StockSubActionCreateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockInput>;
};

export type StockCreateWithoutStockSubActionsInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  fallback?: Maybe<StockCreateOneWithoutStocksInput>;
  name: Scalars["String"];
  qty?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<StockCreateManyWithoutFallbackInput>;
  stockSubChanges?: Maybe<StockSubChangeCreateManyWithoutStockInput>;
};

export type StockCreateWithoutStockSubChangesInput = {
  disabled?: Maybe<Scalars["Boolean"]>;
  fallback?: Maybe<StockCreateOneWithoutStocksInput>;
  name: Scalars["String"];
  qty?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<StockCreateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionCreateManyWithoutStockInput>;
};

export type StockListRelationFilter = {
  every?: Maybe<StockWhereInput>;
  none?: Maybe<StockWhereInput>;
  some?: Maybe<StockWhereInput>;
};

export type StockOrderByInput = {
  disabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  qty?: Maybe<SortOrder>;
  stockId?: Maybe<SortOrder>;
};

export type StockRelationFilter = {
  is?: Maybe<StockWhereInput>;
  isNot?: Maybe<StockWhereInput>;
};

export enum StockScalarFieldEnum {
  Disabled = "disabled",
  Id = "id",
  Name = "name",
  Qty = "qty",
  StockId = "stockId"
}

export type StockScalarWhereInput = {
  AND?: Maybe<Array<StockScalarWhereInput>>;
  disabled?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<StockScalarWhereInput>>;
  OR?: Maybe<Array<StockScalarWhereInput>>;
  qty?: Maybe<IntFilter>;
  stockId?: Maybe<IntNullableFilter>;
};

export type StockSubAction = {
  __typename?: "StockSubAction";
  delta: Scalars["Int"];
  id: Scalars["Int"];
  stock: Stock;
  stockAction: StockAction;
  stockActionId: Scalars["Int"];
  stockId: Scalars["Int"];
};

export type StockSubActionCreateManyWithoutStockActionInput = {
  connect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubActionCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<StockSubActionCreateWithoutStockActionInput>>;
};

export type StockSubActionCreateManyWithoutStockInput = {
  connect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubActionCreateOrConnectWithoutstockInput>>;
  create?: Maybe<Array<StockSubActionCreateWithoutStockInput>>;
};

export type StockSubActionCreateOrConnectWithoutstockActionInput = {
  create: StockSubActionCreateWithoutStockActionInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionCreateOrConnectWithoutstockInput = {
  create: StockSubActionCreateWithoutStockInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionCreateWithoutStockActionInput = {
  delta: Scalars["Int"];
  stock: StockCreateOneWithoutStockSubActionsInput;
};

export type StockSubActionCreateWithoutStockInput = {
  delta: Scalars["Int"];
  stockAction: StockActionCreateOneWithoutSubActionsInput;
};

export type StockSubActionListRelationFilter = {
  every?: Maybe<StockSubActionWhereInput>;
  none?: Maybe<StockSubActionWhereInput>;
  some?: Maybe<StockSubActionWhereInput>;
};

export type StockSubActionOrderByInput = {
  delta?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stockActionId?: Maybe<SortOrder>;
  stockId?: Maybe<SortOrder>;
};

export enum StockSubActionScalarFieldEnum {
  Delta = "delta",
  Id = "id",
  StockActionId = "stockActionId",
  StockId = "stockId"
}

export type StockSubActionScalarWhereInput = {
  AND?: Maybe<Array<StockSubActionScalarWhereInput>>;
  delta?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockSubActionScalarWhereInput>>;
  OR?: Maybe<Array<StockSubActionScalarWhereInput>>;
  stockActionId?: Maybe<IntFilter>;
  stockId?: Maybe<IntFilter>;
};

export type StockSubActionUpdateManyMutationInput = {
  delta?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StockSubActionUpdateManyWithoutStockActionInput = {
  connect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubActionCreateOrConnectWithoutstockActionInput>>;
  create?: Maybe<Array<StockSubActionCreateWithoutStockActionInput>>;
  delete?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockSubActionScalarWhereInput>>;
  disconnect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  set?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  update?: Maybe<Array<StockSubActionUpdateWithWhereUniqueWithoutStockActionInput>>;
  updateMany?: Maybe<Array<StockSubActionUpdateManyWithWhereWithoutStockActionInput>>;
  upsert?: Maybe<Array<StockSubActionUpsertWithWhereUniqueWithoutStockActionInput>>;
};

export type StockSubActionUpdateManyWithoutStockInput = {
  connect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubActionCreateOrConnectWithoutstockInput>>;
  create?: Maybe<Array<StockSubActionCreateWithoutStockInput>>;
  delete?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockSubActionScalarWhereInput>>;
  disconnect?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  set?: Maybe<Array<StockSubActionWhereUniqueInput>>;
  update?: Maybe<Array<StockSubActionUpdateWithWhereUniqueWithoutStockInput>>;
  updateMany?: Maybe<Array<StockSubActionUpdateManyWithWhereWithoutStockInput>>;
  upsert?: Maybe<Array<StockSubActionUpsertWithWhereUniqueWithoutStockInput>>;
};

export type StockSubActionUpdateManyWithWhereWithoutStockActionInput = {
  data: StockSubActionUpdateManyMutationInput;
  where: StockSubActionScalarWhereInput;
};

export type StockSubActionUpdateManyWithWhereWithoutStockInput = {
  data: StockSubActionUpdateManyMutationInput;
  where: StockSubActionScalarWhereInput;
};

export type StockSubActionUpdateWithoutStockActionInput = {
  delta?: Maybe<IntFieldUpdateOperationsInput>;
  stock?: Maybe<StockUpdateOneRequiredWithoutStockSubActionsInput>;
};

export type StockSubActionUpdateWithoutStockInput = {
  delta?: Maybe<IntFieldUpdateOperationsInput>;
  stockAction?: Maybe<StockActionUpdateOneRequiredWithoutSubActionsInput>;
};

export type StockSubActionUpdateWithWhereUniqueWithoutStockActionInput = {
  data: StockSubActionUpdateWithoutStockActionInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionUpdateWithWhereUniqueWithoutStockInput = {
  data: StockSubActionUpdateWithoutStockInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionUpsertWithWhereUniqueWithoutStockActionInput = {
  create: StockSubActionCreateWithoutStockActionInput;
  update: StockSubActionUpdateWithoutStockActionInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionUpsertWithWhereUniqueWithoutStockInput = {
  create: StockSubActionCreateWithoutStockInput;
  update: StockSubActionUpdateWithoutStockInput;
  where: StockSubActionWhereUniqueInput;
};

export type StockSubActionWhereInput = {
  AND?: Maybe<Array<StockSubActionWhereInput>>;
  delta?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockSubActionWhereInput>>;
  OR?: Maybe<Array<StockSubActionWhereInput>>;
  stock?: Maybe<StockRelationFilter>;
  stockAction?: Maybe<StockActionRelationFilter>;
  stockActionId?: Maybe<IntFilter>;
  stockId?: Maybe<IntFilter>;
};

export type StockSubActionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StockSubChange = {
  __typename?: "StockSubChange";
  changeType: ChangeType;
  id: Scalars["Int"];
  stock: Stock;
  stockChange: StockChange;
  stockChangeId: Scalars["Int"];
  stockId: Scalars["Int"];
  value: Scalars["Int"];
};

export type StockSubChangeCreateManyWithoutStockChangeInput = {
  connect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubChangeCreateOrConnectWithoutstockChangeInput>>;
  create?: Maybe<Array<StockSubChangeCreateWithoutStockChangeInput>>;
};

export type StockSubChangeCreateManyWithoutStockInput = {
  connect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubChangeCreateOrConnectWithoutstockInput>>;
  create?: Maybe<Array<StockSubChangeCreateWithoutStockInput>>;
};

export type StockSubChangeCreateOrConnectWithoutstockChangeInput = {
  create: StockSubChangeCreateWithoutStockChangeInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeCreateOrConnectWithoutstockInput = {
  create: StockSubChangeCreateWithoutStockInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeCreateWithoutStockChangeInput = {
  changeType: ChangeType;
  stock: StockCreateOneWithoutStockSubChangesInput;
  value: Scalars["Int"];
};

export type StockSubChangeCreateWithoutStockInput = {
  changeType: ChangeType;
  stockChange: StockChangeCreateOneWithoutStockSubChangesInput;
  value: Scalars["Int"];
};

export type StockSubChangeListRelationFilter = {
  every?: Maybe<StockSubChangeWhereInput>;
  none?: Maybe<StockSubChangeWhereInput>;
  some?: Maybe<StockSubChangeWhereInput>;
};

export type StockSubChangeOrderByInput = {
  changeType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stockChangeId?: Maybe<SortOrder>;
  stockId?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export enum StockSubChangeScalarFieldEnum {
  ChangeType = "changeType",
  Id = "id",
  StockChangeId = "stockChangeId",
  StockId = "stockId",
  Value = "value"
}

export type StockSubChangeScalarWhereInput = {
  AND?: Maybe<Array<StockSubChangeScalarWhereInput>>;
  changeType?: Maybe<EnumChangeTypeFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockSubChangeScalarWhereInput>>;
  OR?: Maybe<Array<StockSubChangeScalarWhereInput>>;
  stockChangeId?: Maybe<IntFilter>;
  stockId?: Maybe<IntFilter>;
  value?: Maybe<IntFilter>;
};

export type StockSubChangeUpdateManyMutationInput = {
  changeType?: Maybe<EnumChangeTypeFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StockSubChangeUpdateManyWithoutStockChangeInput = {
  connect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubChangeCreateOrConnectWithoutstockChangeInput>>;
  create?: Maybe<Array<StockSubChangeCreateWithoutStockChangeInput>>;
  delete?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockSubChangeScalarWhereInput>>;
  disconnect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  set?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  update?: Maybe<Array<StockSubChangeUpdateWithWhereUniqueWithoutStockChangeInput>>;
  updateMany?: Maybe<Array<StockSubChangeUpdateManyWithWhereWithoutStockChangeInput>>;
  upsert?: Maybe<Array<StockSubChangeUpsertWithWhereUniqueWithoutStockChangeInput>>;
};

export type StockSubChangeUpdateManyWithoutStockInput = {
  connect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockSubChangeCreateOrConnectWithoutstockInput>>;
  create?: Maybe<Array<StockSubChangeCreateWithoutStockInput>>;
  delete?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockSubChangeScalarWhereInput>>;
  disconnect?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  set?: Maybe<Array<StockSubChangeWhereUniqueInput>>;
  update?: Maybe<Array<StockSubChangeUpdateWithWhereUniqueWithoutStockInput>>;
  updateMany?: Maybe<Array<StockSubChangeUpdateManyWithWhereWithoutStockInput>>;
  upsert?: Maybe<Array<StockSubChangeUpsertWithWhereUniqueWithoutStockInput>>;
};

export type StockSubChangeUpdateManyWithWhereWithoutStockChangeInput = {
  data: StockSubChangeUpdateManyMutationInput;
  where: StockSubChangeScalarWhereInput;
};

export type StockSubChangeUpdateManyWithWhereWithoutStockInput = {
  data: StockSubChangeUpdateManyMutationInput;
  where: StockSubChangeScalarWhereInput;
};

export type StockSubChangeUpdateWithoutStockChangeInput = {
  changeType?: Maybe<EnumChangeTypeFieldUpdateOperationsInput>;
  stock?: Maybe<StockUpdateOneRequiredWithoutStockSubChangesInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StockSubChangeUpdateWithoutStockInput = {
  changeType?: Maybe<EnumChangeTypeFieldUpdateOperationsInput>;
  stockChange?: Maybe<StockChangeUpdateOneRequiredWithoutStockSubChangesInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StockSubChangeUpdateWithWhereUniqueWithoutStockChangeInput = {
  data: StockSubChangeUpdateWithoutStockChangeInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeUpdateWithWhereUniqueWithoutStockInput = {
  data: StockSubChangeUpdateWithoutStockInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeUpsertWithWhereUniqueWithoutStockChangeInput = {
  create: StockSubChangeCreateWithoutStockChangeInput;
  update: StockSubChangeUpdateWithoutStockChangeInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeUpsertWithWhereUniqueWithoutStockInput = {
  create: StockSubChangeCreateWithoutStockInput;
  update: StockSubChangeUpdateWithoutStockInput;
  where: StockSubChangeWhereUniqueInput;
};

export type StockSubChangeWhereInput = {
  AND?: Maybe<Array<StockSubChangeWhereInput>>;
  changeType?: Maybe<EnumChangeTypeFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<StockSubChangeWhereInput>>;
  OR?: Maybe<Array<StockSubChangeWhereInput>>;
  stock?: Maybe<StockRelationFilter>;
  stockChange?: Maybe<StockChangeRelationFilter>;
  stockChangeId?: Maybe<IntFilter>;
  stockId?: Maybe<IntFilter>;
  value?: Maybe<IntFilter>;
};

export type StockSubChangeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StockUpdateInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fallback?: Maybe<StockUpdateOneWithoutStocksInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
  stocks?: Maybe<StockUpdateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionUpdateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockInput>;
};

export type StockUpdateManyMutationInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
};

export type StockUpdateManyWithoutFallbackInput = {
  connect?: Maybe<Array<StockWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StockCreateOrConnectWithoutfallbackInput>>;
  create?: Maybe<Array<StockCreateWithoutFallbackInput>>;
  delete?: Maybe<Array<StockWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StockScalarWhereInput>>;
  disconnect?: Maybe<Array<StockWhereUniqueInput>>;
  set?: Maybe<Array<StockWhereUniqueInput>>;
  update?: Maybe<Array<StockUpdateWithWhereUniqueWithoutFallbackInput>>;
  updateMany?: Maybe<Array<StockUpdateManyWithWhereWithoutFallbackInput>>;
  upsert?: Maybe<Array<StockUpsertWithWhereUniqueWithoutFallbackInput>>;
};

export type StockUpdateManyWithWhereWithoutFallbackInput = {
  data: StockUpdateManyMutationInput;
  where: StockScalarWhereInput;
};

export type StockUpdateOneRequiredWithoutStockSubActionsInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstockSubActionsInput>;
  create?: Maybe<StockCreateWithoutStockSubActionsInput>;
  update?: Maybe<StockUpdateWithoutStockSubActionsInput>;
  upsert?: Maybe<StockUpsertWithoutStockSubActionsInput>;
};

export type StockUpdateOneRequiredWithoutStockSubChangesInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstockSubChangesInput>;
  create?: Maybe<StockCreateWithoutStockSubChangesInput>;
  update?: Maybe<StockUpdateWithoutStockSubChangesInput>;
  upsert?: Maybe<StockUpsertWithoutStockSubChangesInput>;
};

export type StockUpdateOneWithoutStocksInput = {
  connect?: Maybe<StockWhereUniqueInput>;
  connectOrCreate?: Maybe<StockCreateOrConnectWithoutstocksInput>;
  create?: Maybe<StockCreateWithoutStocksInput>;
  delete?: Maybe<Scalars["Boolean"]>;
  disconnect?: Maybe<Scalars["Boolean"]>;
  update?: Maybe<StockUpdateWithoutStocksInput>;
  upsert?: Maybe<StockUpsertWithoutStocksInput>;
};

export type StockUpdateWithoutFallbackInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
  stocks?: Maybe<StockUpdateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionUpdateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockInput>;
};

export type StockUpdateWithoutStocksInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fallback?: Maybe<StockUpdateOneWithoutStocksInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
  stockSubActions?: Maybe<StockSubActionUpdateManyWithoutStockInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockInput>;
};

export type StockUpdateWithoutStockSubActionsInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fallback?: Maybe<StockUpdateOneWithoutStocksInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
  stocks?: Maybe<StockUpdateManyWithoutFallbackInput>;
  stockSubChanges?: Maybe<StockSubChangeUpdateManyWithoutStockInput>;
};

export type StockUpdateWithoutStockSubChangesInput = {
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fallback?: Maybe<StockUpdateOneWithoutStocksInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  qty?: Maybe<IntFieldUpdateOperationsInput>;
  stocks?: Maybe<StockUpdateManyWithoutFallbackInput>;
  stockSubActions?: Maybe<StockSubActionUpdateManyWithoutStockInput>;
};

export type StockUpdateWithWhereUniqueWithoutFallbackInput = {
  data: StockUpdateWithoutFallbackInput;
  where: StockWhereUniqueInput;
};

export type StockUpsertWithoutStocksInput = {
  create: StockCreateWithoutStocksInput;
  update: StockUpdateWithoutStocksInput;
};

export type StockUpsertWithoutStockSubActionsInput = {
  create: StockCreateWithoutStockSubActionsInput;
  update: StockUpdateWithoutStockSubActionsInput;
};

export type StockUpsertWithoutStockSubChangesInput = {
  create: StockCreateWithoutStockSubChangesInput;
  update: StockUpdateWithoutStockSubChangesInput;
};

export type StockUpsertWithWhereUniqueWithoutFallbackInput = {
  create: StockCreateWithoutFallbackInput;
  update: StockUpdateWithoutFallbackInput;
  where: StockWhereUniqueInput;
};

export type StockWhereInput = {
  AND?: Maybe<Array<StockWhereInput>>;
  disabled?: Maybe<BoolFilter>;
  fallback?: Maybe<StockRelationFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<StockWhereInput>>;
  OR?: Maybe<Array<StockWhereInput>>;
  qty?: Maybe<IntFilter>;
  stockId?: Maybe<IntNullableFilter>;
  stocks?: Maybe<StockListRelationFilter>;
  stockSubActions?: Maybe<StockSubActionListRelationFilter>;
  stockSubChanges?: Maybe<StockSubChangeListRelationFilter>;
};

export type StockWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export type StringFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  equals?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars["String"]>>;
};

export type User = {
  __typename?: "User";
  customer?: Maybe<Customer>;
  email: Scalars["String"];
  employee?: Maybe<Employee>;
  id: Scalars["Int"];
  notifications?: Maybe<Array<Notification>>;
  passwordHash?: Maybe<Scalars["String"]>;
  stockChanges?: Maybe<Array<StockChange>>;
};

export type UserNotificationsArgs = {
  cursor?: Maybe<NotificationWhereUniqueInput>;
  distinct?: Maybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: Maybe<Array<NotificationOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<NotificationWhereInput>;
};

export type UserStockChangesArgs = {
  cursor?: Maybe<StockChangeWhereUniqueInput>;
  distinct?: Maybe<Array<StockChangeScalarFieldEnum>>;
  orderBy?: Maybe<Array<StockChangeOrderByInput>>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  where?: Maybe<StockChangeWhereInput>;
};

export type UserAuth = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type UserCreateOneWithoutCustomerInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutcustomerInput>;
  create?: Maybe<UserCreateWithoutCustomerInput>;
};

export type UserCreateOneWithoutStockChangesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutstockChangesInput>;
  create?: Maybe<UserCreateWithoutStockChangesInput>;
};

export type UserCreateOrConnectWithoutcustomerInput = {
  create: UserCreateWithoutCustomerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutstockChangesInput = {
  create: UserCreateWithoutStockChangesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCustomerInput = {
  email: Scalars["String"];
  employee?: Maybe<EmployeeCreateOneWithoutUserInput>;
  notifications?: Maybe<NotificationCreateManyWithoutUserInput>;
  passwordHash?: Maybe<Scalars["String"]>;
  stockChanges?: Maybe<StockChangeCreateManyWithoutByInput>;
};

export type UserCreateWithoutStockChangesInput = {
  customer?: Maybe<CustomerCreateOneWithoutUserInput>;
  email: Scalars["String"];
  employee?: Maybe<EmployeeCreateOneWithoutUserInput>;
  notifications?: Maybe<NotificationCreateManyWithoutUserInput>;
  passwordHash?: Maybe<Scalars["String"]>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export type UserUpdateOneRequiredWithoutCustomerInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutcustomerInput>;
  create?: Maybe<UserCreateWithoutCustomerInput>;
  update?: Maybe<UserUpdateWithoutCustomerInput>;
  upsert?: Maybe<UserUpsertWithoutCustomerInput>;
};

export type UserUpdateOneRequiredWithoutStockChangesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutstockChangesInput>;
  create?: Maybe<UserCreateWithoutStockChangesInput>;
  update?: Maybe<UserUpdateWithoutStockChangesInput>;
  upsert?: Maybe<UserUpsertWithoutStockChangesInput>;
};

export type UserUpdateWithoutCustomerInput = {
  email?: Maybe<StringFieldUpdateOperationsInput>;
  employee?: Maybe<EmployeeUpdateOneWithoutUserInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
  passwordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stockChanges?: Maybe<StockChangeUpdateManyWithoutByInput>;
};

export type UserUpdateWithoutStockChangesInput = {
  customer?: Maybe<CustomerUpdateOneWithoutUserInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  employee?: Maybe<EmployeeUpdateOneWithoutUserInput>;
  notifications?: Maybe<NotificationUpdateManyWithoutUserInput>;
  passwordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutCustomerInput = {
  create: UserCreateWithoutCustomerInput;
  update: UserUpdateWithoutCustomerInput;
};

export type UserUpsertWithoutStockChangesInput = {
  create: UserCreateWithoutStockChangesInput;
  update: UserUpdateWithoutStockChangesInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  customer?: Maybe<CustomerRelationFilter>;
  email?: Maybe<StringFilter>;
  employee?: Maybe<EmployeeRelationFilter>;
  id?: Maybe<IntFilter>;
  NOT?: Maybe<Array<UserWhereInput>>;
  notifications?: Maybe<NotificationListRelationFilter>;
  OR?: Maybe<Array<UserWhereInput>>;
  passwordHash?: Maybe<StringNullableFilter>;
  stockChanges?: Maybe<StockChangeListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type CallSchedulesQueryVariables = Exact<{
  where?: Maybe<CallScheduleWhereInput>;
}>;

export type CallSchedulesQuery = (
  { __typename?: "Query" }
  & { callSchedules: Array<(
    { __typename?: "CallSchedule" }
    & CallScheduleFragment
  )> }
);

export type CallScheduleQueryVariables = Exact<{
  where: CallScheduleWhereUniqueInput;
}>;

export type CallScheduleQuery = (
  { __typename?: "Query" }
  & { callSchedule?: Maybe<(
    { __typename?: "CallSchedule" }
    & CallScheduleFragment
  )> }
);

export type CreateCallScheduleMutationVariables = Exact<{
  data: CallScheduleCreateInput;
}>;

export type CreateCallScheduleMutation = (
  { __typename?: "Mutation" }
  & { createCallSchedule: (
    { __typename?: "CallSchedule" }
    & CallScheduleFragment
  ) }
);

export type UpdateCallScheduleMutationVariables = Exact<{
  where: CallScheduleWhereUniqueInput;
  data: CallScheduleUpdateInput;
}>;

export type UpdateCallScheduleMutation = (
  { __typename?: "Mutation" }
  & { updateCallSchedule?: Maybe<(
    { __typename?: "CallSchedule" }
    & CallScheduleFragment
  )> }
);

export type ApplyStockActionMutationVariables = Exact<{
  data: StockActionApply;
}>;

export type ApplyStockActionMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "applyStockAction">
);

export type FirmsQueryVariables = Exact<{
  where?: Maybe<FirmWhereInput>;
}>;

export type FirmsQuery = (
  { __typename?: "Query" }
  & { firms: Array<(
    { __typename?: "Firm" }
    & FirmFragment
  )> }
);

export type FirmQueryVariables = Exact<{
  where: FirmWhereUniqueInput;
}>;

export type FirmQuery = (
  { __typename?: "Query" }
  & { firm?: Maybe<(
    { __typename?: "Firm" }
    & FirmFragment
  )> }
);

export type CreateFirmMutationVariables = Exact<{
  data: FirmCreateInput;
}>;

export type CreateFirmMutation = (
  { __typename?: "Mutation" }
  & { createFirm: (
    { __typename?: "Firm" }
    & FirmFragment
  ) }
);

export type UpdateFirmMutationVariables = Exact<{
  where: FirmWhereUniqueInput;
  data: FirmUpdateInput;
}>;

export type UpdateFirmMutation = (
  { __typename?: "Mutation" }
  & { updateFirm?: Maybe<(
    { __typename?: "Firm" }
    & FirmFragment
  )> }
);

export type GetAllQueryVariables = Exact<{ [key: string]: never; }>;

export type GetAllQuery = (
  { __typename?: "Query" }
  & { firms: Array<(
    { __typename?: "Firm" }
    & FirmFragment
  )>, stocks: Array<(
    { __typename?: "Stock" }
    & StockFragment
  )>, productCategories: Array<(
    { __typename?: "ProductCategory" }
    & ProductCategoryFragment
  )>, products: Array<(
    { __typename?: "Product" }
    & ProductFragment
  )>, stockActions: Array<(
    { __typename?: "StockAction" }
    & StockActionFragment
  )>, callSchedules: Array<(
    { __typename?: "CallSchedule" }
    & CallScheduleFragment
  )>, me: (
    { __typename?: "User" }
    & UserFragment
  ) }
);

export type LocationsQueryVariables = Exact<{
  where?: Maybe<LocationWhereInput>;
}>;

export type LocationsQuery = (
  { __typename?: "Query" }
  & { locations: Array<(
    { __typename?: "Location" }
    & LocationFragment
  )> }
);

export type LocationQueryVariables = Exact<{
  where: LocationWhereUniqueInput;
}>;

export type LocationQuery = (
  { __typename?: "Query" }
  & { location?: Maybe<(
    { __typename?: "Location" }
    & LocationFragment
  )> }
);

export type CreateLocationMutationVariables = Exact<{
  data: LocationCreateInput;
}>;

export type CreateLocationMutation = (
  { __typename?: "Mutation" }
  & { createLocation: (
    { __typename?: "Location" }
    & LocationFragment
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  where: LocationWhereUniqueInput;
  data: LocationUpdateInput;
}>;

export type UpdateLocationMutation = (
  { __typename?: "Mutation" }
  & { updateLocation?: Maybe<(
    { __typename?: "Location" }
    & LocationFragment
  )> }
);

export type CustomNumbersQueryQueryVariables = Exact<{
  where: OrderWhereInput;
}>;

export type CustomNumbersQueryQuery = (
  { __typename?: "Query" }
  & { orders: Array<(
    { __typename?: "Order" }
    & NumberTypeFragment
  )> }
);

export type ProductsQueryVariables = Exact<{
  where?: Maybe<ProductWhereInput>;
}>;

export type ProductsQuery = (
  { __typename?: "Query" }
  & { products: Array<(
    { __typename?: "Product" }
    & ProductFragment
  )> }
);

export type ProductQueryVariables = Exact<{
  where: ProductWhereUniqueInput;
}>;

export type ProductQuery = (
  { __typename?: "Query" }
  & { product?: Maybe<(
    { __typename?: "Product" }
    & ProductFragment
  )> }
);

export type CreateProductMutationVariables = Exact<{
  data: ProductCreateInput;
}>;

export type CreateProductMutation = (
  { __typename?: "Mutation" }
  & { createProduct: (
    { __typename?: "Product" }
    & ProductFragment
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  where: ProductWhereUniqueInput;
  data: ProductUpdateInput;
}>;

export type UpdateProductMutation = (
  { __typename?: "Mutation" }
  & { updateProduct?: Maybe<(
    { __typename?: "Product" }
    & ProductFragment
  )> }
);

export type ProductCategoriesQueryVariables = Exact<{
  where?: Maybe<ProductCategoryWhereInput>;
}>;

export type ProductCategoriesQuery = (
  { __typename?: "Query" }
  & { productCategories: Array<(
    { __typename?: "ProductCategory" }
    & ProductCategoryFragment
  )> }
);

export type ProductCategoryQueryVariables = Exact<{
  where: ProductCategoryWhereUniqueInput;
}>;

export type ProductCategoryQuery = (
  { __typename?: "Query" }
  & { productCategory?: Maybe<(
    { __typename?: "ProductCategory" }
    & ProductCategoryFragment
  )> }
);

export type CreateProductCategoryMutationVariables = Exact<{
  data: ProductCategoryCreateInput;
}>;

export type CreateProductCategoryMutation = (
  { __typename?: "Mutation" }
  & { createProductCategory: (
    { __typename?: "ProductCategory" }
    & ProductCategoryFragment
  ) }
);

export type UpdateProductCategoryMutationVariables = Exact<{
  where: ProductCategoryWhereUniqueInput;
  data: ProductCategoryUpdateInput;
}>;

export type UpdateProductCategoryMutation = (
  { __typename?: "Mutation" }
  & { updateProductCategory?: Maybe<(
    { __typename?: "ProductCategory" }
    & ProductCategoryFragment
  )> }
);

export type StocksQueryVariables = Exact<{
  where?: Maybe<StockWhereInput>;
}>;

export type StocksQuery = (
  { __typename?: "Query" }
  & { stocks: Array<(
    { __typename?: "Stock" }
    & StockFragment
  )> }
);

export type StockQueryVariables = Exact<{
  where: StockWhereUniqueInput;
}>;

export type StockQuery = (
  { __typename?: "Query" }
  & { stock?: Maybe<(
    { __typename?: "Stock" }
    & StockDetailFragment
  )> }
);

export type CreateStockMutationVariables = Exact<{
  data: StockCreateInput;
}>;

export type CreateStockMutation = (
  { __typename?: "Mutation" }
  & { createStock: (
    { __typename?: "Stock" }
    & StockFragment
  ) }
);

export type UpdateStockMutationVariables = Exact<{
  where: StockWhereUniqueInput;
  data: StockUpdateInput;
}>;

export type UpdateStockMutation = (
  { __typename?: "Mutation" }
  & { updateStock?: Maybe<(
    { __typename?: "Stock" }
    & StockFragment
  )> }
);

export type StockActionsQueryVariables = Exact<{
  where?: Maybe<StockActionWhereInput>;
}>;

export type StockActionsQuery = (
  { __typename?: "Query" }
  & { stockActions: Array<(
    { __typename?: "StockAction" }
    & StockActionFragment
  )> }
);

export type StockActionQueryVariables = Exact<{
  where: StockActionWhereUniqueInput;
}>;

export type StockActionQuery = (
  { __typename?: "Query" }
  & { stockAction?: Maybe<(
    { __typename?: "StockAction" }
    & StockActionFragment
  )> }
);

export type CreateStockActionMutationVariables = Exact<{
  data: StockActionCreateInput;
}>;

export type CreateStockActionMutation = (
  { __typename?: "Mutation" }
  & { createStockAction: (
    { __typename?: "StockAction" }
    & StockActionFragment
  ) }
);

export type UpdateStockActionMutationVariables = Exact<{
  where: StockActionWhereUniqueInput;
  data: StockActionUpdateInput;
}>;

export type UpdateStockActionMutation = (
  { __typename?: "Mutation" }
  & { updateStockAction?: Maybe<(
    { __typename?: "StockAction" }
    & StockActionFragment
  )> }
);

export type CustomerFragment = (
  { __typename?: "Customer" }
  & Pick<Customer, "id" | "name">
);

export type CartFragment = (
  { __typename?: "Cart" }
  & { cartItems?: Maybe<Array<(
    { __typename?: "CartItem" }
    & Pick<CartItem, "productPriceId" | "comment" | "qty" | "bulk">
  )>> }
);

export type LocationFragment = (
  { __typename?: "Location" }
  & Pick<Location, "id" | "comment" | "orderMethod" | "name" | "disabled" | "locationType" | "stickerColor" | "printDate" | "printBarcode" | "monthly" | "send" | "paymentType" | "firmId" | "address" | "billingAddress" | "phones" | "cartId">
  & { fullStock: (
    { __typename?: "Cart" }
    & CartFragment
  ) }
);

export type FirmFragment = (
  { __typename?: "Firm" }
  & Pick<Firm, "id" | "name" | "disabled" | "billingAddress">
  & { locations?: Maybe<Array<(
    { __typename?: "Location" }
    & LocationFragment
  )>>, customer?: Maybe<Array<(
    { __typename?: "Customer" }
    & CustomerFragment
  )>> }
);

export type StockFragment = (
  { __typename?: "Stock" }
  & Pick<Stock, "id" | "name" | "qty" | "disabled">
  & { fallback?: Maybe<(
    { __typename?: "Stock" }
    & Pick<Stock, "id" | "name" | "qty" | "disabled">
  )> }
);

export type StockDetailFragment = (
  { __typename?: "Stock" }
  & { stockSubChanges?: Maybe<Array<(
    { __typename?: "StockSubChange" }
    & Pick<StockSubChange, "changeType" | "value">
    & { stockChange: (
      { __typename?: "StockChange" }
      & Pick<StockChange, "createdAt" | "comment">
      & { by: (
        { __typename?: "User" }
        & Pick<User, "id" | "email">
      ) }
    ) }
  )>> }
  & StockFragment
);

export type UserFragment = (
  { __typename?: "User" }
  & Pick<User, "id" | "email">
);

export type StockActionFragment = (
  { __typename?: "StockAction" }
  & Pick<StockAction, "id" | "hidden" | "name" | "presetValues">
  & { subActions?: Maybe<Array<(
    { __typename?: "StockSubAction" }
    & Pick<StockSubAction, "id" | "delta">
    & { stock: (
      { __typename?: "Stock" }
      & Pick<Stock, "id" | "name" | "qty">
    ) }
  )>> }
);

export type ProductPriceFragment = (
  { __typename?: "ProductPrice" }
  & Pick<ProductPrice, "id" | "disabled" | "price" | "locationType">
);

export type ProductFragment = (
  { __typename?: "Product" }
  & Pick<Product, "id" | "disabled" | "name" | "subname" | "order" | "categoryId" | "stockActionId">
  & { category?: Maybe<(
    { __typename?: "ProductCategory" }
    & Pick<ProductCategory, "name">
  )>, productPrices?: Maybe<Array<(
    { __typename?: "ProductPrice" }
    & ProductPriceFragment
  )>> }
);

export type ProductCategoryFragment = (
  { __typename?: "ProductCategory" }
  & Pick<ProductCategory, "id" | "name" | "order">
  & { products?: Maybe<Array<(
    { __typename?: "Product" }
    & ProductFragment
  )>> }
);

export type CallScheduleFragment = (
  { __typename?: "CallSchedule" }
  & Pick<CallSchedule, "id" | "comment" | "day" | "deliveryDayAddition" | "deliveryTime">
  & { location: (
    { __typename?: "Location" }
    & LocationFragment
  ) }
);

export type NumberTypeFragment = (
  { __typename?: "Order" }
  & Pick<Order, "id" | "comment">
  & { cart: (
    { __typename?: "Cart" }
    & { cartItems?: Maybe<Array<(
      { __typename?: "CartItem" }
      & Pick<CartItem, "qty">
      & { productPrice: (
        { __typename?: "ProductPrice" }
        & Pick<ProductPrice, "price">
      ) }
    )>> }
  ) }
);

export const CartFragmentDoc = gql`
    fragment Cart on Cart {
  cartItems {
    productPriceId
    comment
    qty
    bulk
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  comment
  orderMethod
  name
  disabled
  locationType
  stickerColor
  printDate
  printBarcode
  monthly
  send
  paymentType
  firmId
  address
  billingAddress
  phones
  cartId
  fullStock {
    ...Cart
  }
}
    ${CartFragmentDoc}`;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  name
}
    `;
export const FirmFragmentDoc = gql`
    fragment Firm on Firm {
  id
  name
  disabled
  billingAddress
  locations {
    ...Location
  }
  customer {
    ...Customer
  }
}
    ${LocationFragmentDoc}
${CustomerFragmentDoc}`;
export const StockFragmentDoc = gql`
    fragment Stock on Stock {
  id
  name
  qty
  disabled
  fallback {
    id
    name
    qty
    disabled
  }
}
    `;
export const StockDetailFragmentDoc = gql`
    fragment StockDetail on Stock {
  ...Stock
  stockSubChanges(orderBy: {id: desc}) {
    changeType
    value
    stockChange {
      createdAt
      comment
      by {
        id
        email
      }
    }
  }
}
    ${StockFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
}
    `;
export const StockActionFragmentDoc = gql`
    fragment StockAction on StockAction {
  id
  hidden
  name
  presetValues
  subActions {
    id
    delta
    stock {
      id
      name
      qty
    }
  }
}
    `;
export const ProductPriceFragmentDoc = gql`
    fragment ProductPrice on ProductPrice {
  id
  disabled
  price
  locationType
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  disabled
  name
  subname
  order
  categoryId
  category {
    name
  }
  stockActionId
  productPrices {
    ...ProductPrice
  }
}
    ${ProductPriceFragmentDoc}`;
export const ProductCategoryFragmentDoc = gql`
    fragment ProductCategory on ProductCategory {
  id
  name
  order
  products(orderBy: {order: asc}) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export const CallScheduleFragmentDoc = gql`
    fragment CallSchedule on CallSchedule {
  id
  comment
  day
  deliveryDayAddition
  deliveryTime
  location {
    ...Location
  }
}
    ${LocationFragmentDoc}`;
export const NumberTypeFragmentDoc = gql`
    fragment NumberType on Order {
  id
  comment
  cart {
    cartItems {
      qty
      productPrice {
        price
      }
    }
  }
}
    `;
export const CallSchedulesDocument = gql`
    query CallSchedules($where: CallScheduleWhereInput) {
  callSchedules(where: $where) {
    ...CallSchedule
  }
}
    ${CallScheduleFragmentDoc}`;

/**
 * __useCallSchedulesQuery__
 *
 * To run a query within a React component, call `useCallSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallSchedulesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCallSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<CallSchedulesQuery, CallSchedulesQueryVariables>) {
        return Apollo.useQuery<CallSchedulesQuery, CallSchedulesQueryVariables>(CallSchedulesDocument, baseOptions);
      }
export function useCallSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallSchedulesQuery, CallSchedulesQueryVariables>) {
          return Apollo.useLazyQuery<CallSchedulesQuery, CallSchedulesQueryVariables>(CallSchedulesDocument, baseOptions);
        }
export type CallSchedulesQueryHookResult = ReturnType<typeof useCallSchedulesQuery>;
export type CallSchedulesLazyQueryHookResult = ReturnType<typeof useCallSchedulesLazyQuery>;
export type CallSchedulesQueryResult = Apollo.QueryResult<CallSchedulesQuery, CallSchedulesQueryVariables>;
export const CallScheduleDocument = gql`
    query CallSchedule($where: CallScheduleWhereUniqueInput!) {
  callSchedule(where: $where) {
    ...CallSchedule
  }
}
    ${CallScheduleFragmentDoc}`;

/**
 * __useCallScheduleQuery__
 *
 * To run a query within a React component, call `useCallScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallScheduleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCallScheduleQuery(baseOptions: Apollo.QueryHookOptions<CallScheduleQuery, CallScheduleQueryVariables>) {
        return Apollo.useQuery<CallScheduleQuery, CallScheduleQueryVariables>(CallScheduleDocument, baseOptions);
      }
export function useCallScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallScheduleQuery, CallScheduleQueryVariables>) {
          return Apollo.useLazyQuery<CallScheduleQuery, CallScheduleQueryVariables>(CallScheduleDocument, baseOptions);
        }
export type CallScheduleQueryHookResult = ReturnType<typeof useCallScheduleQuery>;
export type CallScheduleLazyQueryHookResult = ReturnType<typeof useCallScheduleLazyQuery>;
export type CallScheduleQueryResult = Apollo.QueryResult<CallScheduleQuery, CallScheduleQueryVariables>;
export const CreateCallScheduleDocument = gql`
    mutation CreateCallSchedule($data: CallScheduleCreateInput!) {
  createCallSchedule(data: $data) {
    ...CallSchedule
  }
}
    ${CallScheduleFragmentDoc}`;
export type CreateCallScheduleMutationFn = Apollo.MutationFunction<CreateCallScheduleMutation, CreateCallScheduleMutationVariables>;

/**
 * __useCreateCallScheduleMutation__
 *
 * To run a mutation, you first call `useCreateCallScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCallScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCallScheduleMutation, { data, loading, error }] = useCreateCallScheduleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCallScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCallScheduleMutation, CreateCallScheduleMutationVariables>) {
        return Apollo.useMutation<CreateCallScheduleMutation, CreateCallScheduleMutationVariables>(CreateCallScheduleDocument, baseOptions);
      }
export type CreateCallScheduleMutationHookResult = ReturnType<typeof useCreateCallScheduleMutation>;
export type CreateCallScheduleMutationResult = Apollo.MutationResult<CreateCallScheduleMutation>;
export type CreateCallScheduleMutationOptions = Apollo.BaseMutationOptions<CreateCallScheduleMutation, CreateCallScheduleMutationVariables>;
export const UpdateCallScheduleDocument = gql`
    mutation UpdateCallSchedule($where: CallScheduleWhereUniqueInput!, $data: CallScheduleUpdateInput!) {
  updateCallSchedule(where: $where, data: $data) {
    ...CallSchedule
  }
}
    ${CallScheduleFragmentDoc}`;
export type UpdateCallScheduleMutationFn = Apollo.MutationFunction<UpdateCallScheduleMutation, UpdateCallScheduleMutationVariables>;

/**
 * __useUpdateCallScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateCallScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallScheduleMutation, { data, loading, error }] = useUpdateCallScheduleMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCallScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCallScheduleMutation, UpdateCallScheduleMutationVariables>) {
        return Apollo.useMutation<UpdateCallScheduleMutation, UpdateCallScheduleMutationVariables>(UpdateCallScheduleDocument, baseOptions);
      }
export type UpdateCallScheduleMutationHookResult = ReturnType<typeof useUpdateCallScheduleMutation>;
export type UpdateCallScheduleMutationResult = Apollo.MutationResult<UpdateCallScheduleMutation>;
export type UpdateCallScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateCallScheduleMutation, UpdateCallScheduleMutationVariables>;
export const ApplyStockActionDocument = gql`
    mutation applyStockAction($data: StockActionApply!) {
  applyStockAction(data: $data)
}
    `;
export type ApplyStockActionMutationFn = Apollo.MutationFunction<ApplyStockActionMutation, ApplyStockActionMutationVariables>;

/**
 * __useApplyStockActionMutation__
 *
 * To run a mutation, you first call `useApplyStockActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyStockActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyStockActionMutation, { data, loading, error }] = useApplyStockActionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApplyStockActionMutation(baseOptions?: Apollo.MutationHookOptions<ApplyStockActionMutation, ApplyStockActionMutationVariables>) {
        return Apollo.useMutation<ApplyStockActionMutation, ApplyStockActionMutationVariables>(ApplyStockActionDocument, baseOptions);
      }
export type ApplyStockActionMutationHookResult = ReturnType<typeof useApplyStockActionMutation>;
export type ApplyStockActionMutationResult = Apollo.MutationResult<ApplyStockActionMutation>;
export type ApplyStockActionMutationOptions = Apollo.BaseMutationOptions<ApplyStockActionMutation, ApplyStockActionMutationVariables>;
export const FirmsDocument = gql`
    query Firms($where: FirmWhereInput) {
  firms(where: $where) {
    ...Firm
  }
}
    ${FirmFragmentDoc}`;

/**
 * __useFirmsQuery__
 *
 * To run a query within a React component, call `useFirmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFirmsQuery(baseOptions?: Apollo.QueryHookOptions<FirmsQuery, FirmsQueryVariables>) {
        return Apollo.useQuery<FirmsQuery, FirmsQueryVariables>(FirmsDocument, baseOptions);
      }
export function useFirmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmsQuery, FirmsQueryVariables>) {
          return Apollo.useLazyQuery<FirmsQuery, FirmsQueryVariables>(FirmsDocument, baseOptions);
        }
export type FirmsQueryHookResult = ReturnType<typeof useFirmsQuery>;
export type FirmsLazyQueryHookResult = ReturnType<typeof useFirmsLazyQuery>;
export type FirmsQueryResult = Apollo.QueryResult<FirmsQuery, FirmsQueryVariables>;
export const FirmDocument = gql`
    query Firm($where: FirmWhereUniqueInput!) {
  firm(where: $where) {
    ...Firm
  }
}
    ${FirmFragmentDoc}`;

/**
 * __useFirmQuery__
 *
 * To run a query within a React component, call `useFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFirmQuery(baseOptions: Apollo.QueryHookOptions<FirmQuery, FirmQueryVariables>) {
        return Apollo.useQuery<FirmQuery, FirmQueryVariables>(FirmDocument, baseOptions);
      }
export function useFirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmQuery, FirmQueryVariables>) {
          return Apollo.useLazyQuery<FirmQuery, FirmQueryVariables>(FirmDocument, baseOptions);
        }
export type FirmQueryHookResult = ReturnType<typeof useFirmQuery>;
export type FirmLazyQueryHookResult = ReturnType<typeof useFirmLazyQuery>;
export type FirmQueryResult = Apollo.QueryResult<FirmQuery, FirmQueryVariables>;
export const CreateFirmDocument = gql`
    mutation CreateFirm($data: FirmCreateInput!) {
  createFirm(data: $data) {
    ...Firm
  }
}
    ${FirmFragmentDoc}`;
export type CreateFirmMutationFn = Apollo.MutationFunction<CreateFirmMutation, CreateFirmMutationVariables>;

/**
 * __useCreateFirmMutation__
 *
 * To run a mutation, you first call `useCreateFirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirmMutation, { data, loading, error }] = useCreateFirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFirmMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirmMutation, CreateFirmMutationVariables>) {
        return Apollo.useMutation<CreateFirmMutation, CreateFirmMutationVariables>(CreateFirmDocument, baseOptions);
      }
export type CreateFirmMutationHookResult = ReturnType<typeof useCreateFirmMutation>;
export type CreateFirmMutationResult = Apollo.MutationResult<CreateFirmMutation>;
export type CreateFirmMutationOptions = Apollo.BaseMutationOptions<CreateFirmMutation, CreateFirmMutationVariables>;
export const UpdateFirmDocument = gql`
    mutation UpdateFirm($where: FirmWhereUniqueInput!, $data: FirmUpdateInput!) {
  updateFirm(where: $where, data: $data) {
    ...Firm
  }
}
    ${FirmFragmentDoc}`;
export type UpdateFirmMutationFn = Apollo.MutationFunction<UpdateFirmMutation, UpdateFirmMutationVariables>;

/**
 * __useUpdateFirmMutation__
 *
 * To run a mutation, you first call `useUpdateFirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmMutation, { data, loading, error }] = useUpdateFirmMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFirmMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmMutation, UpdateFirmMutationVariables>) {
        return Apollo.useMutation<UpdateFirmMutation, UpdateFirmMutationVariables>(UpdateFirmDocument, baseOptions);
      }
export type UpdateFirmMutationHookResult = ReturnType<typeof useUpdateFirmMutation>;
export type UpdateFirmMutationResult = Apollo.MutationResult<UpdateFirmMutation>;
export type UpdateFirmMutationOptions = Apollo.BaseMutationOptions<UpdateFirmMutation, UpdateFirmMutationVariables>;
export const GetAllDocument = gql`
    query GetAll {
  firms(orderBy: {name: asc}) {
    ...Firm
  }
  stocks(orderBy: {name: asc}) {
    ...Stock
  }
  productCategories(orderBy: {order: asc}) {
    ...ProductCategory
  }
  products(orderBy: {order: asc}) {
    ...Product
  }
  stockActions(orderBy: {name: asc}) {
    ...StockAction
  }
  callSchedules(orderBy: {deliveryTime: asc}) {
    ...CallSchedule
  }
  me {
    ...User
  }
}
    ${FirmFragmentDoc}
${StockFragmentDoc}
${ProductCategoryFragmentDoc}
${ProductFragmentDoc}
${StockActionFragmentDoc}
${CallScheduleFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useGetAllQuery__
 *
 * To run a query within a React component, call `useGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllQuery(baseOptions?: Apollo.QueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
        return Apollo.useQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, baseOptions);
      }
export function useGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
          return Apollo.useLazyQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, baseOptions);
        }
export type GetAllQueryHookResult = ReturnType<typeof useGetAllQuery>;
export type GetAllLazyQueryHookResult = ReturnType<typeof useGetAllLazyQuery>;
export type GetAllQueryResult = Apollo.QueryResult<GetAllQuery, GetAllQueryVariables>;
export const LocationsDocument = gql`
    query Locations($where: LocationWhereInput) {
  locations(where: $where) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LocationDocument = gql`
    query Location($where: LocationWhereUniqueInput!) {
  location(where: $where) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
        return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, baseOptions);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
          return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, baseOptions);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($data: LocationCreateInput!) {
  createLocation(data: $data) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($where: LocationWhereUniqueInput!, $data: LocationUpdateInput!) {
  updateLocation(where: $where, data: $data) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const CustomNumbersQueryDocument = gql`
    query CustomNumbersQuery($where: OrderWhereInput!) {
  orders(where: $where) {
    ...NumberType
  }
}
    ${NumberTypeFragmentDoc}`;

/**
 * __useCustomNumbersQueryQuery__
 *
 * To run a query within a React component, call `useCustomNumbersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomNumbersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomNumbersQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomNumbersQueryQuery(baseOptions: Apollo.QueryHookOptions<CustomNumbersQueryQuery, CustomNumbersQueryQueryVariables>) {
        return Apollo.useQuery<CustomNumbersQueryQuery, CustomNumbersQueryQueryVariables>(CustomNumbersQueryDocument, baseOptions);
      }
export function useCustomNumbersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomNumbersQueryQuery, CustomNumbersQueryQueryVariables>) {
          return Apollo.useLazyQuery<CustomNumbersQueryQuery, CustomNumbersQueryQueryVariables>(CustomNumbersQueryDocument, baseOptions);
        }
export type CustomNumbersQueryQueryHookResult = ReturnType<typeof useCustomNumbersQueryQuery>;
export type CustomNumbersQueryLazyQueryHookResult = ReturnType<typeof useCustomNumbersQueryLazyQuery>;
export type CustomNumbersQueryQueryResult = Apollo.QueryResult<CustomNumbersQueryQuery, CustomNumbersQueryQueryVariables>;
export const ProductsDocument = gql`
    query Products($where: ProductWhereInput) {
  products(where: $where) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($where: ProductWhereUniqueInput!) {
  product(where: $where) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($data: ProductCreateInput!) {
  createProduct(data: $data) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($where: ProductWhereUniqueInput!, $data: ProductUpdateInput!) {
  updateProduct(where: $where, data: $data) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const ProductCategoriesDocument = gql`
    query ProductCategories($where: ProductCategoryWhereInput) {
  productCategories(where: $where, orderBy: {order: asc}) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
        return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, baseOptions);
      }
export function useProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, baseOptions);
        }
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductCategoryDocument = gql`
    query ProductCategory($where: ProductCategoryWhereUniqueInput!) {
  productCategory(where: $where) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;

/**
 * __useProductCategoryQuery__
 *
 * To run a query within a React component, call `useProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductCategoryQuery(baseOptions: Apollo.QueryHookOptions<ProductCategoryQuery, ProductCategoryQueryVariables>) {
        return Apollo.useQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, baseOptions);
      }
export function useProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoryQuery, ProductCategoryQueryVariables>) {
          return Apollo.useLazyQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, baseOptions);
        }
export type ProductCategoryQueryHookResult = ReturnType<typeof useProductCategoryQuery>;
export type ProductCategoryLazyQueryHookResult = ReturnType<typeof useProductCategoryLazyQuery>;
export type ProductCategoryQueryResult = Apollo.QueryResult<ProductCategoryQuery, ProductCategoryQueryVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($data: ProductCategoryCreateInput!) {
  createProductCategory(data: $data) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, baseOptions);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const UpdateProductCategoryDocument = gql`
    mutation UpdateProductCategory($where: ProductCategoryWhereUniqueInput!, $data: ProductCategoryUpdateInput!) {
  updateProductCategory(where: $where, data: $data) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;
export type UpdateProductCategoryMutationFn = Apollo.MutationFunction<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;

/**
 * __useUpdateProductCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryMutation, { data, loading, error }] = useUpdateProductCategoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(UpdateProductCategoryDocument, baseOptions);
      }
export type UpdateProductCategoryMutationHookResult = ReturnType<typeof useUpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationResult = Apollo.MutationResult<UpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;
export const StocksDocument = gql`
    query Stocks($where: StockWhereInput) {
  stocks(where: $where) {
    ...Stock
  }
}
    ${StockFragmentDoc}`;

/**
 * __useStocksQuery__
 *
 * To run a query within a React component, call `useStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStocksQuery(baseOptions?: Apollo.QueryHookOptions<StocksQuery, StocksQueryVariables>) {
        return Apollo.useQuery<StocksQuery, StocksQueryVariables>(StocksDocument, baseOptions);
      }
export function useStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          return Apollo.useLazyQuery<StocksQuery, StocksQueryVariables>(StocksDocument, baseOptions);
        }
export type StocksQueryHookResult = ReturnType<typeof useStocksQuery>;
export type StocksLazyQueryHookResult = ReturnType<typeof useStocksLazyQuery>;
export type StocksQueryResult = Apollo.QueryResult<StocksQuery, StocksQueryVariables>;
export const StockDocument = gql`
    query Stock($where: StockWhereUniqueInput!) {
  stock(where: $where) {
    ...StockDetail
  }
}
    ${StockDetailFragmentDoc}`;

/**
 * __useStockQuery__
 *
 * To run a query within a React component, call `useStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStockQuery(baseOptions: Apollo.QueryHookOptions<StockQuery, StockQueryVariables>) {
        return Apollo.useQuery<StockQuery, StockQueryVariables>(StockDocument, baseOptions);
      }
export function useStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockQuery, StockQueryVariables>) {
          return Apollo.useLazyQuery<StockQuery, StockQueryVariables>(StockDocument, baseOptions);
        }
export type StockQueryHookResult = ReturnType<typeof useStockQuery>;
export type StockLazyQueryHookResult = ReturnType<typeof useStockLazyQuery>;
export type StockQueryResult = Apollo.QueryResult<StockQuery, StockQueryVariables>;
export const CreateStockDocument = gql`
    mutation CreateStock($data: StockCreateInput!) {
  createStock(data: $data) {
    ...Stock
  }
}
    ${StockFragmentDoc}`;
export type CreateStockMutationFn = Apollo.MutationFunction<CreateStockMutation, CreateStockMutationVariables>;

/**
 * __useCreateStockMutation__
 *
 * To run a mutation, you first call `useCreateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMutation, { data, loading, error }] = useCreateStockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockMutation, CreateStockMutationVariables>) {
        return Apollo.useMutation<CreateStockMutation, CreateStockMutationVariables>(CreateStockDocument, baseOptions);
      }
export type CreateStockMutationHookResult = ReturnType<typeof useCreateStockMutation>;
export type CreateStockMutationResult = Apollo.MutationResult<CreateStockMutation>;
export type CreateStockMutationOptions = Apollo.BaseMutationOptions<CreateStockMutation, CreateStockMutationVariables>;
export const UpdateStockDocument = gql`
    mutation UpdateStock($where: StockWhereUniqueInput!, $data: StockUpdateInput!) {
  updateStock(where: $where, data: $data) {
    ...Stock
  }
}
    ${StockFragmentDoc}`;
export type UpdateStockMutationFn = Apollo.MutationFunction<UpdateStockMutation, UpdateStockMutationVariables>;

/**
 * __useUpdateStockMutation__
 *
 * To run a mutation, you first call `useUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockMutation, { data, loading, error }] = useUpdateStockMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockMutation, UpdateStockMutationVariables>) {
        return Apollo.useMutation<UpdateStockMutation, UpdateStockMutationVariables>(UpdateStockDocument, baseOptions);
      }
export type UpdateStockMutationHookResult = ReturnType<typeof useUpdateStockMutation>;
export type UpdateStockMutationResult = Apollo.MutationResult<UpdateStockMutation>;
export type UpdateStockMutationOptions = Apollo.BaseMutationOptions<UpdateStockMutation, UpdateStockMutationVariables>;
export const StockActionsDocument = gql`
    query StockActions($where: StockActionWhereInput) {
  stockActions(where: $where, orderBy: {name: asc}) {
    ...StockAction
  }
}
    ${StockActionFragmentDoc}`;

/**
 * __useStockActionsQuery__
 *
 * To run a query within a React component, call `useStockActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockActionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStockActionsQuery(baseOptions?: Apollo.QueryHookOptions<StockActionsQuery, StockActionsQueryVariables>) {
        return Apollo.useQuery<StockActionsQuery, StockActionsQueryVariables>(StockActionsDocument, baseOptions);
      }
export function useStockActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockActionsQuery, StockActionsQueryVariables>) {
          return Apollo.useLazyQuery<StockActionsQuery, StockActionsQueryVariables>(StockActionsDocument, baseOptions);
        }
export type StockActionsQueryHookResult = ReturnType<typeof useStockActionsQuery>;
export type StockActionsLazyQueryHookResult = ReturnType<typeof useStockActionsLazyQuery>;
export type StockActionsQueryResult = Apollo.QueryResult<StockActionsQuery, StockActionsQueryVariables>;
export const StockActionDocument = gql`
    query StockAction($where: StockActionWhereUniqueInput!) {
  stockAction(where: $where) {
    ...StockAction
  }
}
    ${StockActionFragmentDoc}`;

/**
 * __useStockActionQuery__
 *
 * To run a query within a React component, call `useStockActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockActionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStockActionQuery(baseOptions: Apollo.QueryHookOptions<StockActionQuery, StockActionQueryVariables>) {
        return Apollo.useQuery<StockActionQuery, StockActionQueryVariables>(StockActionDocument, baseOptions);
      }
export function useStockActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockActionQuery, StockActionQueryVariables>) {
          return Apollo.useLazyQuery<StockActionQuery, StockActionQueryVariables>(StockActionDocument, baseOptions);
        }
export type StockActionQueryHookResult = ReturnType<typeof useStockActionQuery>;
export type StockActionLazyQueryHookResult = ReturnType<typeof useStockActionLazyQuery>;
export type StockActionQueryResult = Apollo.QueryResult<StockActionQuery, StockActionQueryVariables>;
export const CreateStockActionDocument = gql`
    mutation CreateStockAction($data: StockActionCreateInput!) {
  createStockAction(data: $data) {
    ...StockAction
  }
}
    ${StockActionFragmentDoc}`;
export type CreateStockActionMutationFn = Apollo.MutationFunction<CreateStockActionMutation, CreateStockActionMutationVariables>;

/**
 * __useCreateStockActionMutation__
 *
 * To run a mutation, you first call `useCreateStockActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockActionMutation, { data, loading, error }] = useCreateStockActionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStockActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockActionMutation, CreateStockActionMutationVariables>) {
        return Apollo.useMutation<CreateStockActionMutation, CreateStockActionMutationVariables>(CreateStockActionDocument, baseOptions);
      }
export type CreateStockActionMutationHookResult = ReturnType<typeof useCreateStockActionMutation>;
export type CreateStockActionMutationResult = Apollo.MutationResult<CreateStockActionMutation>;
export type CreateStockActionMutationOptions = Apollo.BaseMutationOptions<CreateStockActionMutation, CreateStockActionMutationVariables>;
export const UpdateStockActionDocument = gql`
    mutation UpdateStockAction($where: StockActionWhereUniqueInput!, $data: StockActionUpdateInput!) {
  updateStockAction(where: $where, data: $data) {
    ...StockAction
  }
}
    ${StockActionFragmentDoc}`;
export type UpdateStockActionMutationFn = Apollo.MutationFunction<UpdateStockActionMutation, UpdateStockActionMutationVariables>;

/**
 * __useUpdateStockActionMutation__
 *
 * To run a mutation, you first call `useUpdateStockActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockActionMutation, { data, loading, error }] = useUpdateStockActionMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStockActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockActionMutation, UpdateStockActionMutationVariables>) {
        return Apollo.useMutation<UpdateStockActionMutation, UpdateStockActionMutationVariables>(UpdateStockActionDocument, baseOptions);
      }
export type UpdateStockActionMutationHookResult = ReturnType<typeof useUpdateStockActionMutation>;
export type UpdateStockActionMutationResult = Apollo.MutationResult<UpdateStockActionMutation>;
export type UpdateStockActionMutationOptions = Apollo.BaseMutationOptions<UpdateStockActionMutation, UpdateStockActionMutationVariables>;