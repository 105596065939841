import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  NonInputableField,
  SingleSelect,
  SpacedDivider,
} from "@bjelos-farm/lib-client-common";
import {
  LocationType,
  ProductCreateInput,
  useCreateProductMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { textFromLocationType } from "../../Utils/enumToText";

export const Create: React.FC = () => {
  const { productCategories, stockActions } = useData();
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateProductMutation({
    update: (_, { data }) => {
      setRedirect(data?.createProduct.id);
    },
  });
  const formik = useFormik<ProductCreateInput>({
    initialValues: {
      name: "",
      order: 0,
      subname: "",
      stockAction: { connect: { id: stockActions[0].id } },
      productPrices: {
        create: [
          { price: 0, locationType: LocationType.Retailer },
          { price: 0, locationType: LocationType.Restaurateur },
          { price: 0, locationType: LocationType.Enduser },
        ],
      },
    },
    onSubmit: (data) =>
      createMutation({
        variables: { data },
      }),
  });

  if (getRedirect) return <Redirect to={`/products/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader subheader="Ajouter un produit" title="Produit" />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="subname"
                onChange={formik.handleChange}
                value={formik.values.subname}
                label="Sous nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="order"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.order}
                label="Ordre"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                label="Catégorie"
                options={productCategories.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                currentValue={formik.values.category?.connect?.id}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value || value === 0) {
                      oldValues.category = { connect: { id: value } };
                      return oldValues;
                    } else {
                      delete oldValues.category;
                      return oldValues;
                    }
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Action sur stock"
                options={stockActions.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                currentValue={formik.values.stockAction?.connect?.id}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value || value === 0) {
                      oldValues.stockAction = { connect: { id: value } };
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
          </Grid>
          <SpacedDivider space={10} />
          <Typography variant="h4" gutterBottom>
            Prix EN CENTIME
          </Typography>
          <Grid container spacing={3}>
            {formik.values.productPrices?.create?.map((productPrice, index) => (
              <Grid item md={6} xs={12} key={index}>
                <NonInputableField
                  name={`productPrices.create[${index}].price`}
                  type="number"
                  onChange={formik.handleChange}
                  value={productPrice.price}
                  label={textFromLocationType(productPrice.locationType)}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer le produit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
