import React from "react";
import { useParams } from "react-router-dom";
import MaterialTable from "material-table";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import { humanReadableDate } from "@bjelos-farm/lib-common-tools";
import {
  ChangeType,
  StockUpdateInput,
  useUpdateStockMutation,
  useStockQuery,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { stocks, me, refetch } = useData();
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const [getChange, setChange] = React.useState<number>(0);
  const [getComment, setComment] = React.useState<string>("");
  const { data, loading } = useStockQuery({
    variables: { where: { id: numId } },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateMutation] = useUpdateStockMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<StockUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.stock;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData || !me) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      name: { set: pageData.name },
      disabled: { set: pageData.disabled },
      fallback: pageData.fallback
        ? { connect: { id: pageData.fallback?.id } }
        : {},
    });
  };

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title={`Stock : ${pageData.qty}`}
          action={
            <>
              <IconButton
                disabled={!getEditable}
                onClick={() =>
                  formik.setValues((oldValues) => {
                    oldValues.disabled = { set: !oldValues.disabled?.set };
                    return oldValues;
                  })
                }
              >
                {(
                  getEditable ? formik.values.disabled?.set : pageData.disabled
                ) ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="name.set"
                value={getEditable ? formik.values.name?.set : pageData.name}
                onChange={formik.handleChange}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  label="Alternative"
                  options={stocks
                    .filter(
                      (stock) => !stock.disabled && stock.id !== pageData.id,
                    )
                    .map((stock) => ({
                      label: stock.name,
                      value: stock.id,
                    }))}
                  currentValue={formik.values.fallback?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.fallback = { connect: { id: value } };
                        return oldValues;
                      } else {
                        oldValues.fallback = { disconnect: true };
                        return oldValues;
                      }
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={pageData.fallback?.name || "Aucun"}
                  label="Fallback"
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
      <SpacedDivider />
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Action
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <NonInputableField
              value={getChange}
              type="number"
              onChange={(value) => setChange(parseInt(value.target.value))}
              label="Changement de stock"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <NonInputableField
              value={getComment}
              onChange={(value) => setComment(value.target.value)}
              label="Commentaire du changement"
            />
          </Grid>
          <Grid container justify="space-around">
            <Button
              variant="contained"
              onClick={() =>
                updateMutation({
                  variables: {
                    where: { id: pageData.id },
                    data: {
                      qty: { set: getChange },
                      stockSubChanges: {
                        create: [
                          {
                            changeType: ChangeType.Set,
                            value: getChange,
                            stockChange: {
                              create: {
                                by: { connect: { id: me.id } },
                                comment: getComment,
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                })
              }
            >
              Modification absolue
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                updateMutation({
                  variables: {
                    where: { id: pageData.id },
                    data: {
                      qty: { set: getChange + pageData.qty },
                      stockSubChanges: {
                        create: [
                          {
                            changeType: ChangeType.Change,
                            value: getChange,
                            stockChange: {
                              create: {
                                by: { connect: { id: me.id } },
                                comment: getComment,
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                })
              }
            >
              Modification relative
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <SpacedDivider />
      <CardContent>
        <MaterialTable
          title="Entrées/Sorties"
          data={(pageData.stockSubChanges || []).map((change) => ({
            changeType: change.changeType,
            createdAt: new Date(change.stockChange.createdAt),
            value: change.value,
            comment: change.stockChange.comment,
          }))}
          columns={[
            {
              title: "Date",
              field: "createdAt",
              render: ({ createdAt }) => humanReadableDate(createdAt),
            },
            { title: "Value", field: "value" },
            {
              title: "Type de changement",
              field: "changeType",
              lookup: {
                SET: "Modification absolue",
                CHANGE: "Modification relative",
              },
            },
            { title: "Commentaire", field: "comment" },
          ]}
        />
      </CardContent>
    </Card>
  );
};
