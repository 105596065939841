import {
  FirmFragment,
  LocationFragment,
  LocationType,
  ProductFragment,
} from "../Generated/graphql";

export type productPricesIdsType = ProductFragment & {
  currentPriceId: number;
  label: string;
};

export const getProductPricesIds = (
  products: ProductFragment[],
  locationType: LocationType,
): productPricesIdsType[] =>
  products.reduce<productPricesIdsType[]>((prev, current) => {
    const currentPriceId = current.productPrices?.find(
      (price) => price.locationType === locationType,
    )?.id;
    return currentPriceId !== undefined
      ? [
          ...prev,
          {
            ...current,
            currentPriceId,
            label: current.category?.name
              ? `${current.category.name} - ${current.name}`
              : current.name,
          },
        ]
      : prev;
  }, []);

export const getLocationsFromFirms = (
  firms: FirmFragment[],
): LocationFragment[] =>
  firms.reduce<LocationFragment[]>((prev, current) => {
    return [
      ...prev,
      ...(current.locations?.map((location) => ({
        ...location,
        name: `${current.name} - ${location.name}`,
      })) || []),
    ];
  }, []);

export const getLocationSelectFromFirms = (
  firms: FirmFragment[],
): { label: string; value: number }[] =>
  getLocationsFromFirms(firms).map((location) => ({
    label: location.name,
    value: location.id,
  }));
