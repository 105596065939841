import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/Locations/MyCard";
import { LocationFragment } from "../../Generated/graphql";

export const Locations: React.FC = () => {
  const { firms, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CardList
      data={firms.reduce<LocationFragment[]>((prev, current) => {
        return [
          ...prev,
          ...(current.locations?.map(
            (location): LocationFragment => ({
              ...location,
              name: `${current.name} - ${location.name}`,
            }),
          ) || []),
        ];
      }, [])}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.disabled || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
        {
          path: "/locations/create",
          text: "Créer une nouvelle localisation",
        },
      ]}
    />
  );
};
