import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import {
  NonInputableField,
  SpacedDivider,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import {
  StockCreateInput,
  useCreateStockMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";

export const Create: React.FC = () => {
  const { stocks } = useData();
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateStockMutation({
    update: (_, { data }) => {
      setRedirect(data?.createStock.id);
    },
  });
  const formik = useFormik<StockCreateInput>({
    initialValues: {
      name: "",
    },
    onSubmit: (data) =>
      createMutation({
        variables: {
          data: {
            ...data,
            stockSubActions: {
              create: [
                {
                  delta: 1,
                  stockAction: { create: { name: `Burnet - ${data.name}` } },
                },
                {
                  delta: 1,
                  stockAction: { create: { name: `Modifier - ${data.name}` } },
                },
                {
                  delta: -1,
                  stockAction: { create: { name: `Vente - ${data.name}` } },
                },
              ],
            },
          },
        },
      }),
  });

  if (getRedirect) return <Redirect to={`/stocks/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader subheader="Ajouter une stock" title="Stock" />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                label="Alternative"
                options={stocks
                  .filter((stock) => !stock.disabled)
                  .map((stock) => ({
                    label: stock.name,
                    value: stock.id,
                  }))}
                currentValue={formik.values.fallback?.connect?.id}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value || value === 0) {
                      oldValues.fallback = { connect: { id: value } };
                      return oldValues;
                    } else {
                      delete oldValues.fallback;
                      return oldValues;
                    }
                  })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer le stock
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
