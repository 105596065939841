import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { MyCard } from "../../Components/StockActions/MyCard";
import { useData } from "../../Provider/Data";

export const StockActions: React.FC = () => {
  const { stockActions, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CardList
      data={stockActions}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.hidden || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
        { path: "/stock-actions/create", text: "Créer un type d'action" },
      ]}
    />
  );
};
