import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardActionArea,
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { StyledLink } from "@bjelos-farm/lib-client-common";
import { LocationFragment } from "../../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.error.main,
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  data: LocationFragment;
}

export const MyCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid item lg={4} md={6} xs={12}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {data.name}
          </Typography>
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        <StyledLink to={`/locations/${data.id}`}>
          <CardActionArea>
            <Box p={2}>
              <Grid container justify="center" spacing={2}>
                <Grid className={classes.statsItem} item>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    Ouvrir
                  </Typography>
                  <Launch className={classes.statsIcon} color="action" />
                </Grid>
              </Grid>
            </Box>
          </CardActionArea>
        </StyledLink>
      </Card>
    </Grid>
  );
};
