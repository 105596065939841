import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
  SingleSelect,
  MyTimePicker,
} from "@bjelos-farm/lib-client-common";
import { textFromDay, OrderedDays } from "@bjelos-farm/lib-common-tools";
import {
  CallScheduleUpdateInput,
  useUpdateCallScheduleMutation,
  useCallScheduleQuery,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { getLocationSelectFromFirms } from "../../Utils/FormatModificators";
import { humanReadableTimeEpoch, midDay } from "@bjelos-farm/lib-common-tools";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { me, firms, refetch } = useData();
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const { data, loading } = useCallScheduleQuery({
    variables: { where: { id: numId } },
  });

  const locationList = getLocationSelectFromFirms(firms);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateMutation] = useUpdateCallScheduleMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<CallScheduleUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.callSchedule;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData || !me) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      day: { set: pageData.day },
      deliveryDayAddition: { set: pageData.deliveryDayAddition },
      deliveryTime: { set: pageData.deliveryTime },
      location: { connect: { id: pageData.location.id } },
      comment: { set: pageData.comment },
    });
  };

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title="Planning"
          action={
            <>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Jour d'appel"
                  options={OrderedDays.map((day) => ({
                    label: textFromDay(day),
                    value: day,
                  }))}
                  currentValue={formik.values.day?.set}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.day = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={textFromDay(pageData.day)}
                  label="Jour d'appel"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                type="number"
                name="deliveryDayAddition.set"
                value={
                  getEditable
                    ? formik.values.deliveryDayAddition?.set
                    : pageData.deliveryDayAddition
                }
                onChange={formik.handleChange}
                label="Delta jour de livraison"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <MyTimePicker
                  onChange={(value) =>
                    formik.setValues((oldValues) => {
                      if (value) {
                        oldValues.deliveryTime = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                  value={formik.values.deliveryTime?.set || midDay}
                  label="Heure de livraison"
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={humanReadableTimeEpoch(pageData.deliveryTime)}
                  label="Localisation"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Localisation"
                  options={locationList}
                  currentValue={formik.values.location?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.location = { connect: { id: value } };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={
                    locationList.find(
                      (location) => location.value === pageData.location.id,
                    )?.label || "Aucun"
                  }
                  label="Localisation"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="comment.set"
                value={
                  getEditable ? formik.values.comment?.set : pageData.comment
                }
                onChange={formik.handleChange}
                label="Commentaire"
                multiline={true}
              />
            </Grid>
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
      <SpacedDivider />
    </Card>
  );
};
