import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import { getDayEU, textFromDay } from "@bjelos-farm/lib-common-tools";
import { formatPrice, MyDatePicker } from "@bjelos-farm/lib-client-common";
import {
  OrderStatus,
  useCustomNumbersQueryLazyQuery,
  NumberTypeFragment,
} from "../Generated/graphql";
import { ExposureNeg1, PlusOne } from "@material-ui/icons";

export const Numbers: React.FC = () => {
  const [query, { data, loading }] = useCustomNumbersQueryLazyQuery();
  const [getDate, setDate] = React.useState<Date>(new Date());
  const [getDateTo, setDateTo] = React.useState<Date>(new Date());

  const orders: NumberTypeFragment[] = data?.orders || [];

  const day = getDayEU(getDate);
  const dayTo = getDayEU(getDateTo);

  React.useEffect(() => {
    const fromDate = new Date(getDate.valueOf());
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(getDateTo.valueOf());
    toDate.setHours(0, 0, 0, 0);
    query({
      variables: {
        where: {
          when: { gte: fromDate, lte: toDate },
          status: { not: { equals: OrderStatus.Canceled } },
        },
      },
    });
  }, [getDate, getDateTo, query]);

  if (loading) {
    return <p>Chargement</p>;
  }

  const getCartPrice = (
    cartItems: { qty: number; productPrice: { price: number } }[] = [],
  ) => {
    const price = cartItems.reduce<number>((prev, item) => {
      return prev + item.productPrice.price * item.qty;
    }, 0);
    return price;
  };

  const facture = orders.reduce(
    (a, b) => a + getCartPrice(b.cart.cartItems ?? []),
    0,
  );

  return (
    <>
      <Typography variant="h2">De: {textFromDay(day)}</Typography>
      <div>
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() - 1);
            setDate(tempDate);
          }}
        >
          <ExposureNeg1 color="primary" />
        </IconButton>
        <MyDatePicker value={getDate} onChange={setDate} />
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDate.valueOf());
            tempDate.setDate(getDate.getDate() + 1);
            setDate(tempDate);
          }}
        >
          <PlusOne color="primary" />
        </IconButton>
      </div>
      <Typography variant="h2">A: {textFromDay(dayTo)}</Typography>
      <div>
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDateTo.valueOf());
            tempDate.setDate(getDateTo.getDate() - 1);
            setDateTo(tempDate);
          }}
        >
          <ExposureNeg1 color="primary" />
        </IconButton>
        <MyDatePicker value={getDateTo} onChange={setDateTo} />
        <IconButton
          onClick={() => {
            const tempDate = new Date(getDateTo.valueOf());
            tempDate.setDate(getDateTo.getDate() + 1);
            setDateTo(tempDate);
          }}
        >
          <PlusOne color="primary" />
        </IconButton>
      </div>
      <p>Chiffre de la période : {formatPrice(facture)}</p>
    </>
  );
};
