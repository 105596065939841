import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import {
  ProductUpdateInput,
  useUpdateProductMutation,
  useProductQuery,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { textFromLocationType } from "../../Utils/enumToText";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { productCategories, stockActions, refetch } = useData();
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const { data, loading } = useProductQuery({
    variables: { where: { id: numId } },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateMutation] = useUpdateProductMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<ProductUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.product;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      name: { set: pageData.name },
      subname: { set: pageData.subname },
      order: { set: pageData.order },
      disabled: { set: pageData.disabled },
      category: pageData.categoryId
        ? { connect: { id: pageData.categoryId } }
        : {},
      stockAction: pageData.stockActionId
        ? { connect: { id: pageData.stockActionId } }
        : {},
      productPrices: {
        update: pageData.productPrices?.map(({ id, locationType, price }) => ({
          where: { id },
          data: { locationType: { set: locationType }, price: { set: price } },
        })),
      },
    });
  };

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title="Produit"
          action={
            <>
              <IconButton
                disabled={!getEditable}
                onClick={() =>
                  formik.setValues((oldValues) => {
                    oldValues.disabled = { set: !oldValues.disabled?.set };
                    return oldValues;
                  })
                }
              >
                {(
                  getEditable ? formik.values.disabled?.set : pageData.disabled
                ) ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="name.set"
                value={getEditable ? formik.values.name?.set : pageData.name}
                onChange={formik.handleChange}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="subname.set"
                value={
                  getEditable ? formik.values.subname?.set : pageData.subname
                }
                onChange={formik.handleChange}
                label="Sous nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="order.set"
                type="number"
                value={getEditable ? formik.values.order?.set : pageData.order}
                onChange={formik.handleChange}
                label="Ordre"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  label="Catégorie"
                  options={productCategories.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                  currentValue={formik.values.category?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.category = { connect: { id: value } };
                        return oldValues;
                      } else {
                        oldValues.category = { disconnect: true };
                        return oldValues;
                      }
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={
                    productCategories.find(
                      (category) => category.id === pageData.categoryId,
                    )?.name || "Aucun"
                  }
                  label="Catégorie"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Action sur stock"
                  options={stockActions.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                  currentValue={formik.values.stockAction?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.stockAction = { connect: { id: value } };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={
                    stockActions.find(
                      (stockAction) =>
                        stockAction.id === pageData.stockActionId,
                    )?.name || "Aucun"
                  }
                  label="Catégorie"
                />
              )}
            </Grid>
          </Grid>
          <SpacedDivider space={10} />
          <Typography variant="h4" gutterBottom>
            Prix EN CENTIME (Pas éditable pour l'instant, ça arrive)
          </Typography>
          <Grid container spacing={3}>
            {getEditable && false
              ? formik.values.productPrices?.update?.map(
                  (productPrice, index) => (
                    <Grid item md={6} xs={12} key={index}>
                      <NonInputableField
                        name={`productPrices.update[${index}].data.price.set`}
                        type="number"
                        onChange={formik.handleChange}
                        value={productPrice.data.price?.set}
                        label={textFromLocationType(
                          productPrice.data.locationType?.set || undefined,
                        )}
                      />
                    </Grid>
                  ),
                )
              : pageData.productPrices?.map((productPrice, index) => (
                  <Grid item md={6} xs={12} key={index}>
                    <NonInputableField
                      disabled={true}
                      value={productPrice.price}
                      label={textFromLocationType(productPrice.locationType)}
                    />
                  </Grid>
                ))}
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
    </Card>
  );
};
