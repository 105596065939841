import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardActionArea,
  Button,
} from "@material-ui/core";
import { Launch, VisibilityOff } from "@material-ui/icons";
import {
  StyledLink,
  SpacedDivider,
  NonInputableField,
} from "@bjelos-farm/lib-client-common";
import {
  StockActionFragment,
  useApplyStockActionMutation,
} from "../../Generated/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.error.main,
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  data: StockActionFragment;
}

export const MyCard: React.FC<Props> = ({ data }) => {
  const [getCustomValue, setCustomValue] = React.useState<number>(0);
  const classes = useStyles();
  const [updateMutation] = useApplyStockActionMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  return (
    <Grid item lg={4} md={6} xs={12}>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {data.name} {data.hidden && <VisibilityOff />}
          </Typography>
          <SpacedDivider space={20} />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {data.presetValues?.map((presetValue, index) => (
              <Button
                variant="contained"
                key={index}
                onClick={() =>
                  updateMutation({
                    variables: { data: { id: data.id, value: presetValue } },
                  })
                }
              >
                {presetValue}
              </Button>
            ))}
          </Grid>
          <SpacedDivider space={20} />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <NonInputableField
              value={getCustomValue}
              type="number"
              onChange={(value) => setCustomValue(parseInt(value.target.value))}
              label="Sur mesure"
            />
            <Button
              variant="contained"
              onClick={() =>
                updateMutation({
                  variables: { data: { id: data.id, value: getCustomValue } },
                })
              }
            >
              Valeur sur mesure
            </Button>
          </Grid>
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        <StyledLink to={`/stock-actions/${data.id}`}>
          <CardActionArea>
            <Box p={2}>
              <Grid container justify="center" spacing={2}>
                <Grid className={classes.statsItem} item>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    Ouvrir
                  </Typography>
                  <Launch className={classes.statsIcon} color="action" />
                </Grid>
              </Grid>
            </Box>
          </CardActionArea>
        </StyledLink>
      </Card>
    </Grid>
  );
};
