import React from "react";
import {
  CardListWithCategories,
  looseMatch,
} from "@bjelos-farm/lib-client-common";
import { textFromDay, OrderedDays } from "@bjelos-farm/lib-common-tools";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/CallSchedules/MyCard";
import { CallScheduleFragment } from "../../Generated/graphql";

export const CallSchedules: React.FC = () => {
  const { callSchedules, refetch } = useData();

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const categories: {
    name: string;
    data: CallScheduleFragment[];
  }[] = OrderedDays.map((day) => ({
    name: textFromDay(day),
    data: callSchedules.filter((callSchedule) => callSchedule.day === day),
  }));

  return (
    <CardListWithCategories
      paginationBase={12}
      categories={categories}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter((element) => looseMatch(filter, element.location.name))
      }
      buttons={[
        { path: "/call-schedules/create", text: "Créer un nouvel appel" },
      ]}
    />
  );
};
