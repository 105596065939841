import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/Firms/MyCard";

export const Firms: React.FC = () => {
  const { firms, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CardList
      paginationBase={12}
      data={firms}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.disabled || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Montrer cachés" : "Cacher désactivés",
        },
        { path: "/firms/create", text: "Créer une nouvelle firme" },
      ]}
    />
  );
};
