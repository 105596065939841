import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
} from "@bjelos-farm/lib-client-common";
import {
  FirmUpdateInput,
  useUpdateFirmMutation,
  useFirmQuery,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { me, refetch } = useData();
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const { data, loading } = useFirmQuery({
    variables: { where: { id: numId } },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateMutation] = useUpdateFirmMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<FirmUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.firm;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData || !me) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      name: { set: pageData.name },
      billingAddress: { set: pageData.billingAddress },
    });
  };

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title="Catégorie de produit"
          action={
            <>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="name.set"
                value={getEditable ? formik.values.name?.set : pageData.name}
                onChange={formik.handleChange}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                multiline={true}
                disabled={!getEditable}
                name="billingAddress.set"
                value={
                  getEditable
                    ? formik.values.billingAddress?.set
                    : pageData.billingAddress
                }
                onChange={formik.handleChange}
                label="Addresse de facturation"
              />
            </Grid>
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
      <SpacedDivider />
    </Card>
  );
};
