import React, { createContext, useContext } from "react";
import { useMaster } from "@bjelos-farm/lib-client-common";
import {
  FirmFragment,
  StockFragment,
  UserFragment,
  useGetAllQuery,
  ProductCategoryFragment,
  ProductFragment,
  StockActionFragment,
  CallScheduleFragment,
} from "../Generated/graphql";

type DataContextType = {
  firms: FirmFragment[];
  stocks: StockFragment[];
  productCategories: ProductCategoryFragment[];
  products: ProductFragment[];
  stockActions: StockActionFragment[];
  callSchedules: CallScheduleFragment[];
  me: UserFragment;
  refetch: () => void;
};

const DataDefinition = createContext<DataContextType | undefined>(undefined);

export const useData = () => useContext(DataDefinition)!;

export const DataProvider: React.FC = ({ children }) => {
  const { getJwt, getErrors } = useMaster();
  const { data, refetch } = useGetAllQuery({
    skip: !getJwt || getErrors.length !== 0,
  });
  if (!data?.firms) return <></>;
  return (
    <DataDefinition.Provider
      value={{
        firms: data.firms,
        stocks: data.stocks,
        productCategories: data.productCategories,
        me: data.me,
        products: data.products,
        stockActions: data.stockActions,
        callSchedules: data.callSchedules,
        refetch,
      }}
    >
      {children}
    </DataDefinition.Provider>
  );
};
