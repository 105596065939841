import React from "react";
import { useMaster, NavBar, Login } from "@bjelos-farm/lib-client-common";
import logo from "@bjelos-farm/lib-client-assets/assets/logo.png";
import { TopBar } from "./TopBar";
import { routes } from "../routes";
import { useMainStyle } from "../Styles/layout";

export const Dashboard: React.FC = ({ children }) => {
  const { getJwt } = useMaster();
  const classes = useMainStyle();
  const [isNavOpen, setNavOpen] = React.useState(false);
  return (
    <div className={classes.root}>
      <TopBar
        navToggle={() => setNavOpen((navOpen) => !navOpen)}
        logoUrl={logo}
      />
      {getJwt && (
        <NavBar
          onClose={() => setNavOpen(false)}
          open={isNavOpen}
          routes={routes}
        />
      )}
      <div
        className={
          getJwt && !isNavOpen ? classes.wrapper : classes.centeredWrapper
        }
      >
        <div className={classes.content}>{!getJwt ? <Login /> : children}</div>
      </div>
    </div>
  );
};
