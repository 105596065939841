import {
  LocationType,
  OrderMethod,
  PaymentType,
  StickerColor,
} from "../Generated/graphql";

export const textFromLocationType = (input?: LocationType): string => {
  switch (input) {
    case LocationType.Enduser:
      return "Client privé";
    case LocationType.Restaurateur:
      return "Restaurateur";
    case LocationType.Retailer:
      return "Revendeur";
    default:
      return "Y'a eu un soucis";
  }
};

export const textFromOrderMethod = (input?: OrderMethod): string => {
  switch (input) {
    case OrderMethod.Normal:
      return "De rien";
    case OrderMethod.FromBasis:
      return "Depuis la base";
    case OrderMethod.Permanent:
      return "Stable";
    default:
      return "Y'a eu un soucis";
  }
};

export const textFromStickerColor = (input?: StickerColor): string => {
  switch (input) {
    case StickerColor.Red:
      return "Rouge";
    case StickerColor.Green:
      return "Vert";
    case StickerColor.None:
      return "Aucun";
    default:
      return "Y'a eu un soucis";
  }
};

export const textFromPaymentType = (input?: PaymentType): string => {
  switch (input) {
    case PaymentType.Bank:
      return "Banque";
    case PaymentType.Cash:
      return "Cash";
    default:
      return "Y'a eu un soucis";
  }
};
