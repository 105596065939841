import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import {
  NonInputableField,
  SpacedDivider,
} from "@bjelos-farm/lib-client-common";
import {
  FirmCreateInput,
  useCreateFirmMutation,
} from "../../Generated/graphql";

export const Create: React.FC = () => {
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateFirmMutation({
    update: (_, { data }) => {
      setRedirect(data?.createFirm.id);
    },
  });
  const formik = useFormik<FirmCreateInput>({
    initialValues: {
      name: "",
      billingAddress: "",
    },
    onSubmit: (data) =>
      createMutation({
        variables: { data },
      }),
  });

  if (getRedirect) return <Redirect to={`/firms/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader subheader="Ajouter une firme" title="Firme" />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                multiline={true}
                name="billingAddress"
                onChange={formik.handleChange}
                value={formik.values.billingAddress}
                label="Addresse de facturation"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer la firme
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
