import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  NonInputableField,
  SingleSelect,
  SpacedDivider,
} from "@bjelos-farm/lib-client-common";
import {
  OrderMethod,
  StickerColor,
  LocationType,
  LocationCreateInput,
  useCreateLocationMutation,
  PaymentType,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import {
  textFromLocationType,
  textFromOrderMethod,
  textFromPaymentType,
  textFromStickerColor,
} from "../../Utils/enumToText";
import { Delete, PlusOne } from "@material-ui/icons";
import { removeFromArray } from "@bjelos-farm/lib-common-tools";
import { getProductPricesIds } from "../../Utils/FormatModificators";

export const Create: React.FC = () => {
  const { firms, products } = useData();
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [getGenerateFirm, setGenerateFirm] = React.useState<boolean>(false);
  const [createMutation] = useCreateLocationMutation({
    update: (_, { data }) => {
      setRedirect(data?.createLocation.id);
    },
  });
  const formik = useFormik<LocationCreateInput>({
    initialValues: {
      name: "",
      address: "",
      comment: "",
      locationType: LocationType.Retailer,
      firm: { connect: { id: firms[0].id } },
      orderMethod: OrderMethod.Normal,
      stickerColor: StickerColor.Red,
      printBarcode: false,
      printDate: true,
      monthly: false,
      send: false,
      paymentType: PaymentType.Cash,
      phones: { set: [] },
      fullStock: { create: { cartItems: { create: [] } } },
    },
    onSubmit: (data) => {
      createMutation({
        variables: {
          data: getGenerateFirm
            ? {
                ...data,
                firm: { create: { name: data.name, billingAddress: "" } },
              }
            : data,
        },
      });
    },
  });

  const productPricesIds = getProductPricesIds(
    products,
    formik.values.locationType,
  );

  if (getRedirect) return <Redirect to={`/locations/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader subheader="Ajouter une localisation" title="Localisation" />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="printBarcode"
                    checked={getGenerateFirm}
                    onChange={() => setGenerateFirm(!getGenerateFirm)}
                    color="primary"
                  />
                }
                label="Générer une firme"
              />
            </Grid>
            {getGenerateFirm ? (
              <Grid item md={4} xs={12}>
                <NonInputableField
                  name="name"
                  disabled={true}
                  value="Autogénérée"
                  label="Firme"
                />
              </Grid>
            ) : (
              <Grid item md={4} xs={12}>
                <SingleSelect
                  disableClearable={true}
                  label="Firme"
                  options={firms.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                  currentValue={formik.values.firm?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.firm = { connect: { id: value } };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <NonInputableField
                multiline={true}
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                label="Addresse"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                multiline={true}
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
                label="Commentaire"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Type de localisation"
                options={[
                  {
                    label: textFromLocationType(LocationType.Retailer),
                    value: LocationType.Retailer,
                  },
                  {
                    label: textFromLocationType(LocationType.Restaurateur),
                    value: LocationType.Restaurateur,
                  },
                  {
                    label: textFromLocationType(LocationType.Enduser),
                    value: LocationType.Enduser,
                  },
                ]}
                currentValue={formik.values.locationType}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value) {
                      oldValues.locationType = value;
                      oldValues.fullStock = {
                        create: {
                          cartItems: {
                            create: [],
                          },
                        },
                      };
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Initialisation du cart"
                options={[
                  {
                    label: textFromOrderMethod(OrderMethod.FromBasis),
                    value: OrderMethod.FromBasis,
                  },
                  {
                    label: textFromOrderMethod(OrderMethod.Normal),
                    value: OrderMethod.Normal,
                  },
                  {
                    label: textFromOrderMethod(OrderMethod.Permanent),
                    value: OrderMethod.Permanent,
                  },
                ]}
                currentValue={formik.values.orderMethod}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value) {
                      oldValues.orderMethod = value;
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Couleur d'étiquette"
                options={[
                  {
                    label: textFromStickerColor(StickerColor.Red),
                    value: StickerColor.Red,
                  },
                  {
                    label: textFromStickerColor(StickerColor.Green),
                    value: StickerColor.Green,
                  },
                  {
                    label: textFromStickerColor(StickerColor.None),
                    value: StickerColor.None,
                  },
                ]}
                currentValue={formik.values.stickerColor}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value) {
                      oldValues.stickerColor = value;
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="printBarcode"
                    checked={formik.values.printBarcode ?? false}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Codebar"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="printDate"
                    checked={formik.values.printDate ?? false}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Date"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="monthly"
                    checked={formik.values.monthly ?? false}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Paiement mensuel"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="send"
                    checked={formik.values.send ?? false}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Envoie par la poste"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Type de paiement"
                options={[
                  {
                    label: textFromPaymentType(PaymentType.Bank),
                    value: PaymentType.Bank,
                  },
                  {
                    label: textFromPaymentType(PaymentType.Cash),
                    value: PaymentType.Cash,
                  },
                ]}
                currentValue={formik.values.paymentType}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value) {
                      oldValues.paymentType = value;
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Numéros de téléphone
          </Typography>
          <Grid container spacing={3}>
            {formik.values.phones?.set.map((phone, index) => (
              <React.Fragment key={index}>
                <Grid item md={5} xs={12}>
                  <NonInputableField
                    name={`phones.set[${index}]`}
                    onChange={formik.handleChange}
                    value={phone}
                    label="numéro"
                  />
                </Grid>
                <Grid item md={1} xs={12}>
                  <IconButton
                    onClick={() =>
                      formik.setValues((values) => {
                        if (values.phones?.set) {
                          values.phones.set = removeFromArray(
                            values.phones.set,
                            index,
                          );
                        }
                        return values;
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item md={6} xs={12}>
              <IconButton
                onClick={() =>
                  formik.setValues((values) => {
                    values.phones?.set.push("");
                    return values;
                  })
                }
              >
                <PlusOne />
              </IconButton>
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Stock de base
          </Typography>
          <Grid container spacing={3}>
            {formik.values.fullStock?.create?.cartItems?.create?.map(
              (cartItem, index) => (
                <React.Fragment key={index}>
                  <Grid item md={3} xs={12}>
                    <SingleSelect
                      disableClearable={true}
                      label="Produit"
                      options={productPricesIds.map((productWithId) => ({
                        label: productWithId.label,
                        value: productWithId.currentPriceId,
                      }))}
                      currentValue={cartItem.productPrice.connect?.id}
                      setNewValue={(value) =>
                        formik.setValues((oldValues) => {
                          if (
                            (value || value === 0) &&
                            oldValues.fullStock.create?.cartItems?.create &&
                            oldValues.fullStock.create?.cartItems?.create[index]
                              .productPrice.connect?.id
                          ) {
                            oldValues.fullStock.create.cartItems.create[
                              index
                            ].productPrice.connect = { id: value };
                            return oldValues;
                          }
                          return oldValues;
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <NonInputableField
                      type="number"
                      name={`fullStock.create.cartItems.create[${index}].qty`}
                      onChange={formik.handleChange}
                      value={cartItem.qty}
                      label="Quantité"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <NonInputableField
                      name={`fullStock.create.cartItems.create[${index}].comment`}
                      onChange={formik.handleChange}
                      value={cartItem.comment}
                      label="Commentaire"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`fullStock.create.cartItems.create[${index}].bulk`}
                          checked={cartItem.bulk}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      }
                      label="En vrac"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <IconButton
                      onClick={() =>
                        formik.setValues((values) => {
                          if (values.fullStock?.create?.cartItems?.create) {
                            values.fullStock.create.cartItems.create = removeFromArray(
                              values.fullStock.create.cartItems.create,
                              index,
                            );
                          }
                          return values;
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ),
            )}
            <Grid item md={6} xs={12}>
              <IconButton
                onClick={() =>
                  formik.setValues((values) => {
                    values.fullStock?.create?.cartItems?.create?.push({
                      bulk: false,
                      productPrice: {
                        connect: { id: productPricesIds[0].currentPriceId },
                      },
                      qty: 0,
                      comment: "",
                    });
                    return values;
                  })
                }
              >
                <PlusOne />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer la localisation
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
