import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import {
  MyTimePicker,
  NonInputableField,
  SingleSelect,
  SpacedDivider,
} from "@bjelos-farm/lib-client-common";
import { textFromDay, OrderedDays } from "@bjelos-farm/lib-common-tools";
import {
  CallScheduleCreateInput,
  useCreateCallScheduleMutation,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import { getLocationSelectFromFirms } from "../../Utils/FormatModificators";

export const Create: React.FC = () => {
  const { firms } = useData();
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateCallScheduleMutation({
    update: (_, { data }) => {
      setRedirect(data?.createCallSchedule.id);
    },
  });

  const locationList = getLocationSelectFromFirms(firms);

  const formik = useFormik<CallScheduleCreateInput>({
    initialValues: {
      day: OrderedDays[0],
      deliveryDayAddition: 1,
      deliveryTime: 1000 * 3600 * 8,
      location: { connect: { id: locationList[0].value } },
      comment: "",
    },
    onSubmit: (data) =>
      createMutation({
        variables: { data },
      }),
  });

  if (getRedirect) return <Redirect to={`/call-schedules/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader subheader="Ajouter un appel" title="Planning" />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Jour d'appel"
                options={OrderedDays.map((day) => ({
                  label: textFromDay(day),
                  value: day,
                }))}
                currentValue={formik.values.day}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value || value === 0) {
                      oldValues.day = value;
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="deliveryDayAddition"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.deliveryDayAddition}
                label="Delta jour de livraison"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MyTimePicker
                onChange={(value) =>
                  formik.setValues((oldValues) => {
                    if (value) {
                      oldValues.deliveryTime = value;
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
                value={formik.values.deliveryTime}
                label="Heure de livraison"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SingleSelect
                disableClearable={true}
                label="Localisation"
                options={locationList}
                currentValue={formik.values.location?.connect?.id}
                setNewValue={(value) =>
                  formik.setValues((oldValues) => {
                    if (value || value === 0) {
                      oldValues.location = { connect: { id: value } };
                      return oldValues;
                    }
                    return oldValues;
                  })
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                multiline={true}
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
                label="Commentaire"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Ajouter au planning
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
