import React from "react";
import {
  CardListWithCategories,
  looseMatch,
} from "@bjelos-farm/lib-client-common";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/Products/MyCard";
import { ProductFragment } from "../../Generated/graphql";

export const Products: React.FC = () => {
  const { products, productCategories, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const categories: {
    name: string;
    data: ProductFragment[];
  }[] = productCategories.map((category) => ({
    name: category.name,
    data: category.products || [],
  }));

  const otherProducts: {
    name: string;
    data: ProductFragment[];
  } = {
    name: "Autres",
    data: products.filter((product) => product.categoryId === null),
  };

  return (
    <CardListWithCategories
      categories={[...categories, otherProducts]}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.disabled || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
        {
          path: "/products/create",
          text: "Créer un nouveau produit",
        },
      ]}
    />
  );
};
