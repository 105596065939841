import React from "react";
import ReactDOM from "react-dom";
import {
  MasterProvider,
  Router,
  serviceWorkerRegistration,
  reportWebVitals,
} from "@bjelos-farm/lib-client-common";
import { env } from "./env";
import { Dashboard } from "./Layout/Dashboard";
import { DataProvider } from "./Provider/Data";
import { routes } from "./routes";

ReactDOM.render(
  <React.StrictMode>
    <MasterProvider uri={env.REACT_APP_API_URL}>
      <Dashboard>
        <DataProvider>
          <Router routes={routes} />
        </DataProvider>
      </Dashboard>
    </MasterProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorkerRegistration.register();
reportWebVitals();
