import React from "react";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import {
  NonInputableField,
  SpacedDivider,
} from "@bjelos-farm/lib-client-common";
import {
  ProductCategoryCreateInput,
  useCreateProductCategoryMutation,
} from "../../Generated/graphql";

export const Create: React.FC = () => {
  const [getRedirect, setRedirect] = React.useState<number | undefined>();
  const [createMutation] = useCreateProductCategoryMutation({
    update: (_, { data }) => {
      setRedirect(data?.createProductCategory.id);
    },
  });
  const formik = useFormik<ProductCategoryCreateInput>({
    initialValues: {
      name: "",
      order: 0,
    },
    onSubmit: (data) =>
      createMutation({
        variables: { data },
      }),
  });

  if (getRedirect)
    return <Redirect to={`/product-categories/${getRedirect}`} />;
  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          subheader="Ajouter une catégorie de produit"
          title="Catégorie de produit"
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                name="order"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.order}
                label="Ordre"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ marginLeft: "auto" }}
          >
            Créer la catégorie de produit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
