import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/Stocks/MyCard";

export const Stocks: React.FC = () => {
  const { stocks, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CardList
      data={stocks}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter(
          (element) =>
            (!element.disabled || getShowDisabled) &&
            looseMatch(filter, element.name),
        )
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
        { path: "/stocks/create", text: "Créer un nouveau stock" },
      ]}
    />
  );
};
