import React from "react";
import { CardList, looseMatch } from "@bjelos-farm/lib-client-common";
import { useData } from "../../Provider/Data";
import { MyCard } from "../../Components/ProductCategories/MyCard";

export const ProductCategories: React.FC = () => {
  const { productCategories, refetch } = useData();
  const [getShowDisabled, setShowDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CardList
      data={productCategories}
      CardComponent={MyCard}
      filter={(array, filter) =>
        array.filter((element) => looseMatch(filter, element.name))
      }
      buttons={[
        {
          action: () => {
            setShowDisabled(!getShowDisabled);
          },
          text: getShowDisabled ? "Cacher cachés" : "Montrer désactivés",
        },
        {
          path: "/product-categories/create",
          text: "Créer une nouvelle catégorie",
        },
      ]}
    />
  );
};
