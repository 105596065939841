import { RouteDefinitionType } from "@bjelos-farm/lib-client-common";
import {
  AccountBalance,
  Storage,
  TrendingUp,
  Kitchen,
  Category,
  LocationOn,
  Phone,
} from "@material-ui/icons";

import { Stocks } from "./Pages/Stocks";
import { Create as StockCreate } from "./Pages/Stocks/Create";
import { Show as StockShow } from "./Pages/Stocks/Show";

import { StockActions } from "./Pages/StockActions";
import { Create as StockActionCreate } from "./Pages/StockActions/Create";
import { Show as StockActionShow } from "./Pages/StockActions/Show";

import { ProductCategories } from "./Pages/ProductCategories";
import { Create as ProductCategoryCreate } from "./Pages/ProductCategories/Create";
import { Show as ProductCategoryShow } from "./Pages/ProductCategories/Show";

import { Products } from "./Pages/Products";
import { Create as ProductCreate } from "./Pages/Products/Create";
import { Show as ProductShow } from "./Pages/Products/Show";

import { Firms } from "./Pages/Firms";
import { Create as FirmCreate } from "./Pages/Firms/Create";
import { Show as FirmShow } from "./Pages/Firms/Show";

import { Locations } from "./Pages/Locations";
import { Create as LocationCreate } from "./Pages/Locations/Create";
import { Show as LocationShow } from "./Pages/Locations/Show";

import { CallSchedules } from "./Pages/CallSchedules";
import { Create as CallScheduleCreate } from "./Pages/CallSchedules/Create";
import { Show as CallScheduleShow } from "./Pages/CallSchedules/Show";

import { Numbers } from "./Pages/Numbers";

export const routes: RouteDefinitionType[] = [
  {
    icon: Storage,
    mainComponent: Stocks,
    path: "/stocks",
    title: "Stocks",
    subRoutes: [
      { path: "/create", component: StockCreate },
      { path: "/:id", component: StockShow },
    ],
  },
  {
    icon: TrendingUp,
    mainComponent: StockActions,
    path: "/stock-actions",
    title: "Actions sur stock",
    subRoutes: [
      { path: "/create", component: StockActionCreate },
      { path: "/:id", component: StockActionShow },
    ],
  },
  {
    icon: Category,
    mainComponent: ProductCategories,
    path: "/product-categories",
    title: "Catégories de produit",
    subRoutes: [
      { path: "/create", component: ProductCategoryCreate },
      { path: "/:id", component: ProductCategoryShow },
    ],
  },
  {
    icon: Kitchen,
    mainComponent: Products,
    path: "/products",
    title: "Produits",
    subRoutes: [
      { path: "/create", component: ProductCreate },
      { path: "/:id", component: ProductShow },
    ],
  },
  {
    icon: AccountBalance,
    mainComponent: Firms,
    path: "/firms",
    title: "Firmes",
    subRoutes: [
      { path: "/create", component: FirmCreate },
      { path: "/:id", component: FirmShow },
    ],
  },
  {
    icon: LocationOn,
    mainComponent: Locations,
    path: "/locations",
    title: "Localisations",
    subRoutes: [
      { path: "/create", component: LocationCreate },
      { path: "/:id", component: LocationShow },
    ],
  },
  {
    icon: Phone,
    mainComponent: CallSchedules,
    path: "/call-schedules",
    title: "Planning",
    subRoutes: [
      { path: "/create", component: CallScheduleCreate },
      { path: "/:id", component: CallScheduleShow },
    ],
  },
  {
    icon: AccountBalance,
    mainComponent: Numbers,
    path: "/numbers",
    title: "Chiffres",
    subRoutes: [],
  },
];
