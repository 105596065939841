import React from "react";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { Menu, NightsStay, WbSunny } from "@material-ui/icons";
import { useTopBarStyles } from "../Styles/layout";
import { StyledLink, useMaster } from "@bjelos-farm/lib-client-common";

interface Props {
  logoUrl: string;
  navToggle?: () => void;
}

export const TopBar: React.FC<Props> = ({ logoUrl, navToggle }) => {
  const { getDark, setDark } = useMaster();
  const classes = useTopBarStyles();
  return (
    <AppBar elevation={0}>
      <Toolbar className={classes.toolbar}>
        <StyledLink to="/">
          <img alt="Logo" src={logoUrl} className={classes.img} />
        </StyledLink>
        <div>
          <IconButton color="inherit" onClick={() => setDark(!getDark)}>
            {getDark ? <WbSunny /> : <NightsStay />}
          </IconButton>
          <IconButton color="inherit" onClick={navToggle}>
            <Menu />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};
