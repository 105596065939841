import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Delete,
  Edit,
  PlusOne,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  hasRotation,
  NonInputableField,
  SpacedDivider,
  spaceBetween,
  SingleSelect,
} from "@bjelos-farm/lib-client-common";
import {
  LocationUpdateInput,
  useUpdateLocationMutation,
  useLocationQuery,
  LocationType,
  OrderMethod,
  StickerColor,
  PaymentType,
} from "../../Generated/graphql";
import { useData } from "../../Provider/Data";
import {
  textFromLocationType,
  textFromOrderMethod,
  textFromPaymentType,
  textFromStickerColor,
} from "../../Utils/enumToText";
import { removeFromArray } from "@bjelos-farm/lib-common-tools";
import { getProductPricesIds } from "../../Utils/FormatModificators";

const useStyles = makeStyles((theme) => ({
  spaceBetween,
  expand: hasRotation(theme, 0),
  expandOpen: hasRotation(theme, 180),
  edit: hasRotation(theme, 0),
  editOpen: hasRotation(theme, 60),
}));

export const Show: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const { firms, products, refetch } = useData();
  const [getEditable, setEditable] = React.useState<boolean>(false);
  const { data, loading } = useLocationQuery({
    variables: { where: { id: numId } },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const [updateMutation] = useUpdateLocationMutation({
    update: (_) => {
      window.location.reload();
    },
  });

  const formik = useFormik<LocationUpdateInput>({
    initialValues: {},
    onSubmit: (data) =>
      updateMutation({
        variables: { data, where: { id: numId } },
      }),
  });

  const classes = useStyles();

  const pageData = data?.location;

  if (loading) {
    return <p>Chargement</p>;
  }

  if (!pageData) {
    return <p>Element non trouvé</p>;
  }

  const setAdvancedEditable = (value: boolean): void => {
    setEditable(value);
    formik.setValues({
      name: { set: pageData.name },
      disabled: { set: pageData.disabled },
      address: { set: pageData.address ?? "" },
      billingAddress: { set: pageData.billingAddress ?? "" },
      comment: { set: pageData.comment ?? "" },
      locationType: { set: pageData.locationType },
      orderMethod: { set: pageData.orderMethod },
      stickerColor: { set: pageData.stickerColor },
      printBarcode: { set: pageData.printBarcode },
      printDate: { set: pageData.printDate },
      monthly: { set: pageData.monthly },
      send: { set: pageData.send },
      paymentType: { set: pageData.paymentType },
      firm: { connect: { id: pageData.firmId } },
      phones: { set: pageData.phones ?? [] },
      fullStock: {
        create: {
          cartItems: {
            create:
              pageData.fullStock.cartItems?.map(
                ({ bulk, productPriceId, qty, comment }) => ({
                  bulk,
                  qty,
                  comment,
                  productPrice: { connect: { id: productPriceId } },
                }),
              ) || [],
          },
        },
      },
    });
  };

  const productPricesIds = getProductPricesIds(
    products,
    (getEditable ? formik.values.locationType?.set : pageData.locationType) ??
      LocationType.Enduser,
  );

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader
          title="Localisation"
          action={
            <>
              <IconButton
                disabled={!getEditable}
                onClick={() =>
                  formik.setValues((oldValues) => {
                    oldValues.disabled = { set: !oldValues.disabled?.set };
                    return oldValues;
                  })
                }
              >
                {(
                  getEditable ? formik.values.disabled?.set : pageData.disabled
                ) ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <IconButton
                className={getEditable ? classes.editOpen : classes.edit}
                onClick={() => setAdvancedEditable(!getEditable)}
              >
                <Edit color={getEditable ? "error" : "primary"} />
              </IconButton>
            </>
          }
        />
        <SpacedDivider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="name.set"
                value={getEditable ? formik.values.name?.set : pageData.name}
                onChange={formik.handleChange}
                label="Nom"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Firme"
                  options={firms.map((firm) => ({
                    label: firm.name,
                    value: firm.id,
                  }))}
                  currentValue={formik.values.firm?.connect?.id}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value || value === 0) {
                        oldValues.firm = { connect: { id: value } };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={
                    firms.find((firm) => firm.id === pageData.firmId)?.name ||
                    "Aucun"
                  }
                  label="Firme"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="address.set"
                value={
                  getEditable ? formik.values.address?.set : pageData.address
                }
                onChange={formik.handleChange}
                label="Addresse"
                multiline={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="billingAddress.set"
                value={
                  getEditable
                    ? formik.values.billingAddress?.set
                    : pageData.billingAddress
                }
                onChange={formik.handleChange}
                label="Addresse de facturation"
                multiline={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <NonInputableField
                disabled={!getEditable}
                name="comment.set"
                value={
                  getEditable ? formik.values.comment?.set : pageData.comment
                }
                onChange={formik.handleChange}
                label="Commentaire"
                multiline={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Type de localisation"
                  options={[
                    {
                      label: textFromLocationType(LocationType.Retailer),
                      value: LocationType.Retailer,
                    },
                    {
                      label: textFromLocationType(LocationType.Restaurateur),
                      value: LocationType.Restaurateur,
                    },
                    {
                      label: textFromLocationType(LocationType.Enduser),
                      value: LocationType.Enduser,
                    },
                  ]}
                  currentValue={formik.values.locationType?.set}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value) {
                        oldValues.locationType = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={textFromLocationType(pageData.locationType)}
                  label="Type de localisation"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Initialisation du cart"
                  options={[
                    {
                      label: textFromOrderMethod(OrderMethod.FromBasis),
                      value: OrderMethod.FromBasis,
                    },
                    {
                      label: textFromOrderMethod(OrderMethod.Normal),
                      value: OrderMethod.Normal,
                    },
                    {
                      label: textFromOrderMethod(OrderMethod.Permanent),
                      value: OrderMethod.Permanent,
                    },
                  ]}
                  currentValue={formik.values.orderMethod?.set}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value) {
                        oldValues.orderMethod = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={textFromOrderMethod(pageData.orderMethod)}
                  label="Initialisation du cart"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Couleur d'étiquette"
                  options={[
                    {
                      label: textFromStickerColor(StickerColor.Green),
                      value: StickerColor.Green,
                    },
                    {
                      label: textFromStickerColor(StickerColor.Red),
                      value: StickerColor.Red,
                    },
                    {
                      label: textFromStickerColor(StickerColor.None),
                      value: StickerColor.None,
                    },
                  ]}
                  currentValue={formik.values.stickerColor?.set}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value) {
                        oldValues.stickerColor = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={textFromStickerColor(pageData.stickerColor)}
                  label="Couleur d'étiquette"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!getEditable}
                    name="printBarcode.set"
                    checked={
                      (getEditable
                        ? formik.values.printBarcode?.set
                        : pageData.printBarcode) ?? false
                    }
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Codebar"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!getEditable}
                    name="printDate.set"
                    checked={
                      (getEditable
                        ? formik.values.printDate?.set
                        : pageData.printDate) ?? false
                    }
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Date"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!getEditable}
                    name="monthly.set"
                    checked={
                      (getEditable
                        ? formik.values.monthly?.set
                        : pageData.monthly) ?? false
                    }
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Paiement mensuel"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!getEditable}
                    name="send.set"
                    checked={
                      (getEditable ? formik.values.send?.set : pageData.send) ??
                      false
                    }
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Envoie par la poste"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {getEditable ? (
                <SingleSelect
                  disableClearable={true}
                  label="Type de paiement"
                  options={[
                    {
                      label: textFromPaymentType(PaymentType.Bank),
                      value: PaymentType.Bank,
                    },
                    {
                      label: textFromPaymentType(PaymentType.Cash),
                      value: PaymentType.Cash,
                    },
                  ]}
                  currentValue={formik.values.paymentType?.set}
                  setNewValue={(value) =>
                    formik.setValues((oldValues) => {
                      if (value) {
                        oldValues.paymentType = { set: value };
                        return oldValues;
                      }
                      return oldValues;
                    })
                  }
                />
              ) : (
                <NonInputableField
                  disabled={!getEditable}
                  value={textFromPaymentType(pageData.paymentType)}
                  label="Type de paiement"
                />
              )}
            </Grid>
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Numéros de téléphone
          </Typography>
          <Grid container spacing={3}>
            {getEditable &&
              formik.values.phones?.set.map((phone, index) => (
                <React.Fragment key={index}>
                  <Grid item md={5} xs={12}>
                    <NonInputableField
                      name={`phones.set[${index}]`}
                      onChange={formik.handleChange}
                      value={phone}
                      label="numéro"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <IconButton
                      onClick={() =>
                        formik.setValues((values) => {
                          if (values.phones?.set) {
                            values.phones.set = removeFromArray(
                              values.phones.set,
                              index,
                            );
                          }
                          return values;
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            {!getEditable &&
              pageData.phones?.map((phone, index) => (
                <React.Fragment key={index}>
                  <Grid item md={5} xs={12}>
                    <NonInputableField
                      disabled={true}
                      value={phone}
                      label="numéro"
                    />
                  </Grid>
                  <Grid item md={1} xs={12}></Grid>
                </React.Fragment>
              ))}
            {getEditable && (
              <Grid item md={6} xs={12}>
                <IconButton
                  onClick={() =>
                    formik.setValues((values) => {
                      values.phones?.set.push("");
                      return values;
                    })
                  }
                >
                  <PlusOne />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <SpacedDivider space={20} />
          <Typography variant="h5" gutterBottom>
            Stock de base
          </Typography>
          <Grid container spacing={3}>
            {!getEditable &&
              pageData.fullStock.cartItems?.map((cartItem, index) => (
                <React.Fragment key={index}>
                  <Grid item md={3} xs={12} key={index}>
                    <NonInputableField
                      label="Produit"
                      disabled={true}
                      value={
                        productPricesIds.find(
                          (productPrice) =>
                            productPrice.currentPriceId ===
                            cartItem.productPriceId,
                        )?.label
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={12} key={index}>
                    <NonInputableField
                      label="Quantité"
                      disabled={true}
                      value={cartItem.qty}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} key={index}>
                    <NonInputableField
                      label="Commentaire"
                      disabled={true}
                      value={cartItem.comment}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} key={index}>
                    <NonInputableField
                      label="En vrac"
                      disabled={true}
                      value={cartItem.bulk ? "Oui" : "Non"}
                    />
                  </Grid>
                  <Grid item md={1} xs={12} key={index}></Grid>
                </React.Fragment>
              ))}
            {getEditable &&
              formik.values.fullStock?.create?.cartItems?.create?.map(
                (cartItem, index) => (
                  <React.Fragment key={index}>
                    <Grid item md={3} xs={12}>
                      <SingleSelect
                        disableClearable={true}
                        label="Produit"
                        options={productPricesIds.map((productWithId) => ({
                          label: productWithId.label,
                          value: productWithId.currentPriceId,
                        }))}
                        currentValue={cartItem.productPrice.connect?.id}
                        setNewValue={(value) =>
                          formik.setValues((oldValues) => {
                            if (
                              (value || value === 0) &&
                              oldValues.fullStock?.create?.cartItems?.create &&
                              oldValues.fullStock?.create?.cartItems?.create[
                                index
                              ].productPrice.connect?.id
                            ) {
                              oldValues.fullStock.create.cartItems.create[
                                index
                              ].productPrice.connect = { id: value };
                              return oldValues;
                            }
                            return oldValues;
                          })
                        }
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <NonInputableField
                        type="number"
                        name={`fullStock.create.cartItems.create[${index}].qty`}
                        onChange={formik.handleChange}
                        value={cartItem.qty}
                        label="Quantité"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <NonInputableField
                        name={`fullStock.create.cartItems.create[${index}].comment`}
                        onChange={formik.handleChange}
                        value={cartItem.comment}
                        label="Commentaire"
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`fullStock.create.cartItems.create[${index}].bulk`}
                            checked={cartItem.bulk}
                            onChange={formik.handleChange}
                            color="primary"
                          />
                        }
                        label="En vrac"
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <IconButton
                        onClick={() =>
                          formik.setValues((values) => {
                            if (values.fullStock?.create?.cartItems?.create) {
                              values.fullStock.create.cartItems.create = removeFromArray(
                                values.fullStock.create.cartItems.create,
                                index,
                              );
                            }
                            return values;
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ),
              )}
            {getEditable && (
              <Grid item md={6} xs={12}>
                <IconButton
                  onClick={() =>
                    formik.setValues((values) => {
                      values.fullStock?.create?.cartItems?.create?.push({
                        bulk: false,
                        productPrice: {
                          connect: { id: productPricesIds[0].currentPriceId },
                        },
                        qty: 0,
                        comment: "",
                      });
                      return values;
                    })
                  }
                >
                  <PlusOne />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {getEditable && (
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginLeft: "auto" }}
            >
              Modifier
            </Button>
          </CardActions>
        )}
      </form>
    </Card>
  );
};
